


import Menu from "./Menu";
import Footer from "./Footer";
import { Link } from "react-router-dom";


function Home() {



    return (


        <main >

            <Menu />


            {/* Headline */}
            <div className="container">
                <div className="bg0 flex-wr-sb-c p-rl-20 p-tb-8">
                    <div className="f2-s-1 p-r-30 size-w-0 m-tb-6 flex-wr-s-c">
                        <span className="text-uppercase cl2 p-r-8">EN TENDENCIA:</span>
                        <span
                            className="dis-inline-block cl6 slide100-txt pos-relative size-w-0"
                            data-in="fadeInDown"
                            data-out="fadeOutDown"
                        >
                            <span className="dis-inline-block slide100-txt-item animated visible-false">
                                La Federación Rusa se prepara para un gran cambio
                            </span>
                            <span className="dis-inline-block slide100-txt-item animated visible-false">
                                En el Kremlin lo tienen decidido
                            </span>
                            <span className="dis-inline-block slide100-txt-item animated visible-false">
                                Esta es lo más viral de esta semana
                            </span>
                        </span>
                    </div>

                    <div className="pos-relative size-a-2 bo-1-rad-22 of-hidden bocl11 m-tb-6">
                        <input
                            className="f1-s-1 cl6 plh9 s-full p-l-25 p-r-45"
                            type="text"
                            name="search"
                            placeholder="Buscar"
                        />
                        <button className="flex-c-c size-a-1 ab-t-r fs-20 cl2 hov-cl10 trans-03">
                            <i className="zmdi zmdi-search" />
                        </button>
                    </div>

                </div>
            </div>



            {/*===============================================================================================*/}
            {/*================================= TITULARES - PORTADA ========================================*/}
            {/*===============================================================================================*/}

            <section className="bg0">
                <div className="container">
                    <div className="row m-rl--1">


                        {/*===================== TITULAR PRINCIPAL =====================*/}
                        <div className="col-md-6 p-rl-1 p-b-2">
                            <div
                                className="bg-img1 size-a-3 how1 pos-relative"
                                style={{ backgroundImage: "url(images/Putin-Latam.png)" }}
                            >
                                <Link to="/230929-Conf-Rusia-Latam" className="dis-block how1-child1 trans-03"> </Link>

                                <div className="flex-col-e-s s-full p-rl-25 p-tb-20">
                                    <a
                                        href="#"
                                        className="dis-block how1-child2 f1-s-2 cl0 bo-all-1 bocl0 hov-btn1 trans-03 p-rl-5 p-t-2"
                                    >
                                        Sociedad
                                    </a>
                                    <h3 className="how1-child2 m-t-14 m-b-10">
                                        <Link to="/230929-Conf-Rusia-Latam"
                                            className="how-txt1 size-a-6 f1-l-1 cl0 hov-cl10 trans-03"
                                        >
                                            La primera conferencia parlamentaria "Rusia-América Latina" se
                                            celebra en Moscú
                                        </Link>

                                    </h3>
                                    <span className="how1-child2">
                                        <span className="f1-s-4 cl11">Luis Palermo</span>
                                        <span className="f1-s-3 cl11 m-rl-3">-</span>
                                        <span className="f1-s-3 cl11">Sep 29</span>
                                    </span>
                                </div>
                            </div>
                        </div>


                        {/*===================== SEGUNDO TITULAR =====================*/}
                        <div className="col-md-6 p-rl-1">
                            <div className="row m-rl--1">
                                <div className="col-12 p-rl-1 p-b-2">
                                    <div
                                        className="bg-img1 size-a-4 how1 pos-relative"
                                        style={{
                                            backgroundImage: "url(images/puente-crimea-rusia.png)"
                                        }}
                                    >

                                        <Link to="/231015-El-puente-de-Crimea-fue-completamente-restaurado"
                                            className="dis-block how1-child1 trans-03"
                                        >
                                        </Link>

                                        <div className="flex-col-e-s s-full p-rl-25 p-tb-24">
                                            <Link to="/society" className="dis-block how1-child2 f1-s-2 cl0 bo-all-1 bocl0 hov-btn1 trans-03 p-rl-5 p-t-2"> Sociedad </Link>

                                            <h3 className="how1-child2 m-t-14">
                                                <Link to="/231015-El-puente-de-Crimea-fue-completamente-restaurado"
                                                    className="how-txt1 size-a-7 f1-l-2 cl0 hov-cl10 trans-03"
                                                >
                                                    El puente de Crimea fue completamente restaurado antes de lo previsto
                                                </Link>

                                            </h3>
                                        </div>
                                    </div>
                                </div>



                                {/*===================== TERCER TITULAR =====================*/}
                                <div className="col-sm-6 p-rl-1 p-b-2">
                                    <div
                                        className="bg-img1 size-a-5 how1 pos-relative"
                                        style={{
                                            backgroundImage: "url(images/avion-aeroflot-aero.png)"
                                        }}
                                    >
                                        <Link to="/231012-Aeroflot-aumenta-vuelos-internacionales" className="dis-block how1-child1 trans-03">
                                        </Link>

                                        <div className="flex-col-e-s s-full p-rl-25 p-tb-20">
                                            <Link to="/economy" className="dis-block how1-child2 f1-s-2 cl0 bo-all-1 bocl0 hov-btn1 trans-03 p-rl-5 p-t-2"> Economía </Link>

                                            <h3 className="how1-child2 m-t-14">
                                                <Link to="/231012-Aeroflot-aumenta-vuelos-internacionales"
                                                    className="how-txt1 size-h-1 f1-m-1 cl0 hov-cl10 trans-03"
                                                >
                                                    AEROFLOT volará a otros 86 destinos internacionales.
                                                </Link>

                                            </h3>
                                        </div>
                                    </div>
                                </div>



                                {/*===================== CUARTO TITULAR =====================*/}
                                <div className="col-sm-6 p-rl-1 p-b-2">
                                    <div
                                        className="bg-img1 size-a-5 how1 pos-relative"
                                        style={{ backgroundImage: "url(images/crypto-bitcoin-binance.png)" }}
                                    >
                                        <a
                                            href="es/economy/20230927-Binance-vende-sus-activos-en-Rusia.html"
                                            className="dis-block how1-child1 trans-03"
                                        />
                                        <div className="flex-col-e-s s-full p-rl-25 p-tb-20">
                                            <a
                                                href="#"
                                                className="dis-block how1-child2 f1-s-2 cl0 bo-all-1 bocl0 hov-btn1 trans-03 p-rl-5 p-t-2"
                                            >
                                                Economía
                                            </a>
                                            <h3 className="how1-child2 m-t-14">
                                                <a
                                                    href="es/economy/20230927-Binance-vende-sus-activos-en-Rusia.html"
                                                    className="how-txt1 size-h-1 f1-m-1 cl0 hov-cl10 trans-03"
                                                >
                                                    La criptobolsa BINANCE vende todos sus activos en Rusia
                                                </a>
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>




            {/*===============================================================================================*/}
            {/*===================================== PRIMERA SECCIÓN ========================================*/}
            {/*===============================================================================================*/}
            {/* Post */}
            <section className="bg0 p-t-70">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-10 col-lg-8">
                            <div className="p-b-20">
                                {/*===============================================================================================*/}
                                {/*================================== BLOQUE ACTUALIDAD ========================================*/}
                                {/*===============================================================================================*/}
                                {/* Actualidad */}
                                <div className="tab01 p-b-20">
                                    <div className="tab01-head how2 how2-cl1 bocl12 flex-s-c m-r-10 m-r-0-sr991">
                                        {/* Brand tab */}
                                        <h3 className="f1-m-2 cl12 tab01-title">Actualidad</h3>
                                        {/* Nav tabs */}
                                        <ul className="nav nav-tabs" role="tablist">
                                            <li className="nav-item">
                                                <a
                                                    className="nav-link active"
                                                    data-toggle="tab"
                                                    href="#tab1-1"
                                                    role="tab"
                                                >
                                                    Todo
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a
                                                    className="nav-link"
                                                    data-toggle="tab"
                                                    href="#tab1-2"
                                                    role="tab"
                                                >
                                                    Últimas
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a
                                                    className="nav-link"
                                                    data-toggle="tab"
                                                    href="#tab1-3"
                                                    role="tab"
                                                >
                                                    Política
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a
                                                    className="nav-link"
                                                    data-toggle="tab"
                                                    href="#tab1-4"
                                                    role="tab"
                                                >
                                                    Tendencia
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a
                                                    className="nav-link"
                                                    data-toggle="tab"
                                                    href="#tab1-5"
                                                    role="tab"
                                                >
                                                    Virales
                                                </a>
                                            </li>
                                            <li className="nav-item-more dropdown dis-none">
                                                <a
                                                    className="nav-link dropdown-toggle"
                                                    data-toggle="dropdown"
                                                    href="#"
                                                >
                                                    <i className="fa fa-ellipsis-h" />
                                                </a>
                                                <ul className="dropdown-Menu"></ul>
                                            </li>
                                        </ul>
                                        {/*  */}
                                        <a
                                            href="es/economy.html"
                                            className="tab01-link f1-s-1 cl9 hov-cl10 trans-03"
                                        >
                                            Ver todo
                                            <i className="fs-12 m-l-5 fa fa-caret-right" />
                                        </a>
                                    </div>
                                    {/* ============= Paneles de pestañas ============= */}
                                    {/* Tab panes */}
                                    <div className="tab-content p-t-35">
                                        {/* - */}
                                        <div
                                            className="tab-pane fade show active"
                                            id="tab1-1"
                                            role="tabpanel"
                                        >
                                            <div className="row">
                                                {/* ============= ITEM1A Actualidad ============= */}
                                                <div className="col-sm-6 p-r-25 p-r-15-sr991">
                                                    {/* Item post */}
                                                    <div className="m-b-30">
                                                        <a
                                                            href="es/society/20230929-primera-conferencia-Rusia-America-Latina.html"
                                                            className="wrap-pic-w hov1 trans-03"
                                                        >
                                                            <img src="images/Putin-Latam.png" alt="IMG" />
                                                        </a>
                                                        <div className="p-t-20">
                                                            <h5 className="p-b-5">
                                                                <a
                                                                    href="es/society/20230929-primera-conferencia-Rusia-America-Latina.html"
                                                                    className="f1-m-3 cl2 hov-cl10 trans-03"
                                                                >
                                                                    La primera conferencia parlamentaria
                                                                    "Rusia-América Latina" se celebra en Moscú
                                                                </a>
                                                            </h5>
                                                            <span className="cl8">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-4 cl8 hov-cl10 trans-03"
                                                                >
                                                                    Sociedad
                                                                </a>
                                                                <span className="f1-s-3 m-rl-3">-</span>
                                                                <span className="f1-s-3">Sep 29</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* ============= ITEM1B Actualidad ============= */}
                                                <div className="col-sm-6 p-r-25 p-r-15-sr991">
                                                    {/* Item post */}
                                                    <div className="flex-wr-sb-s m-b-30">
                                                        <a
                                                            href="es/politics/20230920-kremlin-informa-visita-de-Putin-a-China.html"
                                                            className="size-w-1 wrap-pic-w hov1 trans-03"
                                                        >
                                                            <img src="images/kremlin.png" alt="IMG" />
                                                        </a>
                                                        <div className="size-w-2">
                                                            <h5 className="p-b-5">
                                                                <a
                                                                    href="es/politics/20230920-kremlin-informa-visita-de-Putin-a-China.html"
                                                                    className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                >
                                                                    En el Kremlin afirmaron que se está preparando la
                                                                    visita de Putin a China.
                                                                </a>
                                                            </h5>
                                                            <span className="cl8">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                >
                                                                    Política
                                                                </a>
                                                                <span className="f1-s-3 m-rl-3">-</span>
                                                                <span className="f1-s-3">Sep 20</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    {/* ============= ITEM1C Actualidad ============= */}
                                                    {/* Item post */}
                                                    <div className="flex-wr-sb-s m-b-30">
                                                        <a
                                                            href="es/economy/20230927-Binance-vende-sus-activos-en-Rusia.html"
                                                            className="size-w-1 wrap-pic-w hov1 trans-03"
                                                        >
                                                            <img
                                                                src="images/crypto-bitcoin-binance.png"
                                                                alt="IMG"
                                                            />
                                                        </a>
                                                        <div className="size-w-2">
                                                            <h5 className="p-b-5">
                                                                <a
                                                                    href="es/economy/20230927-Binance-vende-sus-activos-en-Rusia.html"
                                                                    className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                >
                                                                    La criptobolsa BINANCE vende todos sus activos en
                                                                    Rusia
                                                                </a>
                                                            </h5>
                                                            <span className="cl8">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                >
                                                                    Economía
                                                                </a>
                                                                <span className="f1-s-3 m-rl-3">-</span>
                                                                <span className="f1-s-3">Sep 27</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    {/* ============= ITEM1D Actualidad ============= */}
                                                    {/* Item post */}
                                                    <div className="flex-wr-sb-s m-b-30">
                                                        <a
                                                            href="es/society/20230925-profesiones-prometedoras-desarrollo-en-Moscu.html"
                                                            className="size-w-1 wrap-pic-w hov1 trans-03"
                                                        >
                                                            <img
                                                                src="images/chicas-computadora-tecnologia.png"
                                                                alt="IMG"
                                                            />
                                                        </a>
                                                        <div className="size-w-2">
                                                            <h5 className="p-b-5">
                                                                <a
                                                                    href="es/society/20230925-profesiones-prometedoras-desarrollo-en-Moscu.html"
                                                                    className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                >
                                                                    Las profesiones prometedoras en el desarrollo se
                                                                    discutirán en Moscú
                                                                </a>
                                                            </h5>
                                                            <span className="cl8">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                >
                                                                    Sociedad
                                                                </a>
                                                                <span className="f1-s-3 m-rl-3">-</span>
                                                                <span className="f1-s-3">Sep 25</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>{" "}
                                            {/* ============= TErmima Fila1 Actualidad ============= */}
                                        </div>
                                        {/* ============= FILA 02 ============= */}
                                        {/* - */}
                                        <div className="tab-pane fade" id="tab1-2" role="tabpanel">
                                            <div className="row">
                                                <div className="col-sm-6 p-r-25 p-r-15-sr991">
                                                    {/* ============= ITEM2A Actualidad ============= */}
                                                    {/* Item post */}
                                                    <div className="m-b-30">
                                                        <a
                                                            href="es/ai/20230926-Rusia-destina-5,2-mil-millones-de-rublos-inteligencia-artificial.html"
                                                            className="wrap-pic-w hov1 trans-03"
                                                        >
                                                            <img src="images/ia-robot-tecno.png" alt="IMG" />
                                                        </a>
                                                        <div className="p-t-20">
                                                            <h5 className="p-b-5">
                                                                <a
                                                                    href="es/ai/20230926-Rusia-destina-5,2-mil-millones-de-rublos-inteligencia-artificial.html"
                                                                    className="f1-m-3 cl2 hov-cl10 trans-03"
                                                                >
                                                                    El Gobierno Ruso destinará 5,2 mil millones al
                                                                    desarrollo de la inteligencia artificial
                                                                </a>
                                                            </h5>
                                                            <span className="cl8">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-4 cl8 hov-cl10 trans-03"
                                                                >
                                                                    IA
                                                                </a>
                                                                <span className="f1-s-3 m-rl-3">-</span>
                                                                <span className="f1-s-3">Sep 26</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 p-r-25 p-r-15-sr991">
                                                    {/* ============= ITEM2B Actualidad ============= */}
                                                    {/* Item post */}
                                                    <div className="flex-wr-sb-s m-b-30">
                                                        <a
                                                            href="#"
                                                            className="size-w-1 wrap-pic-w hov1 trans-03"
                                                        >
                                                            <img src="images/post-10.jpg" alt="IMG" />
                                                        </a>
                                                        <div className="size-w-2">
                                                            <h5 className="p-b-5">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                >
                                                                    Donec metus orci, malesuada et lectus vitae
                                                                </a>
                                                            </h5>
                                                            <span className="cl8">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                >
                                                                    Celebridades
                                                                </a>
                                                                <span className="f1-s-3 m-rl-3">-</span>
                                                                <span className="f1-s-3">Sep 12</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    {/* ============= ITEM2C Actualidad ============= */}
                                                    {/* Item post */}
                                                    <div className="flex-wr-sb-s m-b-30">
                                                        <a
                                                            href="#"
                                                            className="size-w-1 wrap-pic-w hov1 trans-03"
                                                        >
                                                            <img src="images/post-06.jpg" alt="IMG" />
                                                        </a>
                                                        <div className="size-w-2">
                                                            <h5 className="p-b-5">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                >
                                                                    Donec metus orci, malesuada et lectus vitae
                                                                </a>
                                                            </h5>
                                                            <span className="cl8">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                >
                                                                    Actualidad
                                                                </a>
                                                                <span className="f1-s-3 m-rl-3">-</span>
                                                                <span className="f1-s-3">Sep 17</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    {/* ============= ITEM2D Actualidad ============= */}
                                                    {/* Item post */}
                                                    <div className="flex-wr-sb-s m-b-30">
                                                        <a
                                                            href="#"
                                                            className="size-w-1 wrap-pic-w hov1 trans-03"
                                                        >
                                                            <img src="images/post-07.jpg" alt="IMG" />
                                                        </a>
                                                        <div className="size-w-2">
                                                            <h5 className="p-b-5">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                >
                                                                    Donec metus orci, malesuada et lectus vitae
                                                                </a>
                                                            </h5>
                                                            <span className="cl8">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                >
                                                                    Latino
                                                                </a>
                                                                <span className="f1-s-3 m-rl-3">-</span>
                                                                <span className="f1-s-3">Sep 16</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* - */}
                                        <div className="tab-pane fade" id="tab1-3" role="tabpanel">
                                            <div className="row">
                                                <div className="col-sm-6 p-r-25 p-r-15-sr991">
                                                    {/* ============= ITEM3A Actualidad ============= */}
                                                    {/* Item post */}
                                                    <div className="m-b-30">
                                                        <a href="#" className="wrap-pic-w hov1 trans-03">
                                                            <img src="images/post-10.jpg" alt="IMG" />
                                                        </a>
                                                        <div className="p-t-20">
                                                            <h5 className="p-b-5">
                                                                <a
                                                                    href="#"
                                                                    className="f1-m-3 cl2 hov-cl10 trans-03"
                                                                >
                                                                    American live Viral lorem ipsum dolor sit amet
                                                                    consectetur
                                                                </a>
                                                            </h5>
                                                            <span className="cl8">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-4 cl8 hov-cl10 trans-03"
                                                                >
                                                                    Actualidad
                                                                </a>
                                                                <span className="f1-s-3 m-rl-3">-</span>
                                                                <span className="f1-s-3">Sep 18</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 p-r-25 p-r-15-sr991">
                                                    {/* ============= ITEM3B Actualidad ============= */}
                                                    {/* Item post */}
                                                    <div className="flex-wr-sb-s m-b-30">
                                                        <a
                                                            href="#"
                                                            className="size-w-1 wrap-pic-w hov1 trans-03"
                                                        >
                                                            <img src="images/post-07.jpg" alt="IMG" />
                                                        </a>
                                                        <div className="size-w-2">
                                                            <h5 className="p-b-5">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                >
                                                                    Donec metus orci, malesuada et lectus vitae
                                                                </a>
                                                            </h5>
                                                            <span className="cl8">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                >
                                                                    Celebridades
                                                                </a>
                                                                <span className="f1-s-3 m-rl-3">-</span>
                                                                <span className="f1-s-3">Sep 12</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    {/* ============= ITEM3C Actualidad ============= */}
                                                    {/* Item post */}
                                                    <div className="flex-wr-sb-s m-b-30">
                                                        <a
                                                            href="#"
                                                            className="size-w-1 wrap-pic-w hov1 trans-03"
                                                        >
                                                            <img src="images/post-06.jpg" alt="IMG" />
                                                        </a>
                                                        <div className="size-w-2">
                                                            <h5 className="p-b-5">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                >
                                                                    Donec metus orci, malesuada et lectus vitae
                                                                </a>
                                                            </h5>
                                                            <span className="cl8">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                >
                                                                    Actualidad
                                                                </a>
                                                                <span className="f1-s-3 m-rl-3">-</span>
                                                                <span className="f1-s-3">Sep 17</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    {/* ============= ITEM3D Actualidad ============= */}
                                                    {/* Item post */}
                                                    <div className="flex-wr-sb-s m-b-30">
                                                        <a
                                                            href="#"
                                                            className="size-w-1 wrap-pic-w hov1 trans-03"
                                                        >
                                                            <img src="images/post-05.jpg" alt="IMG" />
                                                        </a>
                                                        <div className="size-w-2">
                                                            <h5 className="p-b-5">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                >
                                                                    Donec metus orci, malesuada et lectus vitae
                                                                </a>
                                                            </h5>
                                                            <span className="cl8">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                >
                                                                    Game
                                                                </a>
                                                                <span className="f1-s-3 m-rl-3">-</span>
                                                                <span className="f1-s-3">Sep 16</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* - */}
                                        <div className="tab-pane fade" id="tab1-4" role="tabpanel">
                                            <div className="row">
                                                <div className="col-sm-6 p-r-25 p-r-15-sr991">
                                                    {/* ============= ITEM4A Actualidad ============= */}
                                                    {/* Item post */}
                                                    <div className="m-b-30">
                                                        <a href="#" className="wrap-pic-w hov1 trans-03">
                                                            <img src="images/post-06.jpg" alt="IMG" />
                                                        </a>
                                                        <div className="p-t-20">
                                                            <h5 className="p-b-5">
                                                                <a
                                                                    href="#"
                                                                    className="f1-m-3 cl2 hov-cl10 trans-03"
                                                                >
                                                                    American live Viral lorem ipsum dolor sit amet
                                                                    consectetur
                                                                </a>
                                                            </h5>
                                                            <span className="cl8">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-4 cl8 hov-cl10 trans-03"
                                                                >
                                                                    Viral
                                                                </a>
                                                                <span className="f1-s-3 m-rl-3">-</span>
                                                                <span className="f1-s-3">Sep 18</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 p-r-25 p-r-15-sr991">
                                                    {/* ============= ITEM4B Actualidad ============= */}
                                                    {/* Item post */}
                                                    <div className="flex-wr-sb-s m-b-30">
                                                        <a
                                                            href="#"
                                                            className="size-w-1 wrap-pic-w hov1 trans-03"
                                                        >
                                                            <img src="images/post-35.jpg" alt="IMG" />
                                                        </a>
                                                        <div className="size-w-2">
                                                            <h5 className="p-b-5">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                >
                                                                    Donec metus orci, malesuada et lectus vitae
                                                                </a>
                                                            </h5>
                                                            <span className="cl8">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                >
                                                                    Celebridades
                                                                </a>
                                                                <span className="f1-s-3 m-rl-3">-</span>
                                                                <span className="f1-s-3">Sep 12</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    {/* ============= ITEM4C Actualidad ============= */}
                                                    {/* Item post */}
                                                    <div className="flex-wr-sb-s m-b-30">
                                                        <a
                                                            href="#"
                                                            className="size-w-1 wrap-pic-w hov1 trans-03"
                                                        >
                                                            <img src="images/post-07.jpg" alt="IMG" />
                                                        </a>
                                                        <div className="size-w-2">
                                                            <h5 className="p-b-5">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                >
                                                                    Donec metus orci, malesuada et lectus vitae
                                                                </a>
                                                            </h5>
                                                            <span className="cl8">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                >
                                                                    Viral
                                                                </a>
                                                                <span className="f1-s-3 m-rl-3">-</span>
                                                                <span className="f1-s-3">Sep 17</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    {/* ============= ITEM4D Actualidad ============= */}
                                                    {/* Item post */}
                                                    <div className="flex-wr-sb-s m-b-30">
                                                        <a
                                                            href="#"
                                                            className="size-w-1 wrap-pic-w hov1 trans-03"
                                                        >
                                                            <img src="images/post-10.jpg" alt="IMG" />
                                                        </a>
                                                        <div className="size-w-2">
                                                            <h5 className="p-b-5">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                >
                                                                    Donec metus orci, malesuada et lectus vitae
                                                                </a>
                                                            </h5>
                                                            <span className="cl8">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                >
                                                                    Game
                                                                </a>
                                                                <span className="f1-s-3 m-rl-3">-</span>
                                                                <span className="f1-s-3">Sep 16</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* - */}
                                        <div className="tab-pane fade" id="tab1-5" role="tabpanel">
                                            <div className="row">
                                                <div className="col-sm-6 p-r-25 p-r-15-sr991">
                                                    {/* ============= ITEM5A Actualidad ============= */}
                                                    {/* Item post */}
                                                    <div className="m-b-30">
                                                        <a href="#" className="wrap-pic-w hov1 trans-03">
                                                            <img src="images/post-07.jpg" alt="IMG" />
                                                        </a>
                                                        <div className="p-t-20">
                                                            <h5 className="p-b-5">
                                                                <a
                                                                    href="#"
                                                                    className="f1-m-3 cl2 hov-cl10 trans-03"
                                                                >
                                                                    American live Viral lorem ipsum dolor sit amet
                                                                    consectetur
                                                                </a>
                                                            </h5>
                                                            <span className="cl8">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-4 cl8 hov-cl10 trans-03"
                                                                >
                                                                    Viral
                                                                </a>
                                                                <span className="f1-s-3 m-rl-3">-</span>
                                                                <span className="f1-s-3">Sep 18</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 p-r-25 p-r-15-sr991">
                                                    {/* ============= ITEM5B Actualidad ============= */}
                                                    {/* Item post */}
                                                    <div className="flex-wr-sb-s m-b-30">
                                                        <a
                                                            href="#"
                                                            className="size-w-1 wrap-pic-w hov1 trans-03"
                                                        >
                                                            <img src="images/post-10.jpg" alt="IMG" />
                                                        </a>
                                                        <div className="size-w-2">
                                                            <h5 className="p-b-5">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                >
                                                                    Donec metus orci, malesuada et lectus vitae
                                                                </a>
                                                            </h5>
                                                            <span className="cl8">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                >
                                                                    Celebridades
                                                                </a>
                                                                <span className="f1-s-3 m-rl-3">-</span>
                                                                <span className="f1-s-3">Sep 12</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    {/* ============= ITEM5C Actualidad ============= */}
                                                    {/* Item post */}
                                                    <div className="flex-wr-sb-s m-b-30">
                                                        <a
                                                            href="#"
                                                            className="size-w-1 wrap-pic-w hov1 trans-03"
                                                        >
                                                            <img src="images/post-06.jpg" alt="IMG" />
                                                        </a>
                                                        <div className="size-w-2">
                                                            <h5 className="p-b-5">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                >
                                                                    Donec metus orci, malesuada et lectus vitae
                                                                </a>
                                                            </h5>
                                                            <span className="cl8">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                >
                                                                    Viral
                                                                </a>
                                                                <span className="f1-s-3 m-rl-3">-</span>
                                                                <span className="f1-s-3">Sep 17</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    {/* ============= ITEM5D Actualidad ============= */}
                                                    {/* Item post */}
                                                    <div className="flex-wr-sb-s m-b-30">
                                                        <a
                                                            href="#"
                                                            className="size-w-1 wrap-pic-w hov1 trans-03"
                                                        >
                                                            <img src="images/post-35.jpg" alt="IMG" />
                                                        </a>
                                                        <div className="size-w-2">
                                                            <h5 className="p-b-5">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                >
                                                                    Donec metus orci, malesuada et lectus vitae
                                                                </a>
                                                            </h5>
                                                            <span className="cl8">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                >
                                                                    Game
                                                                </a>
                                                                <span className="f1-s-3 m-rl-3">-</span>
                                                                <span className="f1-s-3">Sep 16</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/*===============================================================================================*/}
                                {/*================================== BLOQUE ECONOMÍA ========================================*/}
                                {/*===============================================================================================*/}
                                {/* Business */}
                                <div className="tab01 p-b-20">
                                    <div className="tab01-head how2 how2-cl2 bocl12 flex-s-c m-r-10 m-r-0-sr991">
                                        {/* Brand tab */}
                                        <h3 className="f1-m-2 cl13 tab01-title">Economía</h3>
                                        {/* Nav tabs */}
                                        <ul className="nav nav-tabs" role="tablist">
                                            <li className="nav-item">
                                                <a
                                                    className="nav-link active"
                                                    data-toggle="tab"
                                                    href="#tab2-1"
                                                    role="tab"
                                                >
                                                    Todo
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a
                                                    className="nav-link"
                                                    data-toggle="tab"
                                                    href="#tab2-2"
                                                    role="tab"
                                                >
                                                    Economía
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a
                                                    className="nav-link"
                                                    data-toggle="tab"
                                                    href="#tab2-3"
                                                    role="tab"
                                                >
                                                    Finanzas
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a
                                                    className="nav-link"
                                                    data-toggle="tab"
                                                    href="#tab2-4"
                                                    role="tab"
                                                >
                                                    Empresas
                                                </a>
                                            </li>
                                            <li className="nav-item-more dropdown dis-none">
                                                <a
                                                    className="nav-link dropdown-toggle"
                                                    data-toggle="dropdown"
                                                    href="#"
                                                >
                                                    <i className="fa fa-ellipsis-h" />
                                                </a>
                                                <ul className="dropdown-Menu"></ul>
                                            </li>
                                        </ul>
                                        {/*  */}
                                        <a
                                            href="es/economy.html"
                                            className="tab01-link f1-s-1 cl9 hov-cl10 trans-03"
                                        >
                                            Ver Todo
                                            <i className="fs-12 m-l-5 fa fa-caret-right" />
                                        </a>
                                    </div>
                                    {/* Tab panes */}
                                    <div className="tab-content p-t-35">
                                        {/* - */}
                                        <div
                                            className="tab-pane fade show active"
                                            id="tab2-1"
                                            role="tabpanel"
                                        >
                                            <div className="row">
                                                <div className="col-sm-6 p-r-25 p-r-15-sr991">
                                                    {/* ============= ITEM1A Economía ============= */}
                                                    {/* Item post */}
                                                    <div className="m-b-30">
                                                        <a
                                                            href="es/economy/20230919-sberbank-aumenta-tasas-interes.html"
                                                            className="wrap-pic-w hov1 trans-03"
                                                        >
                                                            <img src="images/dinero-rublos-rusos.png" alt="IMG" />
                                                        </a>
                                                        <div className="p-t-20">
                                                            <h5 className="p-b-5">
                                                                <a
                                                                    href="es/economy/20230919-sberbank-aumenta-tasas-interes.html"
                                                                    className="f1-m-3 cl2 hov-cl10 trans-03"
                                                                >
                                                                    Sberbank planea aumentar pronto las tasas de
                                                                    interés en los depósitos.
                                                                </a>
                                                            </h5>
                                                            <span className="cl8">
                                                                <a
                                                                    href="es/economy.html"
                                                                    className="f1-s-4 cl8 hov-cl10 trans-03"
                                                                >
                                                                    Economía
                                                                </a>
                                                                <span className="f1-s-3 m-rl-3">-</span>
                                                                <span className="f1-s-3">Sep 19</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 p-r-25 p-r-15-sr991">
                                                    {/* ============= ITEM1B Economía ============= */}
                                                    {/* Item post */}
                                                    <div className="flex-wr-sb-s m-b-30">
                                                        <a
                                                            href="#"
                                                            className="size-w-1 wrap-pic-w hov1 trans-03"
                                                        >
                                                            <img src="images/post-11.jpg" alt="IMG" />
                                                        </a>
                                                        <div className="size-w-2">
                                                            <h5 className="p-b-5">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                >
                                                                    Donec metus orci, malesuada et lectus vitae
                                                                </a>
                                                            </h5>
                                                            <span className="cl8">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                >
                                                                    Negocios Pequeños
                                                                </a>
                                                                <span className="f1-s-3 m-rl-3">-</span>
                                                                <span className="f1-s-3">Sep 17</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    {/* ============= ITEM1C Economía ============= */}
                                                    {/* Item post */}
                                                    <div className="flex-wr-sb-s m-b-30">
                                                        <a
                                                            href="#"
                                                            className="size-w-1 wrap-pic-w hov1 trans-03"
                                                        >
                                                            <img src="images/post-12.jpg" alt="IMG" />
                                                        </a>
                                                        <div className="size-w-2">
                                                            <h5 className="p-b-5">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                >
                                                                    Donec metus orci, malesuada et lectus vitae
                                                                </a>
                                                            </h5>
                                                            <span className="cl8">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                >
                                                                    Economy
                                                                </a>
                                                                <span className="f1-s-3 m-rl-3">-</span>
                                                                <span className="f1-s-3">Sep 16</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    {/* ============= ITEM1D Economía ============= */}
                                                    {/* Item post */}
                                                    <div className="flex-wr-sb-s m-b-30">
                                                        <a
                                                            href="#"
                                                            className="size-w-1 wrap-pic-w hov1 trans-03"
                                                        >
                                                            <img src="images/post-13.jpg" alt="IMG" />
                                                        </a>
                                                        <div className="size-w-2">
                                                            <h5 className="p-b-5">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                >
                                                                    Donec metus orci, malesuada et lectus vitae
                                                                </a>
                                                            </h5>
                                                            <span className="cl8">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                >
                                                                    Money &amp; Markets
                                                                </a>
                                                                <span className="f1-s-3 m-rl-3">-</span>
                                                                <span className="f1-s-3">Sep 12</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* - */}
                                        <div className="tab-pane fade" id="tab2-2" role="tabpanel">
                                            <div className="row">
                                                <div className="col-sm-6 p-r-25 p-r-15-sr991">
                                                    {/* ============= ITEM2A Economía ============= */}
                                                    {/* Item post */}
                                                    <div className="m-b-30">
                                                        <a href="#" className="wrap-pic-w hov1 trans-03">
                                                            <img src="images/post-13.jpg" alt="IMG" />
                                                        </a>
                                                        <div className="p-t-20">
                                                            <h5 className="p-b-5">
                                                                <a
                                                                    href="#"
                                                                    className="f1-m-3 cl2 hov-cl10 trans-03"
                                                                >
                                                                    Finance lorem ipsum dolor sit amet consectetur
                                                                </a>
                                                            </h5>
                                                            <span className="cl8">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-4 cl8 hov-cl10 trans-03"
                                                                >
                                                                    Finance
                                                                </a>
                                                                <span className="f1-s-3 m-rl-3">-</span>
                                                                <span className="f1-s-3">Sep 18</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 p-r-25 p-r-15-sr991">
                                                    {/* ============= ITEM2B Economía ============= */}
                                                    {/* Item post */}
                                                    <div className="flex-wr-sb-s m-b-30">
                                                        <a
                                                            href="#"
                                                            className="size-w-1 wrap-pic-w hov1 trans-03"
                                                        >
                                                            <img src="images/post-12.jpg" alt="IMG" />
                                                        </a>
                                                        <div className="size-w-2">
                                                            <h5 className="p-b-5">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                >
                                                                    Donec metus orci, malesuada et lectus vitae
                                                                </a>
                                                            </h5>
                                                            <span className="cl8">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                >
                                                                    Negocios Pequeños
                                                                </a>
                                                                <span className="f1-s-3 m-rl-3">-</span>
                                                                <span className="f1-s-3">Sep 17</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    {/* ============= ITEM2C Economía ============= */}
                                                    {/* Item post */}
                                                    <div className="flex-wr-sb-s m-b-30">
                                                        <a
                                                            href="#"
                                                            className="size-w-1 wrap-pic-w hov1 trans-03"
                                                        >
                                                            <img src="images/post-11.jpg" alt="IMG" />
                                                        </a>
                                                        <div className="size-w-2">
                                                            <h5 className="p-b-5">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                >
                                                                    Donec metus orci, malesuada et lectus vitae
                                                                </a>
                                                            </h5>
                                                            <span className="cl8">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                >
                                                                    Economy
                                                                </a>
                                                                <span className="f1-s-3 m-rl-3">-</span>
                                                                <span className="f1-s-3">Sep 16</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    {/* ============= ITEM2D Economía ============= */}
                                                    {/* Item post */}
                                                    <div className="flex-wr-sb-s m-b-30">
                                                        <a
                                                            href="#"
                                                            className="size-w-1 wrap-pic-w hov1 trans-03"
                                                        >
                                                            <img src="images/post-10.jpg" alt="IMG" />
                                                        </a>
                                                        <div className="size-w-2">
                                                            <h5 className="p-b-5">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                >
                                                                    Donec metus orci, malesuada et lectus vitae
                                                                </a>
                                                            </h5>
                                                            <span className="cl8">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                >
                                                                    Money &amp; Markets
                                                                </a>
                                                                <span className="f1-s-3 m-rl-3">-</span>
                                                                <span className="f1-s-3">Sep 12</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* - */}
                                        <div className="tab-pane fade" id="tab2-3" role="tabpanel">
                                            <div className="row">
                                                <div className="col-sm-6 p-r-25 p-r-15-sr991">
                                                    {/* ============= ITEM3A Economía ============= */}
                                                    {/* Item post */}
                                                    <div className="m-b-30">
                                                        <a href="#" className="wrap-pic-w hov1 trans-03">
                                                            <img src="images/post-11.jpg" alt="IMG" />
                                                        </a>
                                                        <div className="p-t-20">
                                                            <h5 className="p-b-5">
                                                                <a
                                                                    href="#"
                                                                    className="f1-m-3 cl2 hov-cl10 trans-03"
                                                                >
                                                                    Finance lorem ipsum dolor sit amet consectetur
                                                                </a>
                                                            </h5>
                                                            <span className="cl8">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-4 cl8 hov-cl10 trans-03"
                                                                >
                                                                    Finance
                                                                </a>
                                                                <span className="f1-s-3 m-rl-3">-</span>
                                                                <span className="f1-s-3">Sep 18</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 p-r-25 p-r-15-sr991">
                                                    {/* ============= ITEM3B Economía ============= */}
                                                    {/* Item post */}
                                                    <div className="flex-wr-sb-s m-b-30">
                                                        <a
                                                            href="#"
                                                            className="size-w-1 wrap-pic-w hov1 trans-03"
                                                        >
                                                            <img src="images/post-12.jpg" alt="IMG" />
                                                        </a>
                                                        <div className="size-w-2">
                                                            <h5 className="p-b-5">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                >
                                                                    Donec metus orci, malesuada et lectus vitae
                                                                </a>
                                                            </h5>
                                                            <span className="cl8">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                >
                                                                    Negocios Pequeños
                                                                </a>
                                                                <span className="f1-s-3 m-rl-3">-</span>
                                                                <span className="f1-s-3">Sep 17</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    {/* ============= ITEM3C Economía ============= */}
                                                    {/* Item post */}
                                                    <div className="flex-wr-sb-s m-b-30">
                                                        <a
                                                            href="#"
                                                            className="size-w-1 wrap-pic-w hov1 trans-03"
                                                        >
                                                            <img src="images/post-13.jpg" alt="IMG" />
                                                        </a>
                                                        <div className="size-w-2">
                                                            <h5 className="p-b-5">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                >
                                                                    Donec metus orci, malesuada et lectus vitae
                                                                </a>
                                                            </h5>
                                                            <span className="cl8">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                >
                                                                    Economy
                                                                </a>
                                                                <span className="f1-s-3 m-rl-3">-</span>
                                                                <span className="f1-s-3">Sep 16</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    {/* ============= ITEM3D Economía ============= */}
                                                    {/* Item post */}
                                                    <div className="flex-wr-sb-s m-b-30">
                                                        <a
                                                            href="#"
                                                            className="size-w-1 wrap-pic-w hov1 trans-03"
                                                        >
                                                            <img src="images/post-10.jpg" alt="IMG" />
                                                        </a>
                                                        <div className="size-w-2">
                                                            <h5 className="p-b-5">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                >
                                                                    Donec metus orci, malesuada et lectus vitae
                                                                </a>
                                                            </h5>
                                                            <span className="cl8">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                >
                                                                    Money &amp; Markets
                                                                </a>
                                                                <span className="f1-s-3 m-rl-3">-</span>
                                                                <span className="f1-s-3">Sep 12</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* - */}
                                        <div className="tab-pane fade" id="tab2-4" role="tabpanel">
                                            <div className="row">
                                                <div className="col-sm-6 p-r-25 p-r-15-sr991">
                                                    {/* ============= ITEM4A Economía ============= */}
                                                    {/* Item post */}
                                                    <div className="m-b-30">
                                                        <a href="#" className="wrap-pic-w hov1 trans-03">
                                                            <img src="images/post-12.jpg" alt="IMG" />
                                                        </a>
                                                        <div className="p-t-20">
                                                            <h5 className="p-b-5">
                                                                <a
                                                                    href="#"
                                                                    className="f1-m-3 cl2 hov-cl10 trans-03"
                                                                >
                                                                    Finance lorem ipsum dolor sit amet consectetur
                                                                </a>
                                                            </h5>
                                                            <span className="cl8">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-4 cl8 hov-cl10 trans-03"
                                                                >
                                                                    Finance
                                                                </a>
                                                                <span className="f1-s-3 m-rl-3">-</span>
                                                                <span className="f1-s-3">Sep 18</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 p-r-25 p-r-15-sr991">
                                                    {/* ============= ITEM4B Economía ============= */}
                                                    {/* Item post */}
                                                    <div className="flex-wr-sb-s m-b-30">
                                                        <a
                                                            href="#"
                                                            className="size-w-1 wrap-pic-w hov1 trans-03"
                                                        >
                                                            <img src="images/post-13.jpg" alt="IMG" />
                                                        </a>
                                                        <div className="size-w-2">
                                                            <h5 className="p-b-5">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                >
                                                                    Donec metus orci, malesuada et lectus vitae
                                                                </a>
                                                            </h5>
                                                            <span className="cl8">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                >
                                                                    Negocios Pequeños
                                                                </a>
                                                                <span className="f1-s-3 m-rl-3">-</span>
                                                                <span className="f1-s-3">Sep 17</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    {/* ============= ITEM4C Economía ============= */}
                                                    {/* Item post */}
                                                    <div className="flex-wr-sb-s m-b-30">
                                                        <a
                                                            href="#"
                                                            className="size-w-1 wrap-pic-w hov1 trans-03"
                                                        >
                                                            <img src="images/post-10.jpg" alt="IMG" />
                                                        </a>
                                                        <div className="size-w-2">
                                                            <h5 className="p-b-5">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                >
                                                                    Donec metus orci, malesuada et lectus vitae
                                                                </a>
                                                            </h5>
                                                            <span className="cl8">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                >
                                                                    Economy
                                                                </a>
                                                                <span className="f1-s-3 m-rl-3">-</span>
                                                                <span className="f1-s-3">Sep 16</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    {/* ============= ITEM4D Economía ============= */}
                                                    {/* Item post */}
                                                    <div className="flex-wr-sb-s m-b-30">
                                                        <a
                                                            href="#"
                                                            className="size-w-1 wrap-pic-w hov1 trans-03"
                                                        >
                                                            <img src="images/post-11.jpg" alt="IMG" />
                                                        </a>
                                                        <div className="size-w-2">
                                                            <h5 className="p-b-5">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                >
                                                                    Donec metus orci, malesuada et lectus vitae
                                                                </a>
                                                            </h5>
                                                            <span className="cl8">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                >
                                                                    Money &amp; Markets
                                                                </a>
                                                                <span className="f1-s-3 m-rl-3">-</span>
                                                                <span className="f1-s-3">Sep 12</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/*===============================================================================================*/}
                                {/*================================== BLOQUE SOCIEDAD =====================================*/}
                                {/*===============================================================================================*/}
                                {/* Travel */}
                                <div className="tab01 p-b-20">
                                    <div className="tab01-head how2 how2-cl3 bocl12 flex-s-c m-r-10 m-r-0-sr991">
                                        {/* Brand tab */}
                                        <h3 className="f1-m-2 cl14 tab01-title">Sociedad</h3>
                                        {/* Nav tabs */}
                                        <ul className="nav nav-tabs" role="tablist">
                                            <li className="nav-item">
                                                <a
                                                    className="nav-link active"
                                                    data-toggle="tab"
                                                    href="#tab3-1"
                                                    role="tab"
                                                >
                                                    Todo
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a
                                                    className="nav-link"
                                                    data-toggle="tab"
                                                    href="#tab3-2"
                                                    role="tab"
                                                >
                                                    Hoteles
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a
                                                    className="nav-link"
                                                    data-toggle="tab"
                                                    href="#tab3-3"
                                                    role="tab"
                                                >
                                                    Vuelos
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a
                                                    className="nav-link"
                                                    data-toggle="tab"
                                                    href="#tab3-4"
                                                    role="tab"
                                                >
                                                    Restaurantes
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a
                                                    className="nav-link"
                                                    data-toggle="tab"
                                                    href="#tab3-5"
                                                    role="tab"
                                                >
                                                    Cultura
                                                </a>
                                            </li>
                                            <li className="nav-item-more dropdown dis-none">
                                                <a
                                                    className="nav-link dropdown-toggle"
                                                    data-toggle="dropdown"
                                                    href="#"
                                                >
                                                    <i className="fa fa-ellipsis-h" />
                                                </a>
                                                <ul className="dropdown-Menu"></ul>
                                            </li>
                                        </ul>
                                        {/*  */}
                                        <a
                                            href="es/economy.html"
                                            className="tab01-link f1-s-1 cl9 hov-cl10 trans-03"
                                        >
                                            Ver Todo
                                            <i className="fs-12 m-l-5 fa fa-caret-right" />
                                        </a>
                                    </div>
                                    {/* Tab panes */}
                                    <div className="tab-content p-t-35">
                                        {/* - */}
                                        <div
                                            className="tab-pane fade show active"
                                            id="tab3-1"
                                            role="tabpanel"
                                        >
                                            <div className="row">
                                                <div className="col-sm-6 p-r-25 p-r-15-sr991">
                                                    {/* ============= ITEM1A Sociedad ============= */}
                                                    {/* Item post */}
                                                    <div className="m-b-30">
                                                        <a
                                                            href="es/ai/20230921-moscu-camaras-ia-multan-pasajeros.html"
                                                            className="wrap-pic-w hov1 trans-03"
                                                        >
                                                            <img src="images/camaras-multas-ia.png" alt="IMG" />
                                                        </a>
                                                        <div className="p-t-20">
                                                            <h5 className="p-b-5">
                                                                <a
                                                                    href="es/ai/20230921-moscu-camaras-ia-multan-pasajeros.html"
                                                                    className="f1-m-3 cl2 hov-cl10 trans-03"
                                                                >
                                                                    En Moscú, las cámaras con IA multarán a los
                                                                    pasajeros sin cintúron de seguridad.
                                                                </a>
                                                            </h5>
                                                            <span className="cl8">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-4 cl8 hov-cl10 trans-03"
                                                                >
                                                                    Sociedad
                                                                </a>
                                                                <span className="f1-s-3 m-rl-3">-</span>
                                                                <span className="f1-s-3">Sep 21</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 p-r-25 p-r-15-sr991">
                                                    {/* ============= ITEM1B Sociedad ============= */}
                                                    {/* Item post */}
                                                    <div className="flex-wr-sb-s m-b-30">
                                                        <a
                                                            href="#"
                                                            className="size-w-1 wrap-pic-w hov1 trans-03"
                                                        >
                                                            <img src="images/post-05.jpg" alt="IMG" />
                                                        </a>
                                                        <div className="size-w-2">
                                                            <h5 className="p-b-5">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                >
                                                                    Donec metus orci, malesuada et lectus vitae
                                                                </a>
                                                            </h5>
                                                            <span className="cl8">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                >
                                                                    Sociedad
                                                                </a>
                                                                <span className="f1-s-3 m-rl-3">-</span>
                                                                <span className="f1-s-3">Sep 17</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    {/* ============= ITEM1C Sociedad ============= */}
                                                    {/* Item post */}
                                                    <div className="flex-wr-sb-s m-b-30">
                                                        <a
                                                            href="#"
                                                            className="size-w-1 wrap-pic-w hov1 trans-03"
                                                        >
                                                            <img src="images/post-07.jpg" alt="IMG" />
                                                        </a>
                                                        <div className="size-w-2">
                                                            <h5 className="p-b-5">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                >
                                                                    Donec metus orci, malesuada et lectus vitae
                                                                </a>
                                                            </h5>
                                                            <span className="cl8">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                >
                                                                    Flight
                                                                </a>
                                                                <span className="f1-s-3 m-rl-3">-</span>
                                                                <span className="f1-s-3">Sep 16</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    {/* ============= ITEM1D Sociedad ============= */}
                                                    {/* Item post */}
                                                    <div className="flex-wr-sb-s m-b-30">
                                                        <a
                                                            href="#"
                                                            className="size-w-1 wrap-pic-w hov1 trans-03"
                                                        >
                                                            <img src="images/post-17.jpg" alt="IMG" />
                                                        </a>
                                                        <div className="size-w-2">
                                                            <h5 className="p-b-5">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                >
                                                                    Donec metus orci, malesuada et lectus vitae
                                                                </a>
                                                            </h5>
                                                            <span className="cl8">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                >
                                                                    Culture
                                                                </a>
                                                                <span className="f1-s-3 m-rl-3">-</span>
                                                                <span className="f1-s-3">Sep 12</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* - */}
                                        <div className="tab-pane fade" id="tab3-2" role="tabpanel">
                                            <div className="row">
                                                <div className="col-sm-6 p-r-25 p-r-15-sr991">
                                                    {/* ============= ITEM2A Sociedad ============= */}
                                                    {/* Item post */}
                                                    <div className="m-b-30">
                                                        <a href="#" className="wrap-pic-w hov1 trans-03">
                                                            <img src="images/post-15.jpg" alt="IMG" />
                                                        </a>
                                                        <div className="p-t-20">
                                                            <h5 className="p-b-5">
                                                                <a
                                                                    href="#"
                                                                    className="f1-m-3 cl2 hov-cl10 trans-03"
                                                                >
                                                                    You wish lorem ipsum dolor sit amet consectetur
                                                                </a>
                                                            </h5>
                                                            <span className="cl8">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-4 cl8 hov-cl10 trans-03"
                                                                >
                                                                    Hotels
                                                                </a>
                                                                <span className="f1-s-3 m-rl-3">-</span>
                                                                <span className="f1-s-3">Sep 18</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 p-r-25 p-r-15-sr991">
                                                    {/* ============= ITEM2B Sociedad ============= */}
                                                    {/* Item post */}
                                                    <div className="flex-wr-sb-s m-b-30">
                                                        <a
                                                            href="#"
                                                            className="size-w-1 wrap-pic-w hov1 trans-03"
                                                        >
                                                            <img src="images/post-16.jpg" alt="IMG" />
                                                        </a>
                                                        <div className="size-w-2">
                                                            <h5 className="p-b-5">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                >
                                                                    Donec metus orci, malesuada et lectus vitae
                                                                </a>
                                                            </h5>
                                                            <span className="cl8">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                >
                                                                    Sociedad
                                                                </a>
                                                                <span className="f1-s-3 m-rl-3">-</span>
                                                                <span className="f1-s-3">Sep 17</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    {/* ============= ITEM2C Sociedad ============= */}
                                                    {/* Item post */}
                                                    <div className="flex-wr-sb-s m-b-30">
                                                        <a
                                                            href="#"
                                                            className="size-w-1 wrap-pic-w hov1 trans-03"
                                                        >
                                                            <img src="images/post-17.jpg" alt="IMG" />
                                                        </a>
                                                        <div className="size-w-2">
                                                            <h5 className="p-b-5">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                >
                                                                    Donec metus orci, malesuada et lectus vitae
                                                                </a>
                                                            </h5>
                                                            <span className="cl8">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                >
                                                                    Flight
                                                                </a>
                                                                <span className="f1-s-3 m-rl-3">-</span>
                                                                <span className="f1-s-3">Sep 16</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    {/* ============= ITEM2D Sociedad ============= */}
                                                    {/* Item post */}
                                                    <div className="flex-wr-sb-s m-b-30">
                                                        <a
                                                            href="#"
                                                            className="size-w-1 wrap-pic-w hov1 trans-03"
                                                        >
                                                            <img src="images/post-18.jpg" alt="IMG" />
                                                        </a>
                                                        <div className="size-w-2">
                                                            <h5 className="p-b-5">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                >
                                                                    Donec metus orci, malesuada et lectus vitae
                                                                </a>
                                                            </h5>
                                                            <span className="cl8">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                >
                                                                    Culture
                                                                </a>
                                                                <span className="f1-s-3 m-rl-3">-</span>
                                                                <span className="f1-s-3">Sep 12</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* - */}
                                        <div className="tab-pane fade" id="tab3-3" role="tabpanel">
                                            <div className="row">
                                                <div className="col-sm-6 p-r-25 p-r-15-sr991">
                                                    {/* ============= ITEM3A Sociedad ============= */}
                                                    {/* Item post */}
                                                    <div className="m-b-30">
                                                        <a href="#" className="wrap-pic-w hov1 trans-03">
                                                            <img src="images/post-16.jpg" alt="IMG" />
                                                        </a>
                                                        <div className="p-t-20">
                                                            <h5 className="p-b-5">
                                                                <a
                                                                    href="#"
                                                                    className="f1-m-3 cl2 hov-cl10 trans-03"
                                                                >
                                                                    You wish lorem ipsum dolor sit amet consectetur
                                                                </a>
                                                            </h5>
                                                            <span className="cl8">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-4 cl8 hov-cl10 trans-03"
                                                                >
                                                                    Hotels
                                                                </a>
                                                                <span className="f1-s-3 m-rl-3">-</span>
                                                                <span className="f1-s-3">Sep 18</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 p-r-25 p-r-15-sr991">
                                                    {/* ============= ITEM3B Sociedad ============= */}
                                                    {/* Item post */}
                                                    <div className="flex-wr-sb-s m-b-30">
                                                        <a
                                                            href="#"
                                                            className="size-w-1 wrap-pic-w hov1 trans-03"
                                                        >
                                                            <img src="images/post-17.jpg" alt="IMG" />
                                                        </a>
                                                        <div className="size-w-2">
                                                            <h5 className="p-b-5">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                >
                                                                    Donec metus orci, malesuada et lectus vitae
                                                                </a>
                                                            </h5>
                                                            <span className="cl8">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                >
                                                                    Sociedad
                                                                </a>
                                                                <span className="f1-s-3 m-rl-3">-</span>
                                                                <span className="f1-s-3">Sep 17</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    {/* ============= ITEM3C Sociedad ============= */}
                                                    {/* Item post */}
                                                    <div className="flex-wr-sb-s m-b-30">
                                                        <a
                                                            href="#"
                                                            className="size-w-1 wrap-pic-w hov1 trans-03"
                                                        >
                                                            <img src="images/post-18.jpg" alt="IMG" />
                                                        </a>
                                                        <div className="size-w-2">
                                                            <h5 className="p-b-5">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                >
                                                                    Donec metus orci, malesuada et lectus vitae
                                                                </a>
                                                            </h5>
                                                            <span className="cl8">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                >
                                                                    Flight
                                                                </a>
                                                                <span className="f1-s-3 m-rl-3">-</span>
                                                                <span className="f1-s-3">Sep 16</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    {/* ============= ITEM3D Sociedad ============= */}
                                                    {/* Item post */}
                                                    <div className="flex-wr-sb-s m-b-30">
                                                        <a
                                                            href="#"
                                                            className="size-w-1 wrap-pic-w hov1 trans-03"
                                                        >
                                                            <img src="images/post-24.jpg" alt="IMG" />
                                                        </a>
                                                        <div className="size-w-2">
                                                            <h5 className="p-b-5">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                >
                                                                    Donec metus orci, malesuada et lectus vitae
                                                                </a>
                                                            </h5>
                                                            <span className="cl8">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                >
                                                                    Culture
                                                                </a>
                                                                <span className="f1-s-3 m-rl-3">-</span>
                                                                <span className="f1-s-3">Sep 12</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* - */}
                                        <div className="tab-pane fade" id="tab3-4" role="tabpanel">
                                            <div className="row">
                                                <div className="col-sm-6 p-r-25 p-r-15-sr991">
                                                    {/* ============= ITEM4A Sociedad ============= */}
                                                    {/* Item post */}
                                                    <div className="m-b-30">
                                                        <a href="#" className="wrap-pic-w hov1 trans-03">
                                                            <img src="images/post-17.jpg" alt="IMG" />
                                                        </a>
                                                        <div className="p-t-20">
                                                            <h5 className="p-b-5">
                                                                <a
                                                                    href="#"
                                                                    className="f1-m-3 cl2 hov-cl10 trans-03"
                                                                >
                                                                    You wish lorem ipsum dolor sit amet consectetur
                                                                </a>
                                                            </h5>
                                                            <span className="cl8">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-4 cl8 hov-cl10 trans-03"
                                                                >
                                                                    Hotels
                                                                </a>
                                                                <span className="f1-s-3 m-rl-3">-</span>
                                                                <span className="f1-s-3">Sep 18</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 p-r-25 p-r-15-sr991">
                                                    {/* ============= ITEM4B Sociedad ============= */}
                                                    {/* Item post */}
                                                    <div className="flex-wr-sb-s m-b-30">
                                                        <a
                                                            href="#"
                                                            className="size-w-1 wrap-pic-w hov1 trans-03"
                                                        >
                                                            <img src="images/post-18.jpg" alt="IMG" />
                                                        </a>
                                                        <div className="size-w-2">
                                                            <h5 className="p-b-5">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                >
                                                                    Donec metus orci, malesuada et lectus vitae
                                                                </a>
                                                            </h5>
                                                            <span className="cl8">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                >
                                                                    Sociedad
                                                                </a>
                                                                <span className="f1-s-3 m-rl-3">-</span>
                                                                <span className="f1-s-3">Sep 17</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    {/* ============= ITEM4C Sociedad ============= */}
                                                    {/* Item post */}
                                                    <div className="flex-wr-sb-s m-b-30">
                                                        <a
                                                            href="#"
                                                            className="size-w-1 wrap-pic-w hov1 trans-03"
                                                        >
                                                            <img src="images/post-24.jpg" alt="IMG" />
                                                        </a>
                                                        <div className="size-w-2">
                                                            <h5 className="p-b-5">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                >
                                                                    Donec metus orci, malesuada et lectus vitae
                                                                </a>
                                                            </h5>
                                                            <span className="cl8">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                >
                                                                    Flight
                                                                </a>
                                                                <span className="f1-s-3 m-rl-3">-</span>
                                                                <span className="f1-s-3">Sep 16</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    {/* ============= ITEM4D Sociedad ============= */}
                                                    {/* Item post */}
                                                    <div className="flex-wr-sb-s m-b-30">
                                                        <a
                                                            href="#"
                                                            className="size-w-1 wrap-pic-w hov1 trans-03"
                                                        >
                                                            <img src="images/post-15.jpg" alt="IMG" />
                                                        </a>
                                                        <div className="size-w-2">
                                                            <h5 className="p-b-5">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                >
                                                                    Donec metus orci, malesuada et lectus vitae
                                                                </a>
                                                            </h5>
                                                            <span className="cl8">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                >
                                                                    Culture
                                                                </a>
                                                                <span className="f1-s-3 m-rl-3">-</span>
                                                                <span className="f1-s-3">Sep 12</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* - */}
                                        <div className="tab-pane fade" id="tab3-5" role="tabpanel">
                                            <div className="row">
                                                <div className="col-sm-6 p-r-25 p-r-15-sr991">
                                                    {/* ============= ITEM5A Sociedad ============= */}
                                                    {/* Item post */}
                                                    <div className="m-b-30">
                                                        <a href="#" className="wrap-pic-w hov1 trans-03">
                                                            <img src="images/post-18.jpg" alt="IMG" />
                                                        </a>
                                                        <div className="p-t-20">
                                                            <h5 className="p-b-5">
                                                                <a
                                                                    href="#"
                                                                    className="f1-m-3 cl2 hov-cl10 trans-03"
                                                                >
                                                                    You wish lorem ipsum dolor sit amet consectetur
                                                                </a>
                                                            </h5>
                                                            <span className="cl8">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-4 cl8 hov-cl10 trans-03"
                                                                >
                                                                    Hotels
                                                                </a>
                                                                <span className="f1-s-3 m-rl-3">-</span>
                                                                <span className="f1-s-3">Sep 18</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 p-r-25 p-r-15-sr991">
                                                    {/* ============= ITEM5B Sociedad ============= */}
                                                    {/* Item post */}
                                                    <div className="flex-wr-sb-s m-b-30">
                                                        <a
                                                            href="#"
                                                            className="size-w-1 wrap-pic-w hov1 trans-03"
                                                        >
                                                            <img src="images/post-17.jpg" alt="IMG" />
                                                        </a>
                                                        <div className="size-w-2">
                                                            <h5 className="p-b-5">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                >
                                                                    Donec metus orci, malesuada et lectus vitae
                                                                </a>
                                                            </h5>
                                                            <span className="cl8">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                >
                                                                    Sociedad
                                                                </a>
                                                                <span className="f1-s-3 m-rl-3">-</span>
                                                                <span className="f1-s-3">Sep 17</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    {/* ============= ITEM5C Sociedad ============= */}
                                                    {/* Item post */}
                                                    <div className="flex-wr-sb-s m-b-30">
                                                        <a
                                                            href="#"
                                                            className="size-w-1 wrap-pic-w hov1 trans-03"
                                                        >
                                                            <img src="images/post-16.jpg" alt="IMG" />
                                                        </a>
                                                        <div className="size-w-2">
                                                            <h5 className="p-b-5">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                >
                                                                    Donec metus orci, malesuada et lectus vitae
                                                                </a>
                                                            </h5>
                                                            <span className="cl8">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                >
                                                                    Flight
                                                                </a>
                                                                <span className="f1-s-3 m-rl-3">-</span>
                                                                <span className="f1-s-3">Sep 16</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    {/* ============= ITEM5D Sociedad ============= */}
                                                    {/* Item post */}
                                                    <div className="flex-wr-sb-s m-b-30">
                                                        <a
                                                            href="#"
                                                            className="size-w-1 wrap-pic-w hov1 trans-03"
                                                        >
                                                            <img src="images/post-15.jpg" alt="IMG" />
                                                        </a>
                                                        <div className="size-w-2">
                                                            <h5 className="p-b-5">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                >
                                                                    Donec metus orci, malesuada et lectus vitae
                                                                </a>
                                                            </h5>
                                                            <span className="cl8">
                                                                <a
                                                                    href="#"
                                                                    className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                >
                                                                    Culture
                                                                </a>
                                                                <span className="f1-s-3 m-rl-3">-</span>
                                                                <span className="f1-s-3">Sep 12</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/*================================== FIN DE BLOQUES ======================================*/}
                            </div>
                        </div>


                        {/*===============================================================================================*/}
                        {/*================================== PRIMERA BARRA LATERAL ======================================*/}
                        {/*===============================================================================================*/}
                        <div className="col-md-10 col-lg-4">
                            <div className="p-l-10 p-rl-0-sr991 p-b-20">
                                {/*  */}
                                <div>
                                    <div className="how2 how2-cl4 flex-s-c">
                                        <h3 className="f1-m-2 cl3 tab01-title">Más Popular</h3>
                                    </div>
                                    <ul className="p-t-35">
                                        <li className="flex-wr-sb-s p-b-22">
                                            <div className="size-a-8 flex-c-c borad-3 size-a-8 bg9 f1-m-4 cl0 m-b-6">
                                                1
                                            </div>
                                            <a
                                                href="es/economy/20230923-resistencia-de-la%20economia-rusa.html"
                                                className="size-w-3 f1-s-7 cl3 hov-cl10 trans-03"
                                            >
                                                "Тal cosa no se esperaba": en Occidente se sorprenden por la
                                                resistencia
                                            </a>
                                        </li>
                                        <li className="flex-wr-sb-s p-b-22">
                                            <div className="size-a-8 flex-c-c borad-3 size-a-8 bg9 f1-m-4 cl0 m-b-6">
                                                2
                                            </div>
                                            <a
                                                href="es/politics/20230923-Rusia-dispuesta-a-negociar-sobre-Ucrania.html"
                                                className="size-w-3 f1-s-7 cl3 hov-cl10 trans-03"
                                            >
                                                Rusia está dispuesta a negociar sobre Ucrania
                                            </a>
                                        </li>
                                        <li className="flex-wr-sb-s p-b-22">
                                            <div className="size-a-8 flex-c-c borad-3 size-a-8 bg9 f1-m-4 cl0 m-b-6">
                                                3
                                            </div>
                                            <a
                                                href="es/politics/20230920-kremlin-informa-visita-de-Putin-a-China.html"
                                                className="size-w-3 f1-s-7 cl3 hov-cl10 trans-03"
                                            >
                                                Se está preparando la visita de Putin a China
                                            </a>
                                        </li>
                                        <li className="flex-wr-sb-s p-b-22">
                                            <div className="size-a-8 flex-c-c borad-3 size-a-8 bg9 f1-m-4 cl0 m-b-6">
                                                4
                                            </div>
                                            <a
                                                href="es/politics/20230920-kremlin-informa-visita-de-Putin-a-China.html"
                                                className="size-w-3 f1-s-7 cl3 hov-cl10 trans-03"
                                            >
                                                En el Kremlin afirmaron que se está preparando la visita de
                                                Putin a China
                                            </a>
                                        </li>
                                        <li className="flex-wr-sb-s p-b-22">
                                            <div className="size-a-8 flex-c-c borad-3 size-a-8 bg9 f1-m-4 cl0">
                                                5
                                            </div>
                                            <a
                                                href="es/ai/20230921-moscu-camaras-ia-multan-pasajeros.html"
                                                className="size-w-3 f1-s-7 cl3 hov-cl10 trans-03"
                                            >
                                                Cámaras con IA comenzarán a multar a los pasajeros sin
                                                cinturón de seguridad
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                {/*============================= BANNER LATERAL =================================*/}
                                {/*  */}
                                <div className="flex-c-s p-t-8">
                                    <a href="#">
                                        <img
                                            className="max-w-full"
                                            src="images/banner-02.jpg"
                                            alt="IMG"
                                        />
                                    </a>
                                </div>
                                {/*============================= REDES LATERAL =================================*/}
                                {/*  */}
                                <div className="p-t-50">
                                    <div className="how2 how2-cl4 flex-s-c">
                                        <h3 className="f1-m-2 cl3 tab01-title">Manténte Conectado</h3>
                                    </div>
                                    <ul className="p-t-35">
                                        <li className="flex-wr-sb-c p-b-20">
                                            <a
                                                href="#"
                                                className="size-a-8 flex-c-c borad-3 size-a-8 bg-facebook fs-16 cl0 hov-cl0"
                                            >
                                                <span className="fab fa-facebook-f" />
                                            </a>
                                            <div className="size-w-3 flex-wr-sb-c">
                                                <span className="f1-s-8 cl3 p-r-20">6879 Fans</span>
                                                <a
                                                    href="#"
                                                    className="f1-s-9 text-uppercase cl3 hov-cl10 trans-03"
                                                >
                                                    Like
                                                </a>
                                            </div>
                                        </li>
                                        <li className="flex-wr-sb-c p-b-20">
                                            <a
                                                href="#"
                                                className="size-a-8 flex-c-c borad-3 size-a-8 bg-twitter fs-16 cl0 hov-cl0"
                                            >
                                                <span className="fab fa-twitter" />
                                            </a>
                                            <div className="size-w-3 flex-wr-sb-c">
                                                <span className="f1-s-8 cl3 p-r-20">568 Followers</span>
                                                <a
                                                    href="#"
                                                    className="f1-s-9 text-uppercase cl3 hov-cl10 trans-03"
                                                >
                                                    Follow
                                                </a>
                                            </div>
                                        </li>
                                        <li className="flex-wr-sb-c p-b-20">
                                            <a
                                                href="#"
                                                className="size-a-8 flex-c-c borad-3 size-a-8 bg-youtube fs-16 cl0 hov-cl0"
                                            >
                                                <span className="fab fa-youtube" />
                                            </a>
                                            <div className="size-w-3 flex-wr-sb-c">
                                                <span className="f1-s-8 cl3 p-r-20">5039 Subscribers</span>
                                                <a
                                                    href="#"
                                                    className="f1-s-9 text-uppercase cl3 hov-cl10 trans-03"
                                                >
                                                    Subscribe
                                                </a>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            {/*=============================================================================================*/}
            {/*================================== BANNER HORIZONTAL ========================================*/}
            {/*=============================================================================================*/}
            {/* Banner */}
            <div className="container">
                <div className="flex-c-c">
                    <a href="#">
                        <img className="max-w-full" src="images/banner-01.jpg" alt="IMG" />
                    </a>
                </div>
            </div>



            {/*===============================================================================================*/}
            {/*================================== ÚLTIMOS ARTÍCULOS ========================================*/}
            {/*===============================================================================================*/}
            {/* Latest */}
            <section className="bg0 p-t-60 p-b-35">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-10 col-lg-8 p-b-20">
                            <div className="how2 how2-cl4 flex-s-c m-r-10 m-r-0-sr991">
                                <h3 className="f1-m-2 cl3 tab01-title">Últimos Artículos</h3>
                            </div>
                            <div className="row p-t-35">
                                <div className="col-sm-6 p-r-25 p-r-15-sr991">
                                    {/*============ ARTÍCULO 1 ============*/}
                                    {/* Item latest */}
                                    <div className="m-b-45">
                                        <a
                                            href="es/economy/20230927-Binance-vende-sus-activos-en-Rusia.html"
                                            className="wrap-pic-w hov1 trans-03"
                                        >
                                            <img src="images/crypto-bitcoin-binance.png" alt="IMG" />
                                        </a>
                                        <div className="p-t-16">
                                            <h5 className="p-b-5">
                                                <a
                                                    href="es/economy/20230927-Binance-vende-sus-activos-en-Rusia.html"
                                                    className="f1-m-3 cl2 hov-cl10 trans-03"
                                                >
                                                    La criptobolsa BINANCE vende todos sus activos en Rusia
                                                </a>
                                            </h5>
                                            <span className="cl8">
                                                <a href="#" className="f1-s-4 cl8 hov-cl10 trans-03">
                                                    by Belén García
                                                </a>
                                                <span className="f1-s-3 m-rl-3">-</span>
                                                <span className="f1-s-3">Sep 27</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 p-r-25 p-r-15-sr991">
                                    {/*============ ARTÍCULO 2 ============*/}
                                    {/* Item latest */}
                                    <div className="m-b-45">
                                        <a
                                            href="es/economy/20230926-Se-espera-fortalecer-el-rublo-hasta-87.5-rublos-por-dolar.html"
                                            className="wrap-pic-w hov1 trans-03"
                                        >
                                            <img src="images/rublo-ruso-monedas.png" alt="IMG" />
                                        </a>
                                        <div className="p-t-16">
                                            <h5 className="p-b-5">
                                                <a
                                                    href="es/economy/20230926-Se-espera-fortalecer-el-rublo-hasta-87.5-rublos-por-dolar.html"
                                                    className="f1-m-3 cl2 hov-cl10 trans-03"
                                                >
                                                    El Ministerio de Economía espera fortalecer el rublo hasta
                                                    87.5 rublos por dólar{" "}
                                                </a>
                                            </h5>
                                            <span className="cl8">
                                                <a href="#" className="f1-s-4 cl8 hov-cl10 trans-03">
                                                    by Belén García
                                                </a>
                                                <span className="f1-s-3 m-rl-3">-</span>
                                                <span className="f1-s-3">Sep 26</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 p-r-25 p-r-15-sr991">
                                    {/*============ ARTÍCULO 3 ============*/}
                                    {/* Item latest */}
                                    <div className="m-b-45">
                                        <a
                                            href="es/ai/20230926-Rusia-destina-5,2-mil-millones-de-rublos-inteligencia-artificial.html"
                                            className="wrap-pic-w hov1 trans-03"
                                        >
                                            <img src="images/ia-robot-tecno.png" alt="IMG" />
                                        </a>
                                        <div className="p-t-16">
                                            <h5 className="p-b-5">
                                                <a
                                                    href="es/ai/20230926-Rusia-destina-5,2-mil-millones-de-rublos-inteligencia-artificial.html"
                                                    className="f1-m-3 cl2 hov-cl10 trans-03"
                                                >
                                                    El Gobierno Ruso destinará 5,2 mil millones al desarrollo
                                                    de la inteligencia artificial
                                                </a>
                                            </h5>
                                            <span className="cl8">
                                                <a href="#" className="f1-s-4 cl8 hov-cl10 trans-03">
                                                    by Valeria Leon
                                                </a>
                                                <span className="f1-s-3 m-rl-3">-</span>
                                                <span className="f1-s-3">Sep 26</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 p-r-25 p-r-15-sr991">
                                    {/*============ ARTÍCULO 4 ============*/}
                                    {/* Item latest */}
                                    <div className="m-b-45">
                                        <a
                                            href="es/economy/20230925-Rusia-exporta-4.9-millones-de-toneladas-de-trigo.html"
                                            className="wrap-pic-w hov1 trans-03"
                                        >
                                            <img
                                                src="images/trigo-agro-exportacion-maquinaria.png"
                                                alt="IMG"
                                            />
                                        </a>
                                        <div className="p-t-16">
                                            <h5 className="p-b-5">
                                                <a
                                                    href="es/economy/20230925-Rusia-exporta-4.9-millones-de-toneladas-de-trigo.html"
                                                    className="f1-m-3 cl2 hov-cl10 trans-03"
                                                >
                                                    Rusia ha exportado 4.9 millones de toneladas de trigo y
                                                    puede llegar a 6 millones{" "}
                                                </a>
                                            </h5>
                                            <span className="cl8">
                                                <a href="#" className="f1-s-4 cl8 hov-cl10 trans-03">
                                                    by Belén García
                                                </a>
                                                <span className="f1-s-3 m-rl-3">-</span>
                                                <span className="f1-s-3">Sep 25</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 p-r-25 p-r-15-sr991">
                                    {/*============ ARTÍCULO 5 ============*/}
                                    {/* Item latest */}
                                    <div className="m-b-45">
                                        <a
                                            href="es/politics/20230923-Rusia-dispuesta-a-negociar-sobre-Ucrania.html"
                                            className="wrap-pic-w hov1 trans-03"
                                        >
                                            <img src="images/kremlin-dia-puente-cuadro.png" alt="IMG" />
                                        </a>
                                        <div className="p-t-16">
                                            <h5 className="p-b-5">
                                                <a
                                                    href="es/politics/20230923-Rusia-dispuesta-a-negociar-sobre-Ucrania.html"
                                                    className="f1-m-3 cl2 hov-cl10 trans-03"
                                                >
                                                    Rusia está dispuesta a negociar sobre Ucrania, anunció
                                                    Lavrov
                                                </a>
                                            </h5>
                                            <span className="cl8">
                                                <a href="#" className="f1-s-4 cl8 hov-cl10 trans-03">
                                                    by Ana Vargas
                                                </a>
                                                <span className="f1-s-3 m-rl-3">-</span>
                                                <span className="f1-s-3">Sep 24</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 p-r-25 p-r-15-sr991">
                                    {/*============ ARTÍCULO 6 ============*/}
                                    {/* Item latest */}
                                    <div className="m-b-45">
                                        <a
                                            href="es/economy/20230924-gazprom-suministra-a-europa-41millones-metros-cubicos-gas.html"
                                            className="wrap-pic-w hov1 trans-03"
                                        >
                                            <img src="images/tubos-de-gas.png" alt="IMG" />
                                        </a>
                                        <div className="p-t-16">
                                            <h5 className="p-b-5">
                                                <a
                                                    href="es/economy/20230924-gazprom-suministra-a-europa-41millones-metros-cubicos-gas.html"
                                                    className="f1-m-3 cl2 hov-cl10 trans-03"
                                                >
                                                    Rusia suministra a Europa 41,8 millones de metros cúbicos
                                                    de gas
                                                </a>
                                            </h5>
                                            <span className="cl8">
                                                <a href="#" className="f1-s-4 cl8 hov-cl10 trans-03">
                                                    by John Alvarado
                                                </a>
                                                <span className="f1-s-3 m-rl-3">-</span>
                                                <span className="f1-s-3">Sep 24</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*===========================================================================================*/}
                        {/*======================================= VIDEO =============================================*/}
                        {/*===========================================================================================*/}
                        <div className="col-md-10 col-lg-4">
                            <div className="p-l-10 p-rl-0-sr991 p-b-20">
                                {/* Video */}
                                <div className="p-b-55">
                                    <div className="how2 how2-cl4 flex-s-c m-b-35">
                                        <h3 className="f1-m-2 cl3 tab01-title">Vídeo Destacado</h3>
                                    </div>
                                    <div>
                                        <div className="wrap-pic-w pos-relative">
                                            <img src="images/video-01.png" alt="IMG" />
                                            <button
                                                className="s-full ab-t-l flex-c-c fs-32 cl0 hov-cl10 trans-03"
                                                data-toggle="modal"
                                                data-target="#modal-video-01"
                                            >
                                                <span className="fab fa-youtube" />
                                            </button>
                                        </div>
                                        <div className="p-tb-16 p-rl-25 bg3">
                                            <h5 className="p-b-5">
                                                <a href="#" className="f1-m-3 cl0 hov-cl10 trans-03">
                                                    El canto del alma, que llegará a tu corazón
                                                </a>
                                            </h5>
                                            <span className="cl15">
                                                <a href="#" className="f1-s-4 cl8 hov-cl10 trans-03">
                                                    by Ana Sáenz
                                                </a>
                                                <span className="f1-s-3 m-rl-3">-</span>
                                                <span className="f1-s-3">Sep 18</span>
                                            </span>
                                        </div>
                                    </div>



                                    {/* ========================== Modal Video 01 ========================== */}
                                    <div
                                        className="modal fade"
                                        id="modal-video-01"
                                        tabIndex={-1}
                                        role="dialog"
                                        aria-hidden="true"
                                    >
                                        <div className="modal-dialog" role="document" data-dismiss="modal">
                                            <div
                                                className="close-mo-video-01 trans-0-4"
                                                data-dismiss="modal"
                                                aria-label="Close"
                                            >
                                                ×
                                            </div>
                                            <div className="wrap-video-mo-01">
                                                <div className="video-mo-01">
                                                    <iframe
                                                        src="https://www.youtube.com/embed/ZP_FkF4Ha7I?rel=0"
                                                        title="Madre Rusia"
                                                        allowFullScreen
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                </div>
                                {/* Subscribe */}
                                <div className="bg10 p-rl-35 p-t-28 p-b-35 m-b-55">
                                    <h5 className="f1-m-5 cl0 p-b-10">Subscribe</h5>
                                    <p className="f1-s-1 cl0 p-b-25">
                                        Obtén el contenido actualizado a tu correo.
                                    </p>
                                    <form className="size-a-9 pos-relative">
                                        <input
                                            className="s-full f1-m-6 cl6 plh9 p-l-20 p-r-55"
                                            type="text"
                                            name="email"
                                            placeholder="Email"
                                        />
                                        <button className="size-a-10 flex-c-c ab-t-r fs-16 cl9 hov-cl10 trans-03">
                                            <i className="fa fa-arrow-right" />
                                        </button>
                                    </form>
                                </div>



                                {/* Tag */}
                                <div className="p-b-55">

                                    <div className="how2 how2-cl4 flex-s-c m-b-30">
                                        <h3 className="f1-m-2 cl3 tab01-title">Etiquetas</h3>
                                    </div>

                                    <div className="flex-wr-s-s m-rl--5">
                                        <Link to="/actual"
                                            className="flex-c-c size-h-2 bo-1-rad-20 bocl12 f1-s-1 cl8 hov-btn2 trans-03 p-rl-20 p-tb-5 m-all-5"
                                        > Actualidad </Link>

                                        <Link to="/politics"
                                            className="flex-c-c size-h-2 bo-1-rad-20 bocl12 f1-s-1 cl8 hov-btn2 trans-03 p-rl-20 p-tb-5 m-all-5"
                                        > Política </Link>

                                        <Link to="/events"
                                            className="flex-c-c size-h-2 bo-1-rad-20 bocl12 f1-s-1 cl8 hov-btn2 trans-03 p-rl-20 p-tb-5 m-all-5"
                                        > Eventos </Link>


                                        <Link to="/viral"
                                            className="flex-c-c size-h-2 bo-1-rad-20 bocl12 f1-s-1 cl8 hov-btn2 trans-03 p-rl-20 p-tb-5 m-all-5"
                                        > Viral </Link>

                                        <Link to="/society"
                                            className="flex-c-c size-h-2 bo-1-rad-20 bocl12 f1-s-1 cl8 hov-btn2 trans-03 p-rl-20 p-tb-5 m-all-5"
                                        > Sociedad </Link>

                                        <Link to="/trending"
                                            className="flex-c-c size-h-2 bo-1-rad-20 bocl12 f1-s-1 cl8 hov-btn2 trans-03 p-rl-20 p-tb-5 m-all-5"
                                        > Tendencia </Link>

                                        <Link to="/entertainment"
                                            className="flex-c-c size-h-2 bo-1-rad-20 bocl12 f1-s-1 cl8 hov-btn2 trans-03 p-rl-20 p-tb-5 m-all-5"
                                        > Entretenimiento </Link>

                                        <Link to="/business"
                                            className="flex-c-c size-h-2 bo-1-rad-20 bocl12 f1-s-1 cl8 hov-btn2 trans-03 p-rl-20 p-tb-5 m-all-5"
                                        > Negocios </Link>


                                    </div>


                                </div>
                            </div>




                        </div>
                    </div>
                </div>
            </section>






            <Footer />

        </main>



    );

}

export default Home;
