


import React from 'react';
import Footer from './Footer';
import Menu from './Menu';
import { Link } from "react-router-dom";


export default function Prueba() {



    return (

        <React.Fragment>

            <div   >
                {/* Header */}
                <header>
                    {/* ENCABEZADO ESCRITORIO */}
                    {/* Header desktop */}
                    <div className="container-menu-desktop">
                        <div className="topbar">
                            <div className="content-topbar container h-100">
                                <div className="left-topbar">
                                    <span className="left-topbar-item flex-wr-s-c">
                                        <span>MOSCÚ, MOS</span>
                                        <img
                                            className="m-b-1 m-rl-8"
                                            src="images/icons/icon-night.png"
                                            alt="IMG"
                                        />
                                        <span>HI 55° LO 37°</span>
                                    </span>
                                    <a href="#" className="left-topbar-item">
                                        Acerca de
                                    </a>
                                    <a href="#" className="left-topbar-item">
                                        Contacto
                                    </a>
                                    <a href="#" className="left-topbar-item">
                                        Sing up
                                    </a>
                                    <a href="#" className="left-topbar-item">
                                        Log in
                                    </a>
                                </div>
                                <div className="right-topbar">
                                    <a href="#">
                                        <span className="fab fa-vk" />
                                    </a>
                                    <a href="#">
                                        <span className="fab fa-telegram" />
                                    </a>
                                    <a href="#">
                                        <span className="fab fa-twitter" />
                                    </a>
                                    <a href="#">
                                        <span className="fab fa-facebook-f" />
                                    </a>
                                    <a href="#">
                                        <span className="fab fa-youtube" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        {/* ENCABEZADO MOVIL */}
                        {/* Header Mobile */}
                        <div className="wrap-header-mobile">
                            {/* Logo mobiLe */}
                            <div className="logo-mobile">
                                <a href="index.html">
                                    <img src="images/icons/logo-01.png" alt="IMG-LOGO" />
                                </a>
                            </div>
                            {/* Button show menu */}
                            <div className="btn-show-menu-mobile hamburger hamburger--squeeze m-r--8">
                                <span className="hamburger-box">
                                    <span className="hamburger-inner" />
                                </span>
                            </div>
                        </div>
                        {/* Menu Mobile */}
                        <div className="menu-mobile">
                            <ul className="topbar-mobile">
                                <li className="left-topbar">
                                    <span className="left-topbar-item flex-wr-s-c">
                                        <span>MOSCÚ, MOS</span>
                                        <img
                                            className="m-b-1 m-rl-8"
                                            src="images/icons/icon-night.png"
                                            alt="IMG"
                                        />
                                        <span>HI 55° LO 37°</span>
                                    </span>
                                </li>
                                <li className="left-topbar">
                                    <a href="#" className="left-topbar-item">
                                        Acerca de
                                    </a>
                                    <a href="#" className="left-topbar-item">
                                        Contacto
                                    </a>
                                    <a href="#" className="left-topbar-item">
                                        Sing up
                                    </a>
                                    <a href="#" className="left-topbar-item">
                                        Log in
                                    </a>
                                </li>
                                <li className="right-topbar">
                                    <a href="#">
                                        <span className="fab fa-vk" />
                                    </a>
                                    <a href="#">
                                        <span className="fab fa-telegram" />
                                    </a>
                                    <a href="#">
                                        <span className="fab fa-twitter" />
                                    </a>
                                    <a href="#">
                                        <span className="fab fa-facebook-f" />
                                    </a>
                                    <a href="#">
                                        <span className="fab fa-youtube" />
                                    </a>
                                </li>
                            </ul>
                            {/* MENU MOVIL */}
                            <ul className="main-menu-m">
                                <li>
                                    <a href="index.html">Inicio</a>
                                    <span className="arrow-main-menu-m">
                                        <i className="fa fa-angle-right" aria-hidden="true" />
                                    </span>
                                </li>
                                <li>
                                    <a href="es/economy.html">Actualidad</a>
                                </li>
                                <li>
                                    <a href="es/economy.html">Política</a>
                                </li>
                                <li>
                                    <a href="es/economy.html">Economía</a>
                                </li>
                                <li>
                                    <a href="es/economy.html">Tecnología</a>
                                </li>
                                <li>
                                    <a href="es/economy.html">Sociedad</a>
                                </li>
                                <li>
                                    <a href="es/video.html">Vídeo</a>
                                </li>
                                <li>
                                    <a href="#">Destacado</a>
                                    <ul className="sub-menu-m">
                                        {/*
							<li><a href="es/economy.html">Category Page v1</a></li>
							<li><a href="es/economy.html">Category Page v2</a></li>
							<li><a href="blog-grid.html">Blog Grid Sidebar</a></li>
							<li><a href="blog-list-01.html">Blog List Sidebar v1</a></li>
							<li><a href="blog-list-02.html">Blog List Sidebar v2</a></li>
							<li><a href="#">Blog Detail Sidebar</a></li>
							<li><a href="blog-detail-02.html">Blog Detail No Sidebar</a></li>
							*/}
                                        <li>
                                            <a href="about.html"> Acerca de </a>
                                        </li>
                                        <li>
                                            <a href="contact.html">Contáctanos</a>
                                        </li>
                                    </ul>
                                    <span className="arrow-main-menu-m">
                                        <i className="fa fa-angle-right" aria-hidden="true" />
                                    </span>
                                </li>
                            </ul>
                        </div>
                        {/*===============================================================================================*/}
                        {/*===============================================================================================*/}
                        {/* ESCRITORIO */}
                        {/*  */}
                        <div className="wrap-logo container">
                            {/* Logo desktop */}
                            <div className="logo">
                                <a href="index.html">
                                    <img src="images/icons/logo-01.png" alt="LOGO" />
                                </a>
                            </div>
                            {/* Banner */}
                            <div className="banner-header">
                                <a href="#">
                                    <img src="images/banner-01.jpg" alt="IMG" />
                                </a>
                            </div>
                        </div>
                        {/*  */}
                        <div className="wrap-main-nav">
                            <div className="main-nav">
                                {/* =================== MENU ESCRITORIO =================== */}
                                {/* Menu desktop */}
                                <nav className="menu-desktop">
                                    <a className="logo-stick" href="index.html">
                                        <img src="images/icons/logo-01.png" alt="LOGO" />
                                    </a>
                                    <ul className="main-menu">
                                        <li className="main-menu-active">
                                            <a href="index.html">Inicio</a>
                                        </li>
                                        {/*===============================================================================================*/}
                                        {/* ======= BLOQUE ACTUALIDAD (news) ======= */}
                                        <li className="mega-menu-item">
                                            <a href="es/economy.html">Actualidad</a>
                                            <div className="sub-mega-menu">
                                                <div className="nav flex-column nav-pills" role="tablist">
                                                    <a
                                                        className="nav-link active"
                                                        data-toggle="pill"
                                                        href="#news-0"
                                                        role="tab"
                                                    >
                                                        Todo
                                                    </a>
                                                    <a
                                                        className="nav-link"
                                                        data-toggle="pill"
                                                        href="#news-1"
                                                        role="tab"
                                                    >
                                                        Tendencia
                                                    </a>
                                                    <a
                                                        className="nav-link"
                                                        data-toggle="pill"
                                                        href="#news-2"
                                                        role="tab"
                                                    >
                                                        Tecnología
                                                    </a>
                                                    <a
                                                        className="nav-link"
                                                        data-toggle="pill"
                                                        href="#news-3"
                                                        role="tab"
                                                    >
                                                        Sociedad
                                                    </a>
                                                    {/*
										<a class="nav-link" data-toggle="pill" href="#news-4" role="tab">Eventos</a>
										<a class="nav-link" data-toggle="pill" href="#news-5" role="tab">Viral</a> */}
                                                </div>
                                                <div className="tab-content">
                                                    <div
                                                        className="tab-pane show active"
                                                        id="news-0"
                                                        role="tabpanel"
                                                    >
                                                        <div className="row">
                                                            {/* ======= ItemA Actualidad ======= */}
                                                            <div className="col-3">
                                                                {/* Item post */}
                                                                <div>
                                                                    <a
                                                                        href="es/economy/20230927-Binance-vende-sus-activos-en-Rusia.html"
                                                                        className="wrap-pic-w hov1 trans-03"
                                                                    >
                                                                        <img
                                                                            src="images/crypto-bitcoin-binance.png"
                                                                            alt="IMG"
                                                                        />
                                                                    </a>
                                                                    <div className="p-t-10">
                                                                        <h5 className="p-b-5">
                                                                            <a
                                                                                href="es/economy/20230927-Binance-vende-sus-activos-en-Rusia.html"
                                                                                className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                            >
                                                                                La criptobolsa BINANCE vende todos sus activos
                                                                                en Rusia
                                                                            </a>
                                                                        </h5>
                                                                        <span className="cl8">
                                                                            <a
                                                                                href="es/economy.html"
                                                                                className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                            >
                                                                                Economía
                                                                            </a>
                                                                            <span className="f1-s-3 m-rl-3">-</span>
                                                                            <span className="f1-s-3">Sep 27</span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* ======= ItemA1 Actualidad ======= */}
                                                            <div className="col-3">
                                                                {/* Item post */}
                                                                <div>
                                                                    <a
                                                                        href="es/economy/20230926-Se-espera-fortalecer-el-rublo-hasta-87.5-rublos-por-dolar.html"
                                                                        className="wrap-pic-w hov1 trans-03"
                                                                    >
                                                                        <img
                                                                            src="images/rublo-ruso-monedas.png"
                                                                            alt="IMG"
                                                                        />
                                                                    </a>
                                                                    <div className="p-t-10">
                                                                        <h5 className="p-b-5">
                                                                            <a
                                                                                href="es/economy/20230926-Se-espera-fortalecer-el-rublo-hasta-87.5-rublos-por-dolar.html"
                                                                                className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                            >
                                                                                El Ministerio de Economía espera fortalecer el
                                                                                rublo hasta 87.5 rublos por dólar
                                                                            </a>
                                                                        </h5>
                                                                        <span className="cl8">
                                                                            <a
                                                                                href="es/economy.html"
                                                                                className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                            >
                                                                                Economía
                                                                            </a>
                                                                            <span className="f1-s-3 m-rl-3">-</span>
                                                                            <span className="f1-s-3">Sep 26</span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* ======= ItemA  Actualidad ======= */}
                                                            <div className="col-3">
                                                                {/* Item post */}
                                                                <div>
                                                                    <a
                                                                        href="es/ai/20230926-Rusia-destina-5,2-mil-millones-de-rublos-inteligencia-artificial.html"
                                                                        className="wrap-pic-w hov1 trans-03"
                                                                    >
                                                                        <img src="images/ia-robot-tecno.png" alt="IMG" />
                                                                    </a>
                                                                    <div className="p-t-10">
                                                                        <h5 className="p-b-5">
                                                                            <a
                                                                                href="es/ai/20230926-Rusia-destina-5,2-mil-millones-de-rublos-inteligencia-artificial.html"
                                                                                className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                            >
                                                                                El Gobierno Ruso destinará 5,2 mil millones al
                                                                                desarrollo de la inteligencia artificial
                                                                            </a>
                                                                        </h5>
                                                                        <span className="cl8">
                                                                            <a
                                                                                href="#"
                                                                                className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                            >
                                                                                IA
                                                                            </a>
                                                                            <span className="f1-s-3 m-rl-3">-</span>
                                                                            <span className="f1-s-3">Sep 26</span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* ======= ItemA  Actualidad ======= */}
                                                            <div className="col-3">
                                                                {/* Item post */}
                                                                <div>
                                                                    <a
                                                                        href="es/economy/20230925-Rusia-exporta-4.9-millones-de-toneladas-de-trigo.html"
                                                                        className="wrap-pic-w hov1 trans-03"
                                                                    >
                                                                        <img
                                                                            src="images/trigo-agro-exportacion-maquinaria.png"
                                                                            alt="IMG"
                                                                        />
                                                                    </a>
                                                                    <div className="p-t-10">
                                                                        <h5 className="p-b-5">
                                                                            <a
                                                                                href="es/economy/20230925-Rusia-exporta-4.9-millones-de-toneladas-de-trigo.html"
                                                                                className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                            >
                                                                                Rusia ha exportado 4.9 millones de toneladas
                                                                                de trigo y puede llegar a 6 millones
                                                                            </a>
                                                                        </h5>
                                                                        <span className="cl8">
                                                                            <a
                                                                                href="es/economy.html"
                                                                                className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                            >
                                                                                Economía
                                                                            </a>
                                                                            <span className="f1-s-3 m-rl-3">-</span>
                                                                            <span className="f1-s-3">Sep 25</span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="tab-pane" id="news-1" role="tabpanel">
                                                        <div className="row">
                                                            {/* ======= ItemA2 Actualidad ======= */}
                                                            <div className="col-3">
                                                                {/* Item post */}
                                                                <div>
                                                                    <a
                                                                        href="es/politics/20230923-Rusia-dispuesta-a-negociar-sobre-Ucrania.html"
                                                                        className="wrap-pic-w hov1 trans-03"
                                                                    >
                                                                        <img
                                                                            src="images/kremlin-dia-puente-cuadro.png"
                                                                            alt="IMG"
                                                                        />
                                                                    </a>
                                                                    <div className="p-t-10">
                                                                        <h5 className="p-b-5">
                                                                            <a
                                                                                href="es/politics/20230923-Rusia-dispuesta-a-negociar-sobre-Ucrania.html"
                                                                                className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                            >
                                                                                Rusia está dispuesta a negociar sobre Ucrania,
                                                                                anunció Lavrov
                                                                            </a>
                                                                        </h5>
                                                                        <span className="cl8">
                                                                            <a
                                                                                href="#"
                                                                                className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                            >
                                                                                Política
                                                                            </a>
                                                                            <span className="f1-s-3 m-rl-3">-</span>
                                                                            <span className="f1-s-3">Sep 23</span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* ======= ItemA3 Actualidad ======= */}
                                                            <div className="col-3">
                                                                {/* Item post */}
                                                                <div>
                                                                    <a
                                                                        href="es/economy/20230924-gazprom-suministra-a-europa-41millones-metros-cubicos-gas.html"
                                                                        className="wrap-pic-w hov1 trans-03"
                                                                    >
                                                                        <img src="images/tubos-de-gas.png" alt="IMG" />
                                                                    </a>
                                                                    <div className="p-t-10">
                                                                        <h5 className="p-b-5">
                                                                            <a
                                                                                href="es/economy/20230924-gazprom-suministra-a-europa-41millones-metros-cubicos-gas.html"
                                                                                className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                            >
                                                                                "Gazprom" suministra a Europa 41,8 millones de
                                                                                metros cúbicos de gas
                                                                            </a>
                                                                        </h5>
                                                                        <span className="cl8">
                                                                            <a
                                                                                href="es/economy.html"
                                                                                className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                            >
                                                                                Economía
                                                                            </a>
                                                                            <span className="f1-s-3 m-rl-3">-</span>
                                                                            <span className="f1-s-3">Sep 24</span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* ======= ItemA4 Actualidad ======= */}
                                                            <div className="col-3">
                                                                {/* Item post */}
                                                                <div>
                                                                    <a
                                                                        href="es/politics/20230920-kremlin-informa-visita-de-Putin-a-China.html"
                                                                        className="wrap-pic-w hov1 trans-03"
                                                                    >
                                                                        <img src="images/kremlin.png" alt="IMG" />
                                                                    </a>
                                                                    <div className="p-t-10">
                                                                        <h5 className="p-b-5">
                                                                            <a
                                                                                href="es/politics/20230920-kremlin-informa-visita-de-Putin-a-China.html"
                                                                                className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                            >
                                                                                En el Kremlin afirmaron que se está preparando
                                                                                la visita de Putin a China.
                                                                            </a>
                                                                        </h5>
                                                                        <span className="cl8">
                                                                            <a
                                                                                href="es/economy.html"
                                                                                className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                            >
                                                                                Economía
                                                                            </a>
                                                                            <span className="f1-s-3 m-rl-3">-</span>
                                                                            <span className="f1-s-3">Sep 20</span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* ======= ItemB1 Actualidad ======= */}
                                                            <div className="col-3">
                                                                {/* Item post */}
                                                                <div>
                                                                    <a
                                                                        href="es/society/20230925-profesiones-prometedoras-desarrollo-en-Moscu.html"
                                                                        className="wrap-pic-w hov1 trans-03"
                                                                    >
                                                                        <img
                                                                            src="images/chicas-computadora-tecnologia.png"
                                                                            alt="IMG"
                                                                        />
                                                                    </a>
                                                                    <div className="p-t-10">
                                                                        <h5 className="p-b-5">
                                                                            <a
                                                                                href="es/society/20230925-profesiones-prometedoras-desarrollo-en-Moscu.html"
                                                                                className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                            >
                                                                                Las profesiones prometedoras en el desarrollo
                                                                                se discutirán en Moscú
                                                                            </a>
                                                                        </h5>
                                                                        <span className="cl8">
                                                                            <a
                                                                                href="#"
                                                                                className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                            >
                                                                                Actualidad
                                                                            </a>
                                                                            <span className="f1-s-3 m-rl-3">-</span>
                                                                            <span className="f1-s-3">Sep 25</span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="tab-pane" id="news-2" role="tabpanel">
                                                        <div className="row">
                                                            {/* ======= ItemB2 Actualidad ======= */}
                                                            <div className="col-3">
                                                                {/* Item post */}
                                                                <div>
                                                                    <a
                                                                        href="es/technology/20230920-Rusia-no-bloquea-WhatsApp-ni-YouTube.html"
                                                                        className="wrap-pic-w hov1 trans-03"
                                                                    >
                                                                        <img src="images/youtube.png" alt="IMG" />
                                                                    </a>
                                                                    <div className="p-t-10">
                                                                        <h5 className="p-b-5">
                                                                            <a
                                                                                href="es/technology/20230920-Rusia-no-bloquea-WhatsApp-ni-YouTube.html"
                                                                                className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                            >
                                                                                Rusia no tiene planes de bloquear WhatsApp ni
                                                                                YouTube.
                                                                            </a>
                                                                        </h5>
                                                                        <span className="cl8">
                                                                            <a
                                                                                href="#"
                                                                                className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                            >
                                                                                Tecnología
                                                                            </a>
                                                                            <span className="f1-s-3 m-rl-3">-</span>
                                                                            <span className="f1-s-3">Sep 20</span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* ======= ItemB3 Actualidad ======= */}
                                                            <div className="col-3">
                                                                {/* Item post */}
                                                                <div>
                                                                    <a
                                                                        href="es/politics/20230924-desdolarizacion-mundial-debido-a-los-BRICS.html"
                                                                        className="wrap-pic-w hov1 trans-03"
                                                                    >
                                                                        <img src="images/dolar-roto.png" alt="IMG" />
                                                                    </a>
                                                                    <div className="p-t-10">
                                                                        <h5 className="p-b-5">
                                                                            <a
                                                                                href="es/politics/20230924-desdolarizacion-mundial-debido-a-los-BRICS.html"
                                                                                className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                            >
                                                                                La desdolarización debido a los BRICS pondrá
                                                                                fin al dominio de los Estados Unidos
                                                                            </a>
                                                                        </h5>
                                                                        <span className="cl8">
                                                                            <a
                                                                                href="#"
                                                                                className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                            >
                                                                                Política
                                                                            </a>
                                                                            <span className="f1-s-3 m-rl-3">-</span>
                                                                            <span className="f1-s-3">Sep 24</span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* ======= ItemC1 Actualidad ======= */}
                                                            {/* ======= ItemC2 Actualidad ======= */}
                                                            <div className="col-3">
                                                                {/* Item post */}
                                                                <div>
                                                                    <a href="#" className="wrap-pic-w hov1 trans-03">
                                                                        <img src="images/post-24.jpg" alt="IMG" />
                                                                    </a>
                                                                    <div className="p-t-10">
                                                                        <h5 className="p-b-5">
                                                                            <a
                                                                                href="#"
                                                                                className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                            >
                                                                                20 Donec metus orci, malesuada et lectus vitae
                                                                            </a>
                                                                        </h5>
                                                                        <span className="cl8">
                                                                            <a
                                                                                href="#"
                                                                                className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                            >
                                                                                Viral
                                                                            </a>
                                                                            <span className="f1-s-3 m-rl-3">-</span>
                                                                            <span className="f1-s-3">Sep 15</span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* ======= ItemC3 Actualidad ======= */}
                                                            <div className="col-3">
                                                                {/* Item post */}
                                                                <div>
                                                                    <a href="#" className="wrap-pic-w hov1 trans-03">
                                                                        <img src="images/post-22.jpg" alt="IMG" />
                                                                    </a>
                                                                    <div className="p-t-10">
                                                                        <h5 className="p-b-5">
                                                                            <a
                                                                                href="#"
                                                                                className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                            >
                                                                                21 Donec metus orci, malesuada et lectus vitae
                                                                            </a>
                                                                        </h5>
                                                                        <span className="cl8">
                                                                            <a
                                                                                href="#"
                                                                                className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                            >
                                                                                Viral
                                                                            </a>
                                                                            <span className="f1-s-3 m-rl-3">-</span>
                                                                            <span className="f1-s-3">Sep 12</span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="tab-pane" id="news-3" role="tabpanel">
                                                        <div className="row">
                                                            {/* ======= ItemD1 Actualidad ======= */}
                                                            <div className="col-3">
                                                                {/* Item post */}
                                                                <div>
                                                                    <a href="#" className="wrap-pic-w hov1 trans-03">
                                                                        <img src="images/post-25.jpg" alt="IMG" />
                                                                    </a>
                                                                    <div className="p-t-10">
                                                                        <h5 className="p-b-5">
                                                                            <a
                                                                                href="#"
                                                                                className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                            >
                                                                                23 Donec metus orci, malesuada et lectus vitae
                                                                            </a>
                                                                        </h5>
                                                                        <span className="cl8">
                                                                            <a
                                                                                href="#"
                                                                                className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                            >
                                                                                Viral
                                                                            </a>
                                                                            <span className="f1-s-3 m-rl-3">-</span>
                                                                            <span className="f1-s-3">Sep 18</span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* ======= ItemD2 Actualidad ======= */}
                                                            <div className="col-3">
                                                                {/* Item post */}
                                                                <div>
                                                                    <a href="#" className="wrap-pic-w hov1 trans-03">
                                                                        <img src="images/post-27.jpg" alt="IMG" />
                                                                    </a>
                                                                    <div className="p-t-10">
                                                                        <h5 className="p-b-5">
                                                                            <a
                                                                                href="#"
                                                                                className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                            >
                                                                                24 Donec metus orci, malesuada et lectus vitae
                                                                            </a>
                                                                        </h5>
                                                                        <span className="cl8">
                                                                            <a
                                                                                href="#"
                                                                                className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                            >
                                                                                Viral
                                                                            </a>
                                                                            <span className="f1-s-3 m-rl-3">-</span>
                                                                            <span className="f1-s-3">Sep 20</span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* ======= ItemD3 Actualidad ======= */}
                                                            <div className="col-3">
                                                                {/* Item post */}
                                                                <div>
                                                                    <a href="#" className="wrap-pic-w hov1 trans-03">
                                                                        <img src="images/post-26.jpg" alt="IMG" />
                                                                    </a>
                                                                    <div className="p-t-10">
                                                                        <h5 className="p-b-5">
                                                                            <a
                                                                                href="#"
                                                                                className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                            >
                                                                                25 Donec metus orci, malesuada et lectus vitae
                                                                            </a>
                                                                        </h5>
                                                                        <span className="cl8">
                                                                            <a
                                                                                href="#"
                                                                                className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                            >
                                                                                Viral
                                                                            </a>
                                                                            <span className="f1-s-3 m-rl-3">-</span>
                                                                            <span className="f1-s-3">Sep 12</span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* ======= ItemD4 Actualidad ======= */}
                                                            <div className="col-3">
                                                                {/* Item post */}
                                                                <div>
                                                                    <a href="#" className="wrap-pic-w hov1 trans-03">
                                                                        <img src="images/post-34.jpg" alt="IMG" />
                                                                    </a>
                                                                    <div className="p-t-10">
                                                                        <h5 className="p-b-5">
                                                                            <a
                                                                                href="#"
                                                                                className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                            >
                                                                                26 Donec metus orci, malesuada et lectus vitae
                                                                            </a>
                                                                        </h5>
                                                                        <span className="cl8">
                                                                            <a
                                                                                href="#"
                                                                                className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                            >
                                                                                Viral
                                                                            </a>
                                                                            <span className="f1-s-3 m-rl-3">-</span>
                                                                            <span className="f1-s-3">Sep 15</span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* ======= ocultado 24-09 ======= 

										<div class="tab-pane" id="news-4" role="tabpanel">
											<div class="row">

												 ======= ItemE1 Actualidad ======= 

												
												<div class="col-3">
													 Item post 

													<div>
														<a href="#" class="wrap-pic-w hov1 trans-03">
															<img src="images/post-35.jpg" alt="IMG">
														</a>

														<div class="p-t-10">
															<h5 class="p-b-5">
																<a href="#"
																	class="f1-s-5 cl3 hov-cl10 trans-03">
																	27 Donec metus orci, malesuada et lectus vitae
																</a>
															</h5>

															<span class="cl8">
																<a href="#" class="f1-s-6 cl8 hov-cl10 trans-03">
																	Viral
																</a>

																<span class="f1-s-3 m-rl-3">
																	-
																</span>

																<span class="f1-s-3">
																	Sep 18
																</span>
															</span>
														</div>
													</div>
												</div>

												 ======= ItemE2 Actualidad ======= 

												<div class="col-3">
													 Item post 

													<div>
														<a href="#" class="wrap-pic-w hov1 trans-03">
															<img src="images/post-36.jpg" alt="IMG">
														</a>

														<div class="p-t-10">
															<h5 class="p-b-5">
																<a href="#"
																	class="f1-s-5 cl3 hov-cl10 trans-03">
																	28 Donec metus orci, malesuada et lectus vitae
																</a>
															</h5>

															<span class="cl8">
																<a href="#" class="f1-s-6 cl8 hov-cl10 trans-03">
																	Viral
																</a>

																<span class="f1-s-3 m-rl-3">
																	-
																</span>

																<span class="f1-s-3">
																	Sep 12
																</span>
															</span>
														</div>
													</div>
												</div>

												 ======= ItemE3 Actualidad ======= -

												<div class="col-3">
													Item post 
													<div>
														<a href="#" class="wrap-pic-w hov1 trans-03">
															<img src="images/post-37.jpg" alt="IMG">
														</a>

														<div class="p-t-10">
															<h5 class="p-b-5">
																<a href="#"
																	class="f1-s-5 cl3 hov-cl10 trans-03">
																	29 Donec metus orci, malesuada et lectus vitae
																</a>
															</h5>

															<span class="cl8">
																<a href="#" class="f1-s-6 cl8 hov-cl10 trans-03">
																	Viral
																</a>

																<span class="f1-s-3 m-rl-3">
																	-
																</span>

																<span class="f1-s-3">
																	Sep 20
																</span>
															</span>
														</div>
													</div>
												</div>

												<!-- ======= ItemE4 Actualidad ======= 

												<div class="col-3">
													<!-- Item post 
													<div>
														<a href="#" class="wrap-pic-w hov1 trans-03">
															<img src="images/post-38.jpg" alt="IMG">
														</a>

														<div class="p-t-10">
															<h5 class="p-b-5">
																<a href="#"
																	class="f1-s-5 cl3 hov-cl10 trans-03">
																	30 Donec metus orci, malesuada et lectus vitae
																</a>
															</h5>

															<span class="cl8">
																<a href="#" class="f1-s-6 cl8 hov-cl10 trans-03">
																	Viral
																</a>

																<span class="f1-s-3 m-rl-3">
																	-
																</span>

																<span class="f1-s-3">
																	Sep 15
																</span>
															</span>
														</div>
													</div>
												</div>
											</div>
										</div>

										<div class="tab-pane" id="news-5" role="tabpanel">
											<div class="row">

												<!-- ======= ItemF1 Actualidad ======= --

												<div class="col-3">
													<!-- Item post --
													<div>
														<a href="#" class="wrap-pic-w hov1 trans-03">
															<img src="images/post-39.jpg" alt="IMG">
														</a>

														<div class="p-t-10">
															<h5 class="p-b-5">
																<a href="#"
																	class="f1-s-5 cl3 hov-cl10 trans-03">
																	31 Donec metus orci, malesuada et lectus vitae
																</a>
															</h5>

															<span class="cl8">
																<a href="#" class="f1-s-6 cl8 hov-cl10 trans-03">
																	Viral
																</a>

																<span class="f1-s-3 m-rl-3">
																	-
																</span>

																<span class="f1-s-3">
																	Sep 18
																</span>
															</span>
														</div>
													</div>
												</div>

												<!-- ======= ItemF2 Actualidad ======= --

												<div class="col-3">
													<!-- Item post --
													<div>
														<a href="#" class="wrap-pic-w hov1 trans-03">
															<img src="images/post-41.jpg" alt="IMG">
														</a>

														<div class="p-t-10">
															<h5 class="p-b-5">
																<a href="#"
																	class="f1-s-5 cl3 hov-cl10 trans-03">
																	32 Donec metus orci, malesuada et lectus vitae
																</a>
															</h5>

															<span class="cl8">
																<a href="#" class="f1-s-6 cl8 hov-cl10 trans-03">
																	Viral
																</a>

																<span class="f1-s-3 m-rl-3">
																	-
																</span>

																<span class="f1-s-3">
																	Sep 20
																</span>
															</span>
														</div>
													</div>
												</div>

												<!-- ======= ItemF3 Actualidad ======= --

												<div class="col-3">
													<!-- Item post --
													<div>
														<a href="#" class="wrap-pic-w hov1 trans-03">
															<img src="images/post-42.jpg" alt="IMG">
														</a>

														<div class="p-t-10">
															<h5 class="p-b-5">
																<a href="#"
																	class="f1-s-5 cl3 hov-cl10 trans-03">
																	33 Donec metus orci, malesuada et lectus vitae
																</a>
															</h5>

															<span class="cl8">
																<a href="#" class="f1-s-6 cl8 hov-cl10 trans-03">
																	Viral
																</a>

																<span class="f1-s-3 m-rl-3">
																	-
																</span>

																<span class="f1-s-3">
																	Sep 15
																</span>
															</span>
														</div>
													</div>
												</div>

												<!-- ======= ItemF4 Actualidad ======= --

												<div class="col-3">
													<!-- Item post --
													<div>
														<a href="#" class="wrap-pic-w hov1 trans-03">
															<img src="images/post-40.jpg" alt="IMG">
														</a>

														<div class="p-t-10">
															<h5 class="p-b-5">
																<a href="#"
																	class="f1-s-5 cl3 hov-cl10 trans-03">
																	34 Donec metus orci, malesuada et lectus vitae
																</a>
															</h5>

															<span class="cl8">
																<a href="#" class="f1-s-6 cl8 hov-cl10 trans-03">
																	Viral
																</a>

																<span class="f1-s-3 m-rl-3">
																	-
																</span>

																<span class="f1-s-3">
																	Sep 12
																</span>
															</span>
														</div>
													</div>
												</div>
											</div>
										</div>

                                  ======= Fin de ocultado 24-09 ======= */}
                                                </div>
                                            </div>
                                        </li>
                                        {/* ======= BLOQUE POLÍTICA (enter) ======= */}
                                        <li className="mega-menu-item">
                                            <a href="es/economy.html">Política</a>
                                            <div className="sub-mega-menu">
                                                <div className="nav flex-column nav-pills" role="tablist">
                                                    <a
                                                        className="nav-link active"
                                                        data-toggle="pill"
                                                        href="#enter-1"
                                                        role="tab"
                                                    >
                                                        Todo
                                                    </a>
                                                    <a
                                                        className="nav-link"
                                                        data-toggle="pill"
                                                        href="#enter-2"
                                                        role="tab"
                                                    >
                                                        Eventos
                                                    </a>
                                                    <a
                                                        className="nav-link"
                                                        data-toggle="pill"
                                                        href="#enter-3"
                                                        role="tab"
                                                    >
                                                        Defensa
                                                    </a>
                                                </div>
                                                <div className="tab-content">
                                                    <div
                                                        className="tab-pane show active"
                                                        id="enter-1"
                                                        role="tabpanel"
                                                    >
                                                        <div className="row">
                                                            {/* ======= ItemA1 Política ======= */}
                                                            <div className="col-3">
                                                                {/* Item post */}
                                                                <div>
                                                                    <a
                                                                        href="es/politics/20230923-Rusia-dispuesta-a-negociar-sobre-Ucrania.html"
                                                                        className="wrap-pic-w hov1 trans-03"
                                                                    >
                                                                        <img
                                                                            src="images/kremlin-dia-puente-cuadro.png"
                                                                            alt="IMG"
                                                                        />
                                                                    </a>
                                                                    <div className="p-t-10">
                                                                        <h5 className="p-b-5">
                                                                            <a
                                                                                href="es/politics/20230923-Rusia-dispuesta-a-negociar-sobre-Ucrania.html"
                                                                                className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                            >
                                                                                Rusia está dispuesta a negociar sobre Ucrania,
                                                                                anunció Lavrov
                                                                            </a>
                                                                        </h5>
                                                                        <span className="cl8">
                                                                            <a
                                                                                href="#"
                                                                                className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                            >
                                                                                Política
                                                                            </a>
                                                                            <span className="f1-s-3 m-rl-3">-</span>
                                                                            <span className="f1-s-3">Sep 23</span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* ======= ItemA2 - Política ======= */}
                                                            <div className="col-3">
                                                                {/* Item post */}
                                                                <div>
                                                                    <a
                                                                        href="es/politics/20230924-desdolarizacion-mundial-debido-a-los-BRICS.html"
                                                                        className="wrap-pic-w hov1 trans-03"
                                                                    >
                                                                        <img src="images/dolar-roto.png" alt="IMG" />
                                                                    </a>
                                                                    <div className="p-t-10">
                                                                        <h5 className="p-b-5">
                                                                            <a
                                                                                href="es/politics/20230924-desdolarizacion-mundial-debido-a-los-BRICS.html"
                                                                                className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                            >
                                                                                La desdolarización debido a los BRICS pondrá
                                                                                fin al dominio de los Estados Unidos
                                                                            </a>
                                                                        </h5>
                                                                        <span className="cl8">
                                                                            <a
                                                                                href="#"
                                                                                className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                            >
                                                                                Política
                                                                            </a>
                                                                            <span className="f1-s-3 m-rl-3">-</span>
                                                                            <span className="f1-s-3">Sep 24</span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* ======= ItemA3 - Política ======= */}
                                                            <div className="col-3">
                                                                {/* Item post */}
                                                                <div>
                                                                    <a
                                                                        href="es/politics/20230921-rusia-presidencia-BRICS.html"
                                                                        className="wrap-pic-w hov1 trans-03"
                                                                    >
                                                                        <img src="images/brics.png" alt="IMG" />
                                                                    </a>
                                                                    <div className="p-t-10">
                                                                        <h5 className="p-b-5">
                                                                            <a
                                                                                href="es/politics/20230921-rusia-presidencia-BRICS.html"
                                                                                className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                            >
                                                                                Los países BRICS respaldaron la presidencia de
                                                                                Rusia.
                                                                            </a>
                                                                        </h5>
                                                                        <span className="cl8">
                                                                            <a
                                                                                href="#"
                                                                                className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                            >
                                                                                Política
                                                                            </a>
                                                                            <span className="f1-s-3 m-rl-3">-</span>
                                                                            <span className="f1-s-3">Sep 21</span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* ======= ItemA4 - Política ======= */}
                                                            <div className="col-3">
                                                                {/* Item post */}
                                                                <div>
                                                                    <a
                                                                        href="es/politics/20230920-kremlin-informa-visita-de-Putin-a-China.html"
                                                                        className="wrap-pic-w hov1 trans-03"
                                                                    >
                                                                        <img src="images/kremlin.png" alt="IMG" />
                                                                    </a>
                                                                    <div className="p-t-10">
                                                                        <h5 className="p-b-5">
                                                                            <a
                                                                                href="es/politics/20230920-kremlin-informa-visita-de-Putin-a-China.html"
                                                                                className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                            >
                                                                                En el Kremlin afirmaron que se está preparando
                                                                                la visita de Putin a China.
                                                                            </a>
                                                                        </h5>
                                                                        <span className="cl8">
                                                                            <a
                                                                                href="#"
                                                                                className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                            >
                                                                                Política
                                                                            </a>
                                                                            <span className="f1-s-3 m-rl-3">-</span>
                                                                            <span className="f1-s-3">Sep 20</span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="tab-pane" id="enter-2" role="tabpanel">
                                                        <div className="row">
                                                            {/* ======= ItemB1 - Política ======= */}
                                                            <div className="col-3">
                                                                {/* Item post */}
                                                                <div>
                                                                    <a
                                                                        href="es/politics/20230920-Putin-Pashinyan-discuten-sobre-Karabaj.html"
                                                                        className="wrap-pic-w hov1 trans-03"
                                                                    >
                                                                        <img src="images/erevan.png" alt="IMG" />
                                                                    </a>
                                                                    <div className="p-t-10">
                                                                        <h5 className="p-b-5">
                                                                            <a
                                                                                href="es/politics/20230920-Putin-Pashinyan-discuten-sobre-Karabaj.html"
                                                                                className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                            >
                                                                                Putin y Pashinyan discutieron la situación en
                                                                                Karabaj
                                                                            </a>
                                                                        </h5>
                                                                        <span className="cl8">
                                                                            <a
                                                                                href="#"
                                                                                className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                            >
                                                                                Política
                                                                            </a>
                                                                            <span className="f1-s-3 m-rl-3">-</span>
                                                                            <span className="f1-s-3">Sep 20</span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* ======= ItemB2 - Política ======= */}
                                                            <div className="col-3">
                                                                {/* Item post */}
                                                                <div>
                                                                    <a
                                                                        href="es/defense/20230919-putin-vehiculos-blindados.html"
                                                                        className="wrap-pic-w hov1 trans-03"
                                                                    >
                                                                        <img
                                                                            src="images/tanque-blindado-ia.jpg"
                                                                            alt="IMG"
                                                                        />
                                                                    </a>
                                                                    <div className="p-t-10">
                                                                        <h5 className="p-b-5">
                                                                            <a
                                                                                href="es/defense/20230919-putin-vehiculos-blindados.html"
                                                                                className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                            >
                                                                                Putin declaró que la producción de vehículos
                                                                                blindados en Rusia se duplicó.
                                                                            </a>
                                                                        </h5>
                                                                        <span className="cl8">
                                                                            <a
                                                                                href="#"
                                                                                className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                            >
                                                                                Defensa
                                                                            </a>
                                                                            <span className="f1-s-3 m-rl-3">-</span>
                                                                            <span className="f1-s-3">Sep 19</span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* ======= ItemB3 - Política ======= */}
                                                            <div className="col-3">
                                                                {/* Item post */}
                                                                <div>
                                                                    <a href="#" className="wrap-pic-w hov1 trans-03">
                                                                        <img src="images/post-37.jpg" alt="IMG" />
                                                                    </a>
                                                                    <div className="p-t-10">
                                                                        <h5 className="p-b-5">
                                                                            <a
                                                                                href="#"
                                                                                className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                            >
                                                                                115 Donec metus orci, malesuada et lectus
                                                                                vitae
                                                                            </a>
                                                                        </h5>
                                                                        <span className="cl8">
                                                                            <a
                                                                                href="#"
                                                                                className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                            >
                                                                                Viral
                                                                            </a>
                                                                            <span className="f1-s-3 m-rl-3">-</span>
                                                                            <span className="f1-s-3">Sep 13</span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* ======= ItemB4 - Política ======= */}
                                                            <div className="col-3">
                                                                {/* Item post */}
                                                                <div>
                                                                    <a href="#" className="wrap-pic-w hov1 trans-03">
                                                                        <img src="images/post-38.jpg" alt="IMG" />
                                                                    </a>
                                                                    <div className="p-t-10">
                                                                        <h5 className="p-b-5">
                                                                            <a
                                                                                href="#"
                                                                                className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                            >
                                                                                116 Donec metus orci, malesuada et lectus
                                                                                vitae
                                                                            </a>
                                                                        </h5>
                                                                        <span className="cl8">
                                                                            <a
                                                                                href="#"
                                                                                className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                            >
                                                                                Viral
                                                                            </a>
                                                                            <span className="f1-s-3 m-rl-3">-</span>
                                                                            <span className="f1-s-3">Sep 15</span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="tab-pane" id="enter-3" role="tabpanel">
                                                        <div className="row">
                                                            {/* ======= ItemC1 Política ======= */}
                                                            <div className="col-3">
                                                                {/* Item post */}
                                                                <div>
                                                                    <a href="#" className="wrap-pic-w hov1 trans-03">
                                                                        <img src="images/post-39.jpg" alt="IMG" />
                                                                    </a>
                                                                    <div className="p-t-10">
                                                                        <h5 className="p-b-5">
                                                                            <a
                                                                                href="#"
                                                                                className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                            >
                                                                                117 Donec metus orci, malesuada et lectus
                                                                                vitae
                                                                            </a>
                                                                        </h5>
                                                                        <span className="cl8">
                                                                            <a
                                                                                href="#"
                                                                                className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                            >
                                                                                Viral
                                                                            </a>
                                                                            <span className="f1-s-3 m-rl-3">-</span>
                                                                            <span className="f1-s-3">Sep 12</span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* ======= ItemC2 Política ======= */}
                                                            <div className="col-3">
                                                                {/* Item post */}
                                                                <div>
                                                                    <a href="#" className="wrap-pic-w hov1 trans-03">
                                                                        <img src="images/post-41.jpg" alt="IMG" />
                                                                    </a>
                                                                    <div className="p-t-10">
                                                                        <h5 className="p-b-5">
                                                                            <a
                                                                                href="#"
                                                                                className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                            >
                                                                                118 Donec metus orci, malesuada et lectus
                                                                                vitae
                                                                            </a>
                                                                        </h5>
                                                                        <span className="cl8">
                                                                            <a
                                                                                href="#"
                                                                                className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                            >
                                                                                Viral
                                                                            </a>
                                                                            <span className="f1-s-3 m-rl-3">-</span>
                                                                            <span className="f1-s-3">Sep 15</span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* ======= ItemC3 Política ======= */}
                                                            <div className="col-3">
                                                                {/* Item post */}
                                                                <div>
                                                                    <a href="#" className="wrap-pic-w hov1 trans-03">
                                                                        <img src="images/post-42.jpg" alt="IMG" />
                                                                    </a>
                                                                    <div className="p-t-10">
                                                                        <h5 className="p-b-5">
                                                                            <a
                                                                                href="#"
                                                                                className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                            >
                                                                                119 Donec metus orci, malesuada et lectus
                                                                                vitae
                                                                            </a>
                                                                        </h5>
                                                                        <span className="cl8">
                                                                            <a
                                                                                href="#"
                                                                                className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                            >
                                                                                Viral
                                                                            </a>
                                                                            <span className="f1-s-3 m-rl-3">-</span>
                                                                            <span className="f1-s-3">Sep 15</span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* ======= ItemC4 Política ======= */}
                                                            <div className="col-3">
                                                                {/* Item post */}
                                                                <div>
                                                                    <a href="#" className="wrap-pic-w hov1 trans-03">
                                                                        <img src="images/post-40.jpg" alt="IMG" />
                                                                    </a>
                                                                    <div className="p-t-10">
                                                                        <h5 className="p-b-5">
                                                                            <a
                                                                                href="#"
                                                                                className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                            >
                                                                                120 Donec metus orci, malesuada et lectus
                                                                                vitae
                                                                            </a>
                                                                        </h5>
                                                                        <span className="cl8">
                                                                            <a
                                                                                href="#"
                                                                                className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                            >
                                                                                Viral
                                                                            </a>
                                                                            <span className="f1-s-3 m-rl-3">-</span>
                                                                            <span className="f1-s-3">Sep 12</span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        {/* ======= BLOQUE ECONOMÍA (business) ======= */}
                                        <li className="mega-menu-item">
                                            <a href="es/economy.html">Economía</a>
                                            <div className="sub-mega-menu">
                                                <div className="nav flex-column nav-pills" role="tablist">
                                                    <a
                                                        className="nav-link active"
                                                        data-toggle="pill"
                                                        href="#business-1"
                                                        role="tab"
                                                    >
                                                        Todo
                                                    </a>
                                                    <a
                                                        className="nav-link"
                                                        data-toggle="pill"
                                                        href="#business-2"
                                                        role="tab"
                                                    >
                                                        Finanzas
                                                    </a>
                                                </div>
                                                <div className="tab-content">
                                                    <div
                                                        className="tab-pane show active"
                                                        id="business-1"
                                                        role="tabpanel"
                                                    >
                                                        <div className="row">
                                                            {/* ======= Item Econ ======= */}
                                                            <div className="col-3">
                                                                {/* Item post */}
                                                                <div>
                                                                    <a
                                                                        href="es/economy/20230927-Binance-vende-sus-activos-en-Rusia.html"
                                                                        className="wrap-pic-w hov1 trans-03"
                                                                    >
                                                                        <img
                                                                            src="images/crypto-bitcoin-binance.png"
                                                                            alt="IMG"
                                                                        />
                                                                    </a>
                                                                    <div className="p-t-10">
                                                                        <h5 className="p-b-5">
                                                                            <a
                                                                                href="es/economy/20230927-Binance-vende-sus-activos-en-Rusia.html"
                                                                                className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                            >
                                                                                La criptobolsa BINANCE vende todos sus activos
                                                                                en Rusia
                                                                            </a>
                                                                        </h5>
                                                                        <span className="cl8">
                                                                            <a
                                                                                href="es/economy.html"
                                                                                className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                            >
                                                                                Economía
                                                                            </a>
                                                                            <span className="f1-s-3 m-rl-3">-</span>
                                                                            <span className="f1-s-3">Sep 27</span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* ======= ItemA1 Econ ======= */}
                                                            <div className="col-3">
                                                                {/* Item post */}
                                                                <div>
                                                                    <a
                                                                        href="es/economy/20230926-Se-espera-fortalecer-el-rublo-hasta-87.5-rublos-por-dolar.html"
                                                                        className="wrap-pic-w hov1 trans-03"
                                                                    >
                                                                        <img
                                                                            src="images/rublo-ruso-monedas.png"
                                                                            alt="IMG"
                                                                        />
                                                                    </a>
                                                                    <div className="p-t-10">
                                                                        <h5 className="p-b-5">
                                                                            <a
                                                                                href="es/economy/20230926-Se-espera-fortalecer-el-rublo-hasta-87.5-rublos-por-dolar.html"
                                                                                className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                            >
                                                                                El Ministerio de Economía espera fortalecer el
                                                                                rublo hasta 87.5 rublos por dólar
                                                                            </a>
                                                                        </h5>
                                                                        <span className="cl8">
                                                                            <a
                                                                                href="es/economy.html"
                                                                                className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                            >
                                                                                Economía
                                                                            </a>
                                                                            <span className="f1-s-3 m-rl-3">-</span>
                                                                            <span className="f1-s-3">Sep 26</span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* ======= ItemA1 Economía ======= */}
                                                            <div className="col-3">
                                                                {/* Item post */}
                                                                <div>
                                                                    <a
                                                                        href="es/economy/20230925-Rusia-exporta-4.9-millones-de-toneladas-de-trigo.html"
                                                                        className="wrap-pic-w hov1 trans-03"
                                                                    >
                                                                        <img
                                                                            src="images/trigo-agro-exportacion-maquinaria.png"
                                                                            alt="IMG"
                                                                        />
                                                                    </a>
                                                                    <div className="p-t-10">
                                                                        <h5 className="p-b-5">
                                                                            <a
                                                                                href="es/economy/20230925-Rusia-exporta-4.9-millones-de-toneladas-de-trigo.html"
                                                                                className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                            >
                                                                                Rusia ha exportado 4.9 millones de toneladas
                                                                                de trigo y puede llegar a 6 millones
                                                                            </a>
                                                                        </h5>
                                                                        <span className="cl8">
                                                                            <a
                                                                                href="es/economy.html"
                                                                                className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                            >
                                                                                Economía
                                                                            </a>
                                                                            <span className="f1-s-3 m-rl-3">-</span>
                                                                            <span className="f1-s-3">Sep 25</span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* ======= ItemA2 Economía ======= */}
                                                            <div className="col-3">
                                                                {/* Item post */}
                                                                <div>
                                                                    <a
                                                                        href="es/economy/20230924-gazprom-suministra-a-europa-41millones-metros-cubicos-gas.html"
                                                                        className="wrap-pic-w hov1 trans-03"
                                                                    >
                                                                        <img src="images/tubos-de-gas.png" alt="IMG" />
                                                                    </a>
                                                                    <div className="p-t-10">
                                                                        <h5 className="p-b-5">
                                                                            <a
                                                                                href="es/economy/20230924-gazprom-suministra-a-europa-41millones-metros-cubicos-gas.html"
                                                                                className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                            >
                                                                                "Gazprom" suministra a Europa 41,8 millones de
                                                                                metros cúbicos de gas
                                                                            </a>
                                                                        </h5>
                                                                        <span className="cl8">
                                                                            <a
                                                                                href="es/economy.html"
                                                                                className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                            >
                                                                                Economía
                                                                            </a>
                                                                            <span className="f1-s-3 m-rl-3">-</span>
                                                                            <span className="f1-s-3">Sep 24</span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="tab-pane" id="business-2" role="tabpanel">
                                                        <div className="row">
                                                            {/* ======= ItemA3 Economía ======= */}
                                                            <div className="col-3">
                                                                {/* Item post */}
                                                                <div>
                                                                    <a
                                                                        href="es/economy/20230923-resistencia-de-la economia-rusa.html"
                                                                        className="wrap-pic-w hov1 trans-03"
                                                                    >
                                                                        <img
                                                                            src="images/juego-economia-rusa.png"
                                                                            alt="IMG"
                                                                        />
                                                                    </a>
                                                                    <div className="p-t-10">
                                                                        <h5 className="p-b-5">
                                                                            <a
                                                                                href="es/economy/20230923-resistencia-de-la economia-rusa.html"
                                                                                className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                            >
                                                                                "Тal cosa no se esperaba": La resistencia de
                                                                                la economía rusa.
                                                                            </a>
                                                                        </h5>
                                                                        <span className="cl8">
                                                                            <a
                                                                                href="es/economy.html"
                                                                                className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                            >
                                                                                Economía
                                                                            </a>
                                                                            <span className="f1-s-3 m-rl-3">-</span>
                                                                            <span className="f1-s-3">Sep 23</span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* ======= ItemA4 Economía ======= */}
                                                            <div className="col-3">
                                                                {/* Item post */}
                                                                <div>
                                                                    <a
                                                                        href="es/economy/20230920-rusia-mayor-proveedor-oro-emiratos-arabes.html"
                                                                        className="wrap-pic-w hov1 trans-03"
                                                                    >
                                                                        <img src="images/barras-de-oro.png" alt="IMG" />
                                                                    </a>
                                                                    <div className="p-t-10">
                                                                        <h5 className="p-b-5">
                                                                            <a
                                                                                href="es/economy/20230920-rusia-mayor-proveedor-oro-emiratos-arabes.html"
                                                                                className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                            >
                                                                                Rusia se ha convertido en el mayor proveedor
                                                                                de oro en los Emiratos Árabes Unidos (EAU)
                                                                            </a>
                                                                        </h5>
                                                                        <span className="cl8">
                                                                            <a
                                                                                href="es/economy.html"
                                                                                className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                            >
                                                                                Economía
                                                                            </a>
                                                                            <span className="f1-s-3 m-rl-3">-</span>
                                                                            <span className="f1-s-3">Sep 20</span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* ======= ItemB1 Economía ======= */}
                                                            <div className="col-3">
                                                                {/* Item post */}
                                                                <div>
                                                                    <a
                                                                        href="es/economy/20230920-rusia-exportacion-gasolina.html"
                                                                        className="wrap-pic-w hov1 trans-03"
                                                                    >
                                                                        <img src="images/gasolina.png" alt="IMG" />
                                                                    </a>
                                                                    <div className="p-t-10">
                                                                        <h5 className="p-b-5">
                                                                            <a
                                                                                href="es/economy/20230920-rusia-exportacion-gasolina.html"
                                                                                className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                            >
                                                                                En Rusia se han anunciado medidas radicales
                                                                                para detener la exportación de gasolina del
                                                                                país.
                                                                            </a>
                                                                        </h5>
                                                                        <span className="cl8">
                                                                            <a
                                                                                href="es/economy.html"
                                                                                className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                            >
                                                                                Economía
                                                                            </a>
                                                                            <span className="f1-s-3 m-rl-3">-</span>
                                                                            <span className="f1-s-3">Sep 20</span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* ======= ItemB2 Economía ======= */}
                                                            <div className="col-3">
                                                                {/* Item post */}
                                                                <div>
                                                                    <a
                                                                        href="es/economy/20230920-rusia-control-de-divisas.html"
                                                                        className="wrap-pic-w hov1 trans-03"
                                                                    >
                                                                        <img src="images/rublo-ruso.png" alt="IMG" />
                                                                    </a>
                                                                    <div className="p-t-10">
                                                                        <h5 className="p-b-5">
                                                                            <a
                                                                                href="es/economy/20230920-rusia-control-de-divisas.html"
                                                                                className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                            >
                                                                                El Ministerio de Finanzas informó de medidas
                                                                                para el control de divisas.
                                                                            </a>
                                                                        </h5>
                                                                        <span className="cl8">
                                                                            <a
                                                                                href="es/economy.html"
                                                                                className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                            >
                                                                                Economía
                                                                            </a>
                                                                            <span className="f1-s-3 m-rl-3">-</span>
                                                                            <span className="f1-s-3">Sep 20</span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        {/* ======= BLOQUE TECNOLOGÍA (travel) ======= */}
                                        <li className="mega-menu-item">
                                            <a href="es/economy.html">Tecnología</a>
                                            <div className="sub-mega-menu">
                                                <div className="nav flex-column nav-pills" role="tablist">
                                                    <a
                                                        className="nav-link active"
                                                        data-toggle="pill"
                                                        href="#travel-1"
                                                        role="tab"
                                                    >
                                                        Todo
                                                    </a>
                                                    <a
                                                        className="nav-link"
                                                        data-toggle="pill"
                                                        href="#travel-2"
                                                        role="tab"
                                                    >
                                                        Ciencia
                                                    </a>
                                                </div>
                                                <div className="tab-content">
                                                    <div
                                                        className="tab-pane show active"
                                                        id="travel-1"
                                                        role="tabpanel"
                                                    >
                                                        <div className="row">
                                                            {/* ======= ItemA  TECNO ======= */}
                                                            <div className="col-3">
                                                                {/* Item post */}
                                                                <div>
                                                                    <a
                                                                        href="es/ai/20230926-Rusia-destina-5,2-mil-millones-de-rublos-inteligencia-artificial.html"
                                                                        className="wrap-pic-w hov1 trans-03"
                                                                    >
                                                                        <img src="images/ia-robot-tecno.png" alt="IMG" />
                                                                    </a>
                                                                    <div className="p-t-10">
                                                                        <h5 className="p-b-5">
                                                                            <a
                                                                                href="es/ai/20230926-Rusia-destina-5,2-mil-millones-de-rublos-inteligencia-artificial.html"
                                                                                className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                            >
                                                                                El Gobierno Ruso destinará 5,2 mil millones al
                                                                                desarrollo de la inteligencia artificial
                                                                            </a>
                                                                        </h5>
                                                                        <span className="cl8">
                                                                            <a
                                                                                href="#"
                                                                                className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                            >
                                                                                IA
                                                                            </a>
                                                                            <span className="f1-s-3 m-rl-3">-</span>
                                                                            <span className="f1-s-3">Sep 26</span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* ======= ItemA1 Tecnología ======= */}
                                                            <div className="col-3">
                                                                {/* Item 1 - Tecnología */}
                                                                <div>
                                                                    <a
                                                                        href="es/technology/20230920-Rusia-no-bloquea-WhatsApp-ni-YouTube.html"
                                                                        className="wrap-pic-w hov1 trans-03"
                                                                    >
                                                                        <img src="images/youtube.png" alt="IMG" />
                                                                    </a>
                                                                    <div className="p-t-10">
                                                                        <h5 className="p-b-5">
                                                                            <a
                                                                                href="es/technology/20230920-Rusia-no-bloquea-WhatsApp-ni-YouTube.html"
                                                                                className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                            >
                                                                                Rusia no tiene planes de bloquear WhatsApp ni
                                                                                YouTube.
                                                                            </a>
                                                                        </h5>
                                                                        <span className="cl8">
                                                                            <a
                                                                                href="#"
                                                                                className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                            >
                                                                                Tecnología
                                                                            </a>
                                                                            <span className="f1-s-3 m-rl-3">-</span>
                                                                            <span className="f1-s-3">Sep 20</span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* ======= ItemA2 Tecnología ======= */}
                                                            <div className="col-3">
                                                                {/* Item post */}
                                                                <div>
                                                                    <a
                                                                        href="es/technology/20230920-MISIS-generadores-cuanticos.html"
                                                                        className="wrap-pic-w hov1 trans-03"
                                                                    >
                                                                        <img src="images/MISIS.jpg" alt="IMG" />
                                                                    </a>
                                                                    <div className="p-t-10">
                                                                        <h5 className="p-b-5">
                                                                            <a
                                                                                href="es/technology/20230920-MISIS-generadores-cuanticos.html"
                                                                                className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                            >
                                                                                En MISIS, se ha mejorado la fiabilidad de los
                                                                                generadores cuánticos.
                                                                            </a>
                                                                        </h5>
                                                                        <span className="cl8">
                                                                            <a
                                                                                href="#"
                                                                                className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                            >
                                                                                Ciencia
                                                                            </a>
                                                                            <span className="f1-s-3 m-rl-3">-</span>
                                                                            <span className="f1-s-3">Sep 20</span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* ======= ItemA3 Tecnología ======= */}
                                                            <div className="col-3">
                                                                {/* Item post */}
                                                                <div>
                                                                    <a
                                                                        href="es/ai/20230908-putin-inteligencia-artificial.html"
                                                                        className="wrap-pic-w hov1 trans-03"
                                                                    >
                                                                        <img
                                                                            src="images/ia-mujer-pantalla.png"
                                                                            alt="IMG"
                                                                        />
                                                                    </a>
                                                                    <div className="p-t-10">
                                                                        <h5 className="p-b-5">
                                                                            <a
                                                                                href="es/ai/20230908-putin-inteligencia-artificial.html"
                                                                                className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                            >
                                                                                Putin instruyó la aplicación "Inteligencia
                                                                                Artificial" en la educación superior.
                                                                            </a>
                                                                        </h5>
                                                                        <span className="cl8">
                                                                            <a
                                                                                href="#"
                                                                                className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                            >
                                                                                IA
                                                                            </a>
                                                                            <span className="f1-s-3 m-rl-3">-</span>
                                                                            <span className="f1-s-3">Sep 18</span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* ======= Fila 2- Tecnología ======= */}
                                                    <div className="tab-pane" id="travel-2" role="tabpanel">
                                                        <div className="row">
                                                            {/* ======= ItemA4 Tecnología ======= */}
                                                            <div className="col-3">
                                                                {/* Item post */}
                                                                <div>
                                                                    <a
                                                                        href="es/technology/20230918-identificacion-digital-rusia.html"
                                                                        className="wrap-pic-w hov1 trans-03"
                                                                    >
                                                                        <img
                                                                            src="images/rusia-identi-digital.jpg"
                                                                            alt="IMG"
                                                                        />
                                                                    </a>
                                                                    <div className="p-t-10">
                                                                        <h5 className="p-b-5">
                                                                            <a
                                                                                href="es/technology/20230918-identificacion-digital-rusia.html"
                                                                                className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                            >
                                                                                Putin ha firmado un decreto sobre la
                                                                                identificación digital
                                                                            </a>
                                                                        </h5>
                                                                        <span className="cl8">
                                                                            <a
                                                                                href="#"
                                                                                className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                            >
                                                                                Digital
                                                                            </a>
                                                                            <span className="f1-s-3 m-rl-3">-</span>
                                                                            <span className="f1-s-3">Sep 18</span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* ======= ItemB1 Tecnología ======= */}
                                                            <div className="col-3">
                                                                {/* Item post */}
                                                                <div>
                                                                    <a
                                                                        href="es/technology/20230917-premio-ciencia01.html"
                                                                        className="wrap-pic-w hov1 trans-03"
                                                                    >
                                                                        <img
                                                                            src="images/ciencia-laboratorio.png"
                                                                            alt="IMG"
                                                                        />
                                                                    </a>
                                                                    <div className="p-t-10">
                                                                        <h5 className="p-b-5">
                                                                            <a
                                                                                href="es/technology/20230917-premio-ciencia01.html"
                                                                                className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                            >
                                                                                Los ganadores del premio "Por la lealtad a la
                                                                                ciencia" serán anunciados en octubre.
                                                                            </a>
                                                                        </h5>
                                                                        <span className="cl8">
                                                                            <a
                                                                                href="#"
                                                                                className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                            >
                                                                                Ciencia
                                                                            </a>
                                                                            <span className="f1-s-3 m-rl-3">-</span>
                                                                            <span className="f1-s-3">Sep 18</span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* ======= ItemB2 Tecnología ======= */}
                                                            <div className="col-3">
                                                                {/* Item post */}
                                                                <div>
                                                                    <a href="#" className="wrap-pic-w hov1 trans-03">
                                                                        <img src="images/post-36.jpg" alt="IMG" />
                                                                    </a>
                                                                    <div className="p-t-10">
                                                                        <h5 className="p-b-5">
                                                                            <a
                                                                                href="#"
                                                                                className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                            >
                                                                                Donec metus orci, malesuada et lectus vitae
                                                                            </a>
                                                                        </h5>
                                                                        <span className="cl8">
                                                                            <a
                                                                                href="#"
                                                                                className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                            >
                                                                                Eventos
                                                                            </a>
                                                                            <span className="f1-s-3 m-rl-3">-</span>
                                                                            <span className="f1-s-3">Sep 13</span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* ======= ItemB3 Tecnología ======= */}
                                                            <div className="col-3">
                                                                {/* Item post */}
                                                                <div>
                                                                    <a href="#" className="wrap-pic-w hov1 trans-03">
                                                                        <img src="images/post-37.jpg" alt="IMG" />
                                                                    </a>
                                                                    <div className="p-t-10">
                                                                        <h5 className="p-b-5">
                                                                            <a
                                                                                href="#"
                                                                                className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                            >
                                                                                Donec metus orci, malesuada et lectus vitae
                                                                            </a>
                                                                        </h5>
                                                                        <span className="cl8">
                                                                            <a
                                                                                href="#"
                                                                                className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                            >
                                                                                Eventos
                                                                            </a>
                                                                            <span className="f1-s-3 m-rl-3">-</span>
                                                                            <span className="f1-s-3">Sep 13</span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        {/* ======= BLOQUE SOCIEDAD (life) ======= */}
                                        <li className="mega-menu-item">
                                            <a href="es/economy.html">Sociedad</a>
                                            <div className="sub-mega-menu">
                                                <div className="nav flex-column nav-pills" role="tablist">
                                                    <a
                                                        className="nav-link active"
                                                        data-toggle="pill"
                                                        href="#life-1"
                                                        role="tab"
                                                    >
                                                        Todo
                                                    </a>
                                                </div>
                                                <div className="tab-content">
                                                    <div
                                                        className="tab-pane show active"
                                                        id="life-1"
                                                        role="tabpanel"
                                                    >
                                                        <div className="row">
                                                            {/* ======= ItemA1 Sociedad ======= */}
                                                            <div className="col-3">
                                                                {/* Item post */}
                                                                <div>
                                                                    <a
                                                                        href="es/culture/20230919-rusas-hermosas-miss-universe.html"
                                                                        className="wrap-pic-w hov1 trans-03"
                                                                    >
                                                                        <img
                                                                            src="images/miss-universe-russia.png"
                                                                            alt="IMG"
                                                                        />
                                                                    </a>
                                                                    <div className="p-t-10">
                                                                        <h5 className="p-b-5">
                                                                            <a
                                                                                href="es/culture/20230919-rusas-hermosas-miss-universe.html"
                                                                                className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                            >
                                                                                Las mujeres más hermosas de Rusia: La vida de
                                                                                las Miss Universo
                                                                            </a>
                                                                        </h5>
                                                                        <span className="cl8">
                                                                            <a
                                                                                href="#"
                                                                                className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                            >
                                                                                Cultura
                                                                            </a>
                                                                            <span className="f1-s-3 m-rl-3">-</span>
                                                                            <span className="f1-s-3">Sep 19</span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* ======= ItemA2 Sociedad ======= */}
                                                            <div className="col-3">
                                                                {/* Item post */}
                                                                <div>
                                                                    <a
                                                                        href="es/ai/20230921-moscu-camaras-ia-multan-pasajeros.html"
                                                                        className="wrap-pic-w hov1 trans-03"
                                                                    >
                                                                        <img
                                                                            src="images/camaras-multas-ia.png"
                                                                            alt="IMG"
                                                                        />
                                                                    </a>
                                                                    <div className="p-t-10">
                                                                        <h5 className="p-b-5">
                                                                            <a
                                                                                href="es/ai/20230921-moscu-camaras-ia-multan-pasajeros.html"
                                                                                className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                            >
                                                                                En Moscú, las cámaras comenzarán a multar a
                                                                                los pasajeros sin cintúron de seguridad.
                                                                            </a>
                                                                        </h5>
                                                                        <span className="cl8">
                                                                            <a
                                                                                href="#"
                                                                                className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                            >
                                                                                Sociedad
                                                                            </a>
                                                                            <span className="f1-s-3 m-rl-3">-</span>
                                                                            <span className="f1-s-3">Sep 21</span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* ======= ItemA3 Sociedad ======= */}
                                                            <div className="col-3">
                                                                {/* Item post */}
                                                                <div>
                                                                    <a
                                                                        href="es/education/20230921-colaboracion-entre-universidades-de-Rusia-Cuba.html"
                                                                        className="wrap-pic-w hov1 trans-03"
                                                                    >
                                                                        <img
                                                                            src="images/rusas-universidad-estudios.png"
                                                                            alt="IMG"
                                                                        />
                                                                    </a>
                                                                    <div className="p-t-10">
                                                                        <h5 className="p-b-5">
                                                                            <a
                                                                                href="es/education/20230921-colaboracion-entre-universidades-de-Rusia-Cuba.html"
                                                                                className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                            >
                                                                                Chernyshenko habló sobre la colaboración entre
                                                                                las universidades de Rusia y Cuba.
                                                                            </a>
                                                                        </h5>
                                                                        <span className="cl8">
                                                                            <a
                                                                                href="#"
                                                                                className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                            >
                                                                                Educación
                                                                            </a>
                                                                            <span className="f1-s-3 m-rl-3">-</span>
                                                                            <span className="f1-s-3">Sep 21</span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* ======= ItemA4 Sociedad ======= */}
                                                            <div className="col-3">
                                                                {/* Item post */}
                                                                <div>
                                                                    <a
                                                                        href="es/society/20230921-exitoso-inicio-de-carrera-de-jovenes.html"
                                                                        className="wrap-pic-w hov1 trans-03"
                                                                    >
                                                                        <img
                                                                            src="images/chicas-rusas-patio.png"
                                                                            alt="IMG"
                                                                        />
                                                                    </a>
                                                                    <div className="p-t-10">
                                                                        <h5 className="p-b-5">
                                                                            <a
                                                                                href="es/society/20230921-exitoso-inicio-de-carrera-de-jovenes.html"
                                                                                className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                            >
                                                                                Putin insta a crear condiciones para un
                                                                                exitoso inicio de carrera de la juventud.
                                                                            </a>
                                                                        </h5>
                                                                        <span className="cl8">
                                                                            <a
                                                                                href="#"
                                                                                className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                            >
                                                                                Sociedad
                                                                            </a>
                                                                            <span className="f1-s-3 m-rl-3">-</span>
                                                                            <span className="f1-s-3">Sep 21</span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        {/* ======= BLOQUE VIDEO (video) ======= */}
                                        <li className="mega-menu-item">
                                            <a href="es/video.html">Video</a>
                                            <div className="sub-mega-menu">
                                                <div className="nav flex-column nav-pills" role="tablist">
                                                    <a
                                                        className="nav-link active"
                                                        data-toggle="pill"
                                                        href="#video-1"
                                                        role="tab"
                                                    >
                                                        Todo
                                                    </a>
                                                </div>
                                                <div className="tab-content">
                                                    <div
                                                        className="tab-pane show active"
                                                        id="video-1"
                                                        role="tabpanel"
                                                    >
                                                        <div className="row">
                                                            {/* ======= ItemA1 Video ======= */}
                                                            <div className="col-3">
                                                                {/* Item post */}
                                                                <div>
                                                                    <a
                                                                        href="es/video/20230929-conferencia-Rusia-America-Latina.html"
                                                                        className="wrap-pic-w hov1 trans-03"
                                                                    >
                                                                        <img src="images/Putin-Latam.png" alt="IMG" />
                                                                    </a>
                                                                    <div className="p-t-10">
                                                                        <h5 className="p-b-5">
                                                                            <a
                                                                                href="es/video/20230929-conferencia-Rusia-America-Latina.html"
                                                                                className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                            >
                                                                                La primera conferencia parlamentaria
                                                                                "Rusia-América Latina" se celebra en Moscú
                                                                            </a>
                                                                        </h5>
                                                                        <span className="cl8">
                                                                            <a
                                                                                href="#"
                                                                                className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                            >
                                                                                Sociedad
                                                                            </a>
                                                                            <span className="f1-s-3 m-rl-3">-</span>
                                                                            <span className="f1-s-3">Sep 29</span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* ======= ItemA2 Video ======= */}
                                                            <div className="col-3">
                                                                {/* Item post */}
                                                                <div>
                                                                    <a href="#" className="wrap-pic-w hov1 trans-03">
                                                                        <img src="images/post-10.jpg" alt="IMG" />
                                                                    </a>
                                                                    <div className="p-t-10">
                                                                        <h5 className="p-b-5">
                                                                            <a
                                                                                href="#"
                                                                                className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                            >
                                                                                Donec metus orci, malesuada et lectus vitae
                                                                            </a>
                                                                        </h5>
                                                                        <span className="cl8">
                                                                            <a
                                                                                href="#"
                                                                                className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                            >
                                                                                Viral
                                                                            </a>
                                                                            <span className="f1-s-3 m-rl-3">-</span>
                                                                            <span className="f1-s-3">Sep 12</span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* ======= ItemA3 Video ======= */}
                                                            <div className="col-3">
                                                                {/* Item post */}
                                                                <div>
                                                                    <a href="#" className="wrap-pic-w hov1 trans-03">
                                                                        <img src="images/post-07.jpg" alt="IMG" />
                                                                    </a>
                                                                    <div className="p-t-10">
                                                                        <h5 className="p-b-5">
                                                                            <a
                                                                                href="#"
                                                                                className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                            >
                                                                                Donec metus orci, malesuada et lectus vitae
                                                                            </a>
                                                                        </h5>
                                                                        <span className="cl8">
                                                                            <a
                                                                                href="#"
                                                                                className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                            >
                                                                                Viral
                                                                            </a>
                                                                            <span className="f1-s-3 m-rl-3">-</span>
                                                                            <span className="f1-s-3">Sep 20</span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* ======= ItemA4 Video ======= */}
                                                            <div className="col-3">
                                                                {/* Item post */}
                                                                <div>
                                                                    <a href="#" className="wrap-pic-w hov1 trans-03">
                                                                        <img src="images/post-06.jpg" alt="IMG" />
                                                                    </a>
                                                                    <div className="p-t-10">
                                                                        <h5 className="p-b-5">
                                                                            <a
                                                                                href="#"
                                                                                className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                            >
                                                                                Donec metus orci, malesuada et lectus vitae
                                                                            </a>
                                                                        </h5>
                                                                        <span className="cl8">
                                                                            <a
                                                                                href="#"
                                                                                className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                            >
                                                                                Viral
                                                                            </a>
                                                                            <span className="f1-s-3 m-rl-3">-</span>
                                                                            <span className="f1-s-3">Sep 15</span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        {/* ======= BLOQUE DESTACADO ======= */}
                                        <li>
                                            <a href="#">Destacado</a>
                                            <ul className="sub-menu">
                                                {/*
									<li><a href="es/economy.html">Category Page v1</a></li>
									<li><a href="es/economy.html">Category Page v2</a></li>
									<li><a href="blog-grid.html">Blog Grid Sidebar</a></li>
									<li><a href="blog-list-01.html">Blog List Sidebar v1</a></li>
									<li><a href="blog-list-02.html">Blog List Sidebar v2</a></li>
									<li><a href="#">Blog Detail Sidebar</a></li>
									<li><a href="blog-detail-02.html">Blog Detail No Sidebar</a></li>
									*/}
                                                <li>
                                                    <a href="about.html">Acerca de</a>
                                                </li>
                                                <li>
                                                    <a href="contact.html">Contáctanos</a>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                        {/*===============================================================================================*/}
                    </div>
                </header>



                {/*===============================================================================================*/}
                {/*================================== FIN DEL ENCABEZADO ========================================*/}
                {/*===============================================================================================*/}
                {/* Headline */}
                <div className="container">
                    <div className="bg0 flex-wr-sb-c p-rl-20 p-tb-8">
                        <div className="f2-s-1 p-r-30 size-w-0 m-tb-6 flex-wr-s-c">
                            <span className="text-uppercase cl2 p-r-8">EN TENDENCIA:</span>
                            <span
                                className="dis-inline-block cl6 slide100-txt pos-relative size-w-0"
                                data-in="fadeInDown"
                                data-out="fadeOutDown"
                            >
                                <span className="dis-inline-block slide100-txt-item animated visible-false">
                                    La Federación Rusa se prepara para un gran cambio
                                </span>
                                <span className="dis-inline-block slide100-txt-item animated visible-false">
                                    En el Kremlin lo tienen decidido
                                </span>
                                <span className="dis-inline-block slide100-txt-item animated visible-false">
                                    Esta es lo más viral de esta semana
                                </span>
                            </span>
                        </div>
                        <div className="pos-relative size-a-2 bo-1-rad-22 of-hidden bocl11 m-tb-6">
                            <input
                                className="f1-s-1 cl6 plh9 s-full p-l-25 p-r-45"
                                type="text"
                                name="search"
                                placeholder="Buscar"
                            />
                            <button className="flex-c-c size-a-1 ab-t-r fs-20 cl2 hov-cl10 trans-03">
                                <i className="zmdi zmdi-search" />
                            </button>
                        </div>
                    </div>
                </div>
                {/*===============================================================================================*/}
                {/*================================= TITULARES - PORTADA ========================================*/}
                {/*===============================================================================================*/}
                {/* Feature post */}
                <section className="bg0">
                    <div className="container">
                        <div className="row m-rl--1">
                            {/*===================== TITULAR PRINCIPAL =====================*/}
                            <div className="col-md-6 p-rl-1 p-b-2">
                                <div
                                    className="bg-img1 size-a-3 how1 pos-relative"
                                    style={{ backgroundImage: "url(images/Putin-Latam.png)" }}
                                >
                                    <a
                                        href="es/society/20230929-primera-conferencia-Rusia-America-Latina.html"
                                        className="dis-block how1-child1 trans-03"
                                    />
                                    <div className="flex-col-e-s s-full p-rl-25 p-tb-20">
                                        <a
                                            href="#"
                                            className="dis-block how1-child2 f1-s-2 cl0 bo-all-1 bocl0 hov-btn1 trans-03 p-rl-5 p-t-2"
                                        >
                                            Sociedad
                                        </a>
                                        <h3 className="how1-child2 m-t-14 m-b-10">
                                            <a
                                                href="es/society/20230929-primera-conferencia-Rusia-America-Latina.html"
                                                className="how-txt1 size-a-6 f1-l-1 cl0 hov-cl10 trans-03"
                                            >
                                                La primera conferencia parlamentaria "Rusia-América Latina" se
                                                celebra en Moscú
                                            </a>
                                        </h3>
                                        <span className="how1-child2">
                                            <span className="f1-s-4 cl11">Luis Palermo</span>
                                            <span className="f1-s-3 cl11 m-rl-3">-</span>
                                            <span className="f1-s-3 cl11">Sep 29</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            {/*===================== SEGUNDO TITULAR =====================*/}
                            <div className="col-md-6 p-rl-1">
                                <div className="row m-rl--1">
                                    <div className="col-12 p-rl-1 p-b-2">
                                        <div
                                            className="bg-img1 size-a-4 how1 pos-relative"
                                            style={{
                                                backgroundImage: "url(images/crypto-bitcoin-binance.png)"
                                            }}
                                        >
                                            <a
                                                href="es/economy/20230927-Binance-vende-sus-activos-en-Rusia.html"
                                                className="dis-block how1-child1 trans-03"
                                            />
                                            <div className="flex-col-e-s s-full p-rl-25 p-tb-24">
                                                <a
                                                    href="es/economy.html"
                                                    className="dis-block how1-child2 f1-s-2 cl0 bo-all-1 bocl0 hov-btn1 trans-03 p-rl-5 p-t-2"
                                                >
                                                    Economía
                                                </a>
                                                <h3 className="how1-child2 m-t-14">
                                                    <a
                                                        href="es/economy/20230927-Binance-vende-sus-activos-en-Rusia.html"
                                                        className="how-txt1 size-a-7 f1-l-2 cl0 hov-cl10 trans-03"
                                                    >
                                                        La criptobolsa BINANCE vende todos sus activos en Rusia
                                                    </a>
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                    {/*===================== TERCER TITULAR =====================*/}
                                    <div className="col-sm-6 p-rl-1 p-b-2">
                                        <div
                                            className="bg-img1 size-a-5 how1 pos-relative"
                                            style={{
                                                backgroundImage: "url(images/rublo-ruso-monedas.png)"
                                            }}
                                        >
                                            <a
                                                href="es/economy/20230926-Se-espera-fortalecer-el-rublo-hasta-87.5-rublos-por-dolar.html"
                                                className="dis-block how1-child1 trans-03"
                                            />
                                            <div className="flex-col-e-s s-full p-rl-25 p-tb-20">
                                                <a
                                                    href="#"
                                                    className="dis-block how1-child2 f1-s-2 cl0 bo-all-1 bocl0 hov-btn1 trans-03 p-rl-5 p-t-2"
                                                >
                                                    Economía
                                                </a>
                                                <h3 className="how1-child2 m-t-14">
                                                    <a
                                                        href="es/economy/20230926-Se-espera-fortalecer-el-rublo-hasta-87.5-rublos-por-dolar.html"
                                                        className="how-txt1 size-h-1 f1-m-1 cl0 hov-cl10 trans-03"
                                                    >
                                                        El Ministerio de Economía espera fortalecer el rublo hasta
                                                        87.5 rublos por dólar
                                                    </a>
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                    {/*===================== CUARTO TITULAR =====================*/}
                                    <div className="col-sm-6 p-rl-1 p-b-2">
                                        <div
                                            className="bg-img1 size-a-5 how1 pos-relative"
                                            style={{ backgroundImage: "url(images/ia-robot-tecno.png)" }}
                                        >
                                            <a
                                                href="es/ai/20230926-Rusia-destina-5,2-mil-millones-de-rublos-inteligencia-artificial.html"
                                                className="dis-block how1-child1 trans-03"
                                            />
                                            <div className="flex-col-e-s s-full p-rl-25 p-tb-20">
                                                <a
                                                    href="#"
                                                    className="dis-block how1-child2 f1-s-2 cl0 bo-all-1 bocl0 hov-btn1 trans-03 p-rl-5 p-t-2"
                                                >
                                                    IA
                                                </a>
                                                <h3 className="how1-child2 m-t-14">
                                                    <a
                                                        href="es/ai/20230926-Rusia-destina-5,2-mil-millones-de-rublos-inteligencia-artificial.html"
                                                        className="how-txt1 size-h-1 f1-m-1 cl0 hov-cl10 trans-03"
                                                    >
                                                        El Gobierno Ruso destinará 5,2 mil millones al desarrollo
                                                        de la inteligencia artificial
                                                    </a>
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*===============================================================================================*/}
                {/*================================== PRIMERA SECCIÓN ========================================*/}
                {/*===============================================================================================*/}
                {/* Post */}
                <section className="bg0 p-t-70">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-10 col-lg-8">
                                <div className="p-b-20">
                                    {/*===============================================================================================*/}
                                    {/*================================== BLOQUE ACTUALIDAD ========================================*/}
                                    {/*===============================================================================================*/}
                                    {/* Actualidad */}
                                    <div className="tab01 p-b-20">
                                        <div className="tab01-head how2 how2-cl1 bocl12 flex-s-c m-r-10 m-r-0-sr991">
                                            {/* Brand tab */}
                                            <h3 className="f1-m-2 cl12 tab01-title">Actualidad</h3>
                                            {/* Nav tabs */}
                                            <ul className="nav nav-tabs" role="tablist">
                                                <li className="nav-item">
                                                    <a
                                                        className="nav-link active"
                                                        data-toggle="tab"
                                                        href="#tab1-1"
                                                        role="tab"
                                                    >
                                                        Todo
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a
                                                        className="nav-link"
                                                        data-toggle="tab"
                                                        href="#tab1-2"
                                                        role="tab"
                                                    >
                                                        Últimas
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a
                                                        className="nav-link"
                                                        data-toggle="tab"
                                                        href="#tab1-3"
                                                        role="tab"
                                                    >
                                                        Política
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a
                                                        className="nav-link"
                                                        data-toggle="tab"
                                                        href="#tab1-4"
                                                        role="tab"
                                                    >
                                                        Tendencia
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a
                                                        className="nav-link"
                                                        data-toggle="tab"
                                                        href="#tab1-5"
                                                        role="tab"
                                                    >
                                                        Virales
                                                    </a>
                                                </li>
                                                <li className="nav-item-more dropdown dis-none">
                                                    <a
                                                        className="nav-link dropdown-toggle"
                                                        data-toggle="dropdown"
                                                        href="#"
                                                    >
                                                        <i className="fa fa-ellipsis-h" />
                                                    </a>
                                                    <ul className="dropdown-menu"></ul>
                                                </li>
                                            </ul>
                                            {/*  */}
                                            <a
                                                href="es/economy.html"
                                                className="tab01-link f1-s-1 cl9 hov-cl10 trans-03"
                                            >
                                                Ver todo
                                                <i className="fs-12 m-l-5 fa fa-caret-right" />
                                            </a>
                                        </div>
                                        {/* ============= Paneles de pestañas ============= */}
                                        {/* Tab panes */}
                                        <div className="tab-content p-t-35">
                                            {/* - */}
                                            <div
                                                className="tab-pane fade show active"
                                                id="tab1-1"
                                                role="tabpanel"
                                            >
                                                <div className="row">
                                                    {/* ============= ITEM1A Actualidad ============= */}
                                                    <div className="col-sm-6 p-r-25 p-r-15-sr991">
                                                        {/* Item post */}
                                                        <div className="m-b-30">
                                                            <a
                                                                href="es/society/20230929-primera-conferencia-Rusia-America-Latina.html"
                                                                className="wrap-pic-w hov1 trans-03"
                                                            >
                                                                <img src="images/Putin-Latam.png" alt="IMG" />
                                                            </a>
                                                            <div className="p-t-20">
                                                                <h5 className="p-b-5">
                                                                    <a
                                                                        href="es/society/20230929-primera-conferencia-Rusia-America-Latina.html"
                                                                        className="f1-m-3 cl2 hov-cl10 trans-03"
                                                                    >
                                                                        La primera conferencia parlamentaria
                                                                        "Rusia-América Latina" se celebra en Moscú
                                                                    </a>
                                                                </h5>
                                                                <span className="cl8">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-4 cl8 hov-cl10 trans-03"
                                                                    >
                                                                        Sociedad
                                                                    </a>
                                                                    <span className="f1-s-3 m-rl-3">-</span>
                                                                    <span className="f1-s-3">Sep 29</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* ============= ITEM1B Actualidad ============= */}
                                                    <div className="col-sm-6 p-r-25 p-r-15-sr991">
                                                        {/* Item post */}
                                                        <div className="flex-wr-sb-s m-b-30">
                                                            <a
                                                                href="es/politics/20230920-kremlin-informa-visita-de-Putin-a-China.html"
                                                                className="size-w-1 wrap-pic-w hov1 trans-03"
                                                            >
                                                                <img src="images/kremlin.png" alt="IMG" />
                                                            </a>
                                                            <div className="size-w-2">
                                                                <h5 className="p-b-5">
                                                                    <a
                                                                        href="es/politics/20230920-kremlin-informa-visita-de-Putin-a-China.html"
                                                                        className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                    >
                                                                        En el Kremlin afirmaron que se está preparando la
                                                                        visita de Putin a China.
                                                                    </a>
                                                                </h5>
                                                                <span className="cl8">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                    >
                                                                        Política
                                                                    </a>
                                                                    <span className="f1-s-3 m-rl-3">-</span>
                                                                    <span className="f1-s-3">Sep 20</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        {/* ============= ITEM1C Actualidad ============= */}
                                                        {/* Item post */}
                                                        <div className="flex-wr-sb-s m-b-30">
                                                            <a
                                                                href="es/economy/20230927-Binance-vende-sus-activos-en-Rusia.html"
                                                                className="size-w-1 wrap-pic-w hov1 trans-03"
                                                            >
                                                                <img
                                                                    src="images/crypto-bitcoin-binance.png"
                                                                    alt="IMG"
                                                                />
                                                            </a>
                                                            <div className="size-w-2">
                                                                <h5 className="p-b-5">
                                                                    <a
                                                                        href="es/economy/20230927-Binance-vende-sus-activos-en-Rusia.html"
                                                                        className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                    >
                                                                        La criptobolsa BINANCE vende todos sus activos en
                                                                        Rusia
                                                                    </a>
                                                                </h5>
                                                                <span className="cl8">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                    >
                                                                        Economía
                                                                    </a>
                                                                    <span className="f1-s-3 m-rl-3">-</span>
                                                                    <span className="f1-s-3">Sep 27</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        {/* ============= ITEM1D Actualidad ============= */}
                                                        {/* Item post */}
                                                        <div className="flex-wr-sb-s m-b-30">
                                                            <a
                                                                href="es/society/20230925-profesiones-prometedoras-desarrollo-en-Moscu.html"
                                                                className="size-w-1 wrap-pic-w hov1 trans-03"
                                                            >
                                                                <img
                                                                    src="images/chicas-computadora-tecnologia.png"
                                                                    alt="IMG"
                                                                />
                                                            </a>
                                                            <div className="size-w-2">
                                                                <h5 className="p-b-5">
                                                                    <a
                                                                        href="es/society/20230925-profesiones-prometedoras-desarrollo-en-Moscu.html"
                                                                        className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                    >
                                                                        Las profesiones prometedoras en el desarrollo se
                                                                        discutirán en Moscú
                                                                    </a>
                                                                </h5>
                                                                <span className="cl8">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                    >
                                                                        Sociedad
                                                                    </a>
                                                                    <span className="f1-s-3 m-rl-3">-</span>
                                                                    <span className="f1-s-3">Sep 25</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>{" "}
                                                {/* ============= TErmima Fila1 Actualidad ============= */}
                                            </div>
                                            {/* ============= FILA 02 ============= */}
                                            {/* - */}
                                            <div className="tab-pane fade" id="tab1-2" role="tabpanel">
                                                <div className="row">
                                                    <div className="col-sm-6 p-r-25 p-r-15-sr991">
                                                        {/* ============= ITEM2A Actualidad ============= */}
                                                        {/* Item post */}
                                                        <div className="m-b-30">
                                                            <a
                                                                href="es/ai/20230926-Rusia-destina-5,2-mil-millones-de-rublos-inteligencia-artificial.html"
                                                                className="wrap-pic-w hov1 trans-03"
                                                            >
                                                                <img src="images/ia-robot-tecno.png" alt="IMG" />
                                                            </a>
                                                            <div className="p-t-20">
                                                                <h5 className="p-b-5">
                                                                    <a
                                                                        href="es/ai/20230926-Rusia-destina-5,2-mil-millones-de-rublos-inteligencia-artificial.html"
                                                                        className="f1-m-3 cl2 hov-cl10 trans-03"
                                                                    >
                                                                        El Gobierno Ruso destinará 5,2 mil millones al
                                                                        desarrollo de la inteligencia artificial
                                                                    </a>
                                                                </h5>
                                                                <span className="cl8">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-4 cl8 hov-cl10 trans-03"
                                                                    >
                                                                        IA
                                                                    </a>
                                                                    <span className="f1-s-3 m-rl-3">-</span>
                                                                    <span className="f1-s-3">Sep 26</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 p-r-25 p-r-15-sr991">
                                                        {/* ============= ITEM2B Actualidad ============= */}
                                                        {/* Item post */}
                                                        <div className="flex-wr-sb-s m-b-30">
                                                            <a
                                                                href="#"
                                                                className="size-w-1 wrap-pic-w hov1 trans-03"
                                                            >
                                                                <img src="images/post-10.jpg" alt="IMG" />
                                                            </a>
                                                            <div className="size-w-2">
                                                                <h5 className="p-b-5">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                    >
                                                                        Donec metus orci, malesuada et lectus vitae
                                                                    </a>
                                                                </h5>
                                                                <span className="cl8">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                    >
                                                                        Celebridades
                                                                    </a>
                                                                    <span className="f1-s-3 m-rl-3">-</span>
                                                                    <span className="f1-s-3">Sep 12</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        {/* ============= ITEM2C Actualidad ============= */}
                                                        {/* Item post */}
                                                        <div className="flex-wr-sb-s m-b-30">
                                                            <a
                                                                href="#"
                                                                className="size-w-1 wrap-pic-w hov1 trans-03"
                                                            >
                                                                <img src="images/post-06.jpg" alt="IMG" />
                                                            </a>
                                                            <div className="size-w-2">
                                                                <h5 className="p-b-5">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                    >
                                                                        Donec metus orci, malesuada et lectus vitae
                                                                    </a>
                                                                </h5>
                                                                <span className="cl8">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                    >
                                                                        Actualidad
                                                                    </a>
                                                                    <span className="f1-s-3 m-rl-3">-</span>
                                                                    <span className="f1-s-3">Sep 17</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        {/* ============= ITEM2D Actualidad ============= */}
                                                        {/* Item post */}
                                                        <div className="flex-wr-sb-s m-b-30">
                                                            <a
                                                                href="#"
                                                                className="size-w-1 wrap-pic-w hov1 trans-03"
                                                            >
                                                                <img src="images/post-07.jpg" alt="IMG" />
                                                            </a>
                                                            <div className="size-w-2">
                                                                <h5 className="p-b-5">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                    >
                                                                        Donec metus orci, malesuada et lectus vitae
                                                                    </a>
                                                                </h5>
                                                                <span className="cl8">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                    >
                                                                        Latino
                                                                    </a>
                                                                    <span className="f1-s-3 m-rl-3">-</span>
                                                                    <span className="f1-s-3">Sep 16</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* - */}
                                            <div className="tab-pane fade" id="tab1-3" role="tabpanel">
                                                <div className="row">
                                                    <div className="col-sm-6 p-r-25 p-r-15-sr991">
                                                        {/* ============= ITEM3A Actualidad ============= */}
                                                        {/* Item post */}
                                                        <div className="m-b-30">
                                                            <a href="#" className="wrap-pic-w hov1 trans-03">
                                                                <img src="images/post-10.jpg" alt="IMG" />
                                                            </a>
                                                            <div className="p-t-20">
                                                                <h5 className="p-b-5">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-m-3 cl2 hov-cl10 trans-03"
                                                                    >
                                                                        American live Viral lorem ipsum dolor sit amet
                                                                        consectetur
                                                                    </a>
                                                                </h5>
                                                                <span className="cl8">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-4 cl8 hov-cl10 trans-03"
                                                                    >
                                                                        Actualidad
                                                                    </a>
                                                                    <span className="f1-s-3 m-rl-3">-</span>
                                                                    <span className="f1-s-3">Sep 18</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 p-r-25 p-r-15-sr991">
                                                        {/* ============= ITEM3B Actualidad ============= */}
                                                        {/* Item post */}
                                                        <div className="flex-wr-sb-s m-b-30">
                                                            <a
                                                                href="#"
                                                                className="size-w-1 wrap-pic-w hov1 trans-03"
                                                            >
                                                                <img src="images/post-07.jpg" alt="IMG" />
                                                            </a>
                                                            <div className="size-w-2">
                                                                <h5 className="p-b-5">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                    >
                                                                        Donec metus orci, malesuada et lectus vitae
                                                                    </a>
                                                                </h5>
                                                                <span className="cl8">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                    >
                                                                        Celebridades
                                                                    </a>
                                                                    <span className="f1-s-3 m-rl-3">-</span>
                                                                    <span className="f1-s-3">Sep 12</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        {/* ============= ITEM3C Actualidad ============= */}
                                                        {/* Item post */}
                                                        <div className="flex-wr-sb-s m-b-30">
                                                            <a
                                                                href="#"
                                                                className="size-w-1 wrap-pic-w hov1 trans-03"
                                                            >
                                                                <img src="images/post-06.jpg" alt="IMG" />
                                                            </a>
                                                            <div className="size-w-2">
                                                                <h5 className="p-b-5">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                    >
                                                                        Donec metus orci, malesuada et lectus vitae
                                                                    </a>
                                                                </h5>
                                                                <span className="cl8">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                    >
                                                                        Actualidad
                                                                    </a>
                                                                    <span className="f1-s-3 m-rl-3">-</span>
                                                                    <span className="f1-s-3">Sep 17</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        {/* ============= ITEM3D Actualidad ============= */}
                                                        {/* Item post */}
                                                        <div className="flex-wr-sb-s m-b-30">
                                                            <a
                                                                href="#"
                                                                className="size-w-1 wrap-pic-w hov1 trans-03"
                                                            >
                                                                <img src="images/post-05.jpg" alt="IMG" />
                                                            </a>
                                                            <div className="size-w-2">
                                                                <h5 className="p-b-5">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                    >
                                                                        Donec metus orci, malesuada et lectus vitae
                                                                    </a>
                                                                </h5>
                                                                <span className="cl8">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                    >
                                                                        Game
                                                                    </a>
                                                                    <span className="f1-s-3 m-rl-3">-</span>
                                                                    <span className="f1-s-3">Sep 16</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* - */}
                                            <div className="tab-pane fade" id="tab1-4" role="tabpanel">
                                                <div className="row">
                                                    <div className="col-sm-6 p-r-25 p-r-15-sr991">
                                                        {/* ============= ITEM4A Actualidad ============= */}
                                                        {/* Item post */}
                                                        <div className="m-b-30">
                                                            <a href="#" className="wrap-pic-w hov1 trans-03">
                                                                <img src="images/post-06.jpg" alt="IMG" />
                                                            </a>
                                                            <div className="p-t-20">
                                                                <h5 className="p-b-5">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-m-3 cl2 hov-cl10 trans-03"
                                                                    >
                                                                        American live Viral lorem ipsum dolor sit amet
                                                                        consectetur
                                                                    </a>
                                                                </h5>
                                                                <span className="cl8">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-4 cl8 hov-cl10 trans-03"
                                                                    >
                                                                        Viral
                                                                    </a>
                                                                    <span className="f1-s-3 m-rl-3">-</span>
                                                                    <span className="f1-s-3">Sep 18</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 p-r-25 p-r-15-sr991">
                                                        {/* ============= ITEM4B Actualidad ============= */}
                                                        {/* Item post */}
                                                        <div className="flex-wr-sb-s m-b-30">
                                                            <a
                                                                href="#"
                                                                className="size-w-1 wrap-pic-w hov1 trans-03"
                                                            >
                                                                <img src="images/post-35.jpg" alt="IMG" />
                                                            </a>
                                                            <div className="size-w-2">
                                                                <h5 className="p-b-5">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                    >
                                                                        Donec metus orci, malesuada et lectus vitae
                                                                    </a>
                                                                </h5>
                                                                <span className="cl8">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                    >
                                                                        Celebridades
                                                                    </a>
                                                                    <span className="f1-s-3 m-rl-3">-</span>
                                                                    <span className="f1-s-3">Sep 12</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        {/* ============= ITEM4C Actualidad ============= */}
                                                        {/* Item post */}
                                                        <div className="flex-wr-sb-s m-b-30">
                                                            <a
                                                                href="#"
                                                                className="size-w-1 wrap-pic-w hov1 trans-03"
                                                            >
                                                                <img src="images/post-07.jpg" alt="IMG" />
                                                            </a>
                                                            <div className="size-w-2">
                                                                <h5 className="p-b-5">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                    >
                                                                        Donec metus orci, malesuada et lectus vitae
                                                                    </a>
                                                                </h5>
                                                                <span className="cl8">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                    >
                                                                        Viral
                                                                    </a>
                                                                    <span className="f1-s-3 m-rl-3">-</span>
                                                                    <span className="f1-s-3">Sep 17</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        {/* ============= ITEM4D Actualidad ============= */}
                                                        {/* Item post */}
                                                        <div className="flex-wr-sb-s m-b-30">
                                                            <a
                                                                href="#"
                                                                className="size-w-1 wrap-pic-w hov1 trans-03"
                                                            >
                                                                <img src="images/post-10.jpg" alt="IMG" />
                                                            </a>
                                                            <div className="size-w-2">
                                                                <h5 className="p-b-5">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                    >
                                                                        Donec metus orci, malesuada et lectus vitae
                                                                    </a>
                                                                </h5>
                                                                <span className="cl8">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                    >
                                                                        Game
                                                                    </a>
                                                                    <span className="f1-s-3 m-rl-3">-</span>
                                                                    <span className="f1-s-3">Sep 16</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* - */}
                                            <div className="tab-pane fade" id="tab1-5" role="tabpanel">
                                                <div className="row">
                                                    <div className="col-sm-6 p-r-25 p-r-15-sr991">
                                                        {/* ============= ITEM5A Actualidad ============= */}
                                                        {/* Item post */}
                                                        <div className="m-b-30">
                                                            <a href="#" className="wrap-pic-w hov1 trans-03">
                                                                <img src="images/post-07.jpg" alt="IMG" />
                                                            </a>
                                                            <div className="p-t-20">
                                                                <h5 className="p-b-5">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-m-3 cl2 hov-cl10 trans-03"
                                                                    >
                                                                        American live Viral lorem ipsum dolor sit amet
                                                                        consectetur
                                                                    </a>
                                                                </h5>
                                                                <span className="cl8">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-4 cl8 hov-cl10 trans-03"
                                                                    >
                                                                        Viral
                                                                    </a>
                                                                    <span className="f1-s-3 m-rl-3">-</span>
                                                                    <span className="f1-s-3">Sep 18</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 p-r-25 p-r-15-sr991">
                                                        {/* ============= ITEM5B Actualidad ============= */}
                                                        {/* Item post */}
                                                        <div className="flex-wr-sb-s m-b-30">
                                                            <a
                                                                href="#"
                                                                className="size-w-1 wrap-pic-w hov1 trans-03"
                                                            >
                                                                <img src="images/post-10.jpg" alt="IMG" />
                                                            </a>
                                                            <div className="size-w-2">
                                                                <h5 className="p-b-5">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                    >
                                                                        Donec metus orci, malesuada et lectus vitae
                                                                    </a>
                                                                </h5>
                                                                <span className="cl8">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                    >
                                                                        Celebridades
                                                                    </a>
                                                                    <span className="f1-s-3 m-rl-3">-</span>
                                                                    <span className="f1-s-3">Sep 12</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        {/* ============= ITEM5C Actualidad ============= */}
                                                        {/* Item post */}
                                                        <div className="flex-wr-sb-s m-b-30">
                                                            <a
                                                                href="#"
                                                                className="size-w-1 wrap-pic-w hov1 trans-03"
                                                            >
                                                                <img src="images/post-06.jpg" alt="IMG" />
                                                            </a>
                                                            <div className="size-w-2">
                                                                <h5 className="p-b-5">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                    >
                                                                        Donec metus orci, malesuada et lectus vitae
                                                                    </a>
                                                                </h5>
                                                                <span className="cl8">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                    >
                                                                        Viral
                                                                    </a>
                                                                    <span className="f1-s-3 m-rl-3">-</span>
                                                                    <span className="f1-s-3">Sep 17</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        {/* ============= ITEM5D Actualidad ============= */}
                                                        {/* Item post */}
                                                        <div className="flex-wr-sb-s m-b-30">
                                                            <a
                                                                href="#"
                                                                className="size-w-1 wrap-pic-w hov1 trans-03"
                                                            >
                                                                <img src="images/post-35.jpg" alt="IMG" />
                                                            </a>
                                                            <div className="size-w-2">
                                                                <h5 className="p-b-5">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                    >
                                                                        Donec metus orci, malesuada et lectus vitae
                                                                    </a>
                                                                </h5>
                                                                <span className="cl8">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                    >
                                                                        Game
                                                                    </a>
                                                                    <span className="f1-s-3 m-rl-3">-</span>
                                                                    <span className="f1-s-3">Sep 16</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/*===============================================================================================*/}
                                    {/*================================== BLOQUE ECONOMÍA ========================================*/}
                                    {/*===============================================================================================*/}
                                    {/* Business */}
                                    <div className="tab01 p-b-20">
                                        <div className="tab01-head how2 how2-cl2 bocl12 flex-s-c m-r-10 m-r-0-sr991">
                                            {/* Brand tab */}
                                            <h3 className="f1-m-2 cl13 tab01-title">Economía</h3>
                                            {/* Nav tabs */}
                                            <ul className="nav nav-tabs" role="tablist">
                                                <li className="nav-item">
                                                    <a
                                                        className="nav-link active"
                                                        data-toggle="tab"
                                                        href="#tab2-1"
                                                        role="tab"
                                                    >
                                                        Todo
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a
                                                        className="nav-link"
                                                        data-toggle="tab"
                                                        href="#tab2-2"
                                                        role="tab"
                                                    >
                                                        Economía
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a
                                                        className="nav-link"
                                                        data-toggle="tab"
                                                        href="#tab2-3"
                                                        role="tab"
                                                    >
                                                        Finanzas
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a
                                                        className="nav-link"
                                                        data-toggle="tab"
                                                        href="#tab2-4"
                                                        role="tab"
                                                    >
                                                        Empresas
                                                    </a>
                                                </li>
                                                <li className="nav-item-more dropdown dis-none">
                                                    <a
                                                        className="nav-link dropdown-toggle"
                                                        data-toggle="dropdown"
                                                        href="#"
                                                    >
                                                        <i className="fa fa-ellipsis-h" />
                                                    </a>
                                                    <ul className="dropdown-menu"></ul>
                                                </li>
                                            </ul>
                                            {/*  */}
                                            <a
                                                href="es/economy.html"
                                                className="tab01-link f1-s-1 cl9 hov-cl10 trans-03"
                                            >
                                                Ver Todo
                                                <i className="fs-12 m-l-5 fa fa-caret-right" />
                                            </a>
                                        </div>
                                        {/* Tab panes */}
                                        <div className="tab-content p-t-35">
                                            {/* - */}
                                            <div
                                                className="tab-pane fade show active"
                                                id="tab2-1"
                                                role="tabpanel"
                                            >
                                                <div className="row">
                                                    <div className="col-sm-6 p-r-25 p-r-15-sr991">
                                                        {/* ============= ITEM1A Economía ============= */}
                                                        {/* Item post */}
                                                        <div className="m-b-30">
                                                            <a
                                                                href="es/economy/20230919-sberbank-aumenta-tasas-interes.html"
                                                                className="wrap-pic-w hov1 trans-03"
                                                            >
                                                                <img src="images/dinero-rublos-rusos.png" alt="IMG" />
                                                            </a>
                                                            <div className="p-t-20">
                                                                <h5 className="p-b-5">
                                                                    <a
                                                                        href="es/economy/20230919-sberbank-aumenta-tasas-interes.html"
                                                                        className="f1-m-3 cl2 hov-cl10 trans-03"
                                                                    >
                                                                        Sberbank planea aumentar pronto las tasas de
                                                                        interés en los depósitos.
                                                                    </a>
                                                                </h5>
                                                                <span className="cl8">
                                                                    <a
                                                                        href="es/economy.html"
                                                                        className="f1-s-4 cl8 hov-cl10 trans-03"
                                                                    >
                                                                        Economía
                                                                    </a>
                                                                    <span className="f1-s-3 m-rl-3">-</span>
                                                                    <span className="f1-s-3">Sep 19</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 p-r-25 p-r-15-sr991">
                                                        {/* ============= ITEM1B Economía ============= */}
                                                        {/* Item post */}
                                                        <div className="flex-wr-sb-s m-b-30">
                                                            <a
                                                                href="#"
                                                                className="size-w-1 wrap-pic-w hov1 trans-03"
                                                            >
                                                                <img src="images/post-11.jpg" alt="IMG" />
                                                            </a>
                                                            <div className="size-w-2">
                                                                <h5 className="p-b-5">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                    >
                                                                        Donec metus orci, malesuada et lectus vitae
                                                                    </a>
                                                                </h5>
                                                                <span className="cl8">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                    >
                                                                        Negocios Pequeños
                                                                    </a>
                                                                    <span className="f1-s-3 m-rl-3">-</span>
                                                                    <span className="f1-s-3">Sep 17</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        {/* ============= ITEM1C Economía ============= */}
                                                        {/* Item post */}
                                                        <div className="flex-wr-sb-s m-b-30">
                                                            <a
                                                                href="#"
                                                                className="size-w-1 wrap-pic-w hov1 trans-03"
                                                            >
                                                                <img src="images/post-12.jpg" alt="IMG" />
                                                            </a>
                                                            <div className="size-w-2">
                                                                <h5 className="p-b-5">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                    >
                                                                        Donec metus orci, malesuada et lectus vitae
                                                                    </a>
                                                                </h5>
                                                                <span className="cl8">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                    >
                                                                        Economy
                                                                    </a>
                                                                    <span className="f1-s-3 m-rl-3">-</span>
                                                                    <span className="f1-s-3">Sep 16</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        {/* ============= ITEM1D Economía ============= */}
                                                        {/* Item post */}
                                                        <div className="flex-wr-sb-s m-b-30">
                                                            <a
                                                                href="#"
                                                                className="size-w-1 wrap-pic-w hov1 trans-03"
                                                            >
                                                                <img src="images/post-13.jpg" alt="IMG" />
                                                            </a>
                                                            <div className="size-w-2">
                                                                <h5 className="p-b-5">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                    >
                                                                        Donec metus orci, malesuada et lectus vitae
                                                                    </a>
                                                                </h5>
                                                                <span className="cl8">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                    >
                                                                        Money &amp; Markets
                                                                    </a>
                                                                    <span className="f1-s-3 m-rl-3">-</span>
                                                                    <span className="f1-s-3">Sep 12</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* - */}
                                            <div className="tab-pane fade" id="tab2-2" role="tabpanel">
                                                <div className="row">
                                                    <div className="col-sm-6 p-r-25 p-r-15-sr991">
                                                        {/* ============= ITEM2A Economía ============= */}
                                                        {/* Item post */}
                                                        <div className="m-b-30">
                                                            <a href="#" className="wrap-pic-w hov1 trans-03">
                                                                <img src="images/post-13.jpg" alt="IMG" />
                                                            </a>
                                                            <div className="p-t-20">
                                                                <h5 className="p-b-5">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-m-3 cl2 hov-cl10 trans-03"
                                                                    >
                                                                        Finance lorem ipsum dolor sit amet consectetur
                                                                    </a>
                                                                </h5>
                                                                <span className="cl8">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-4 cl8 hov-cl10 trans-03"
                                                                    >
                                                                        Finance
                                                                    </a>
                                                                    <span className="f1-s-3 m-rl-3">-</span>
                                                                    <span className="f1-s-3">Sep 18</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 p-r-25 p-r-15-sr991">
                                                        {/* ============= ITEM2B Economía ============= */}
                                                        {/* Item post */}
                                                        <div className="flex-wr-sb-s m-b-30">
                                                            <a
                                                                href="#"
                                                                className="size-w-1 wrap-pic-w hov1 trans-03"
                                                            >
                                                                <img src="images/post-12.jpg" alt="IMG" />
                                                            </a>
                                                            <div className="size-w-2">
                                                                <h5 className="p-b-5">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                    >
                                                                        Donec metus orci, malesuada et lectus vitae
                                                                    </a>
                                                                </h5>
                                                                <span className="cl8">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                    >
                                                                        Negocios Pequeños
                                                                    </a>
                                                                    <span className="f1-s-3 m-rl-3">-</span>
                                                                    <span className="f1-s-3">Sep 17</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        {/* ============= ITEM2C Economía ============= */}
                                                        {/* Item post */}
                                                        <div className="flex-wr-sb-s m-b-30">
                                                            <a
                                                                href="#"
                                                                className="size-w-1 wrap-pic-w hov1 trans-03"
                                                            >
                                                                <img src="images/post-11.jpg" alt="IMG" />
                                                            </a>
                                                            <div className="size-w-2">
                                                                <h5 className="p-b-5">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                    >
                                                                        Donec metus orci, malesuada et lectus vitae
                                                                    </a>
                                                                </h5>
                                                                <span className="cl8">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                    >
                                                                        Economy
                                                                    </a>
                                                                    <span className="f1-s-3 m-rl-3">-</span>
                                                                    <span className="f1-s-3">Sep 16</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        {/* ============= ITEM2D Economía ============= */}
                                                        {/* Item post */}
                                                        <div className="flex-wr-sb-s m-b-30">
                                                            <a
                                                                href="#"
                                                                className="size-w-1 wrap-pic-w hov1 trans-03"
                                                            >
                                                                <img src="images/post-10.jpg" alt="IMG" />
                                                            </a>
                                                            <div className="size-w-2">
                                                                <h5 className="p-b-5">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                    >
                                                                        Donec metus orci, malesuada et lectus vitae
                                                                    </a>
                                                                </h5>
                                                                <span className="cl8">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                    >
                                                                        Money &amp; Markets
                                                                    </a>
                                                                    <span className="f1-s-3 m-rl-3">-</span>
                                                                    <span className="f1-s-3">Sep 12</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* - */}
                                            <div className="tab-pane fade" id="tab2-3" role="tabpanel">
                                                <div className="row">
                                                    <div className="col-sm-6 p-r-25 p-r-15-sr991">
                                                        {/* ============= ITEM3A Economía ============= */}
                                                        {/* Item post */}
                                                        <div className="m-b-30">
                                                            <a href="#" className="wrap-pic-w hov1 trans-03">
                                                                <img src="images/post-11.jpg" alt="IMG" />
                                                            </a>
                                                            <div className="p-t-20">
                                                                <h5 className="p-b-5">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-m-3 cl2 hov-cl10 trans-03"
                                                                    >
                                                                        Finance lorem ipsum dolor sit amet consectetur
                                                                    </a>
                                                                </h5>
                                                                <span className="cl8">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-4 cl8 hov-cl10 trans-03"
                                                                    >
                                                                        Finance
                                                                    </a>
                                                                    <span className="f1-s-3 m-rl-3">-</span>
                                                                    <span className="f1-s-3">Sep 18</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 p-r-25 p-r-15-sr991">
                                                        {/* ============= ITEM3B Economía ============= */}
                                                        {/* Item post */}
                                                        <div className="flex-wr-sb-s m-b-30">
                                                            <a
                                                                href="#"
                                                                className="size-w-1 wrap-pic-w hov1 trans-03"
                                                            >
                                                                <img src="images/post-12.jpg" alt="IMG" />
                                                            </a>
                                                            <div className="size-w-2">
                                                                <h5 className="p-b-5">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                    >
                                                                        Donec metus orci, malesuada et lectus vitae
                                                                    </a>
                                                                </h5>
                                                                <span className="cl8">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                    >
                                                                        Negocios Pequeños
                                                                    </a>
                                                                    <span className="f1-s-3 m-rl-3">-</span>
                                                                    <span className="f1-s-3">Sep 17</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        {/* ============= ITEM3C Economía ============= */}
                                                        {/* Item post */}
                                                        <div className="flex-wr-sb-s m-b-30">
                                                            <a
                                                                href="#"
                                                                className="size-w-1 wrap-pic-w hov1 trans-03"
                                                            >
                                                                <img src="images/post-13.jpg" alt="IMG" />
                                                            </a>
                                                            <div className="size-w-2">
                                                                <h5 className="p-b-5">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                    >
                                                                        Donec metus orci, malesuada et lectus vitae
                                                                    </a>
                                                                </h5>
                                                                <span className="cl8">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                    >
                                                                        Economy
                                                                    </a>
                                                                    <span className="f1-s-3 m-rl-3">-</span>
                                                                    <span className="f1-s-3">Sep 16</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        {/* ============= ITEM3D Economía ============= */}
                                                        {/* Item post */}
                                                        <div className="flex-wr-sb-s m-b-30">
                                                            <a
                                                                href="#"
                                                                className="size-w-1 wrap-pic-w hov1 trans-03"
                                                            >
                                                                <img src="images/post-10.jpg" alt="IMG" />
                                                            </a>
                                                            <div className="size-w-2">
                                                                <h5 className="p-b-5">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                    >
                                                                        Donec metus orci, malesuada et lectus vitae
                                                                    </a>
                                                                </h5>
                                                                <span className="cl8">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                    >
                                                                        Money &amp; Markets
                                                                    </a>
                                                                    <span className="f1-s-3 m-rl-3">-</span>
                                                                    <span className="f1-s-3">Sep 12</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* - */}
                                            <div className="tab-pane fade" id="tab2-4" role="tabpanel">
                                                <div className="row">
                                                    <div className="col-sm-6 p-r-25 p-r-15-sr991">
                                                        {/* ============= ITEM4A Economía ============= */}
                                                        {/* Item post */}
                                                        <div className="m-b-30">
                                                            <a href="#" className="wrap-pic-w hov1 trans-03">
                                                                <img src="images/post-12.jpg" alt="IMG" />
                                                            </a>
                                                            <div className="p-t-20">
                                                                <h5 className="p-b-5">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-m-3 cl2 hov-cl10 trans-03"
                                                                    >
                                                                        Finance lorem ipsum dolor sit amet consectetur
                                                                    </a>
                                                                </h5>
                                                                <span className="cl8">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-4 cl8 hov-cl10 trans-03"
                                                                    >
                                                                        Finance
                                                                    </a>
                                                                    <span className="f1-s-3 m-rl-3">-</span>
                                                                    <span className="f1-s-3">Sep 18</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 p-r-25 p-r-15-sr991">
                                                        {/* ============= ITEM4B Economía ============= */}
                                                        {/* Item post */}
                                                        <div className="flex-wr-sb-s m-b-30">
                                                            <a
                                                                href="#"
                                                                className="size-w-1 wrap-pic-w hov1 trans-03"
                                                            >
                                                                <img src="images/post-13.jpg" alt="IMG" />
                                                            </a>
                                                            <div className="size-w-2">
                                                                <h5 className="p-b-5">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                    >
                                                                        Donec metus orci, malesuada et lectus vitae
                                                                    </a>
                                                                </h5>
                                                                <span className="cl8">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                    >
                                                                        Negocios Pequeños
                                                                    </a>
                                                                    <span className="f1-s-3 m-rl-3">-</span>
                                                                    <span className="f1-s-3">Sep 17</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        {/* ============= ITEM4C Economía ============= */}
                                                        {/* Item post */}
                                                        <div className="flex-wr-sb-s m-b-30">
                                                            <a
                                                                href="#"
                                                                className="size-w-1 wrap-pic-w hov1 trans-03"
                                                            >
                                                                <img src="images/post-10.jpg" alt="IMG" />
                                                            </a>
                                                            <div className="size-w-2">
                                                                <h5 className="p-b-5">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                    >
                                                                        Donec metus orci, malesuada et lectus vitae
                                                                    </a>
                                                                </h5>
                                                                <span className="cl8">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                    >
                                                                        Economy
                                                                    </a>
                                                                    <span className="f1-s-3 m-rl-3">-</span>
                                                                    <span className="f1-s-3">Sep 16</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        {/* ============= ITEM4D Economía ============= */}
                                                        {/* Item post */}
                                                        <div className="flex-wr-sb-s m-b-30">
                                                            <a
                                                                href="#"
                                                                className="size-w-1 wrap-pic-w hov1 trans-03"
                                                            >
                                                                <img src="images/post-11.jpg" alt="IMG" />
                                                            </a>
                                                            <div className="size-w-2">
                                                                <h5 className="p-b-5">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                    >
                                                                        Donec metus orci, malesuada et lectus vitae
                                                                    </a>
                                                                </h5>
                                                                <span className="cl8">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                    >
                                                                        Money &amp; Markets
                                                                    </a>
                                                                    <span className="f1-s-3 m-rl-3">-</span>
                                                                    <span className="f1-s-3">Sep 12</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/*===============================================================================================*/}
                                    {/*================================== BLOQUE SOCIEDAD =====================================*/}
                                    {/*===============================================================================================*/}
                                    {/* Travel */}
                                    <div className="tab01 p-b-20">
                                        <div className="tab01-head how2 how2-cl3 bocl12 flex-s-c m-r-10 m-r-0-sr991">
                                            {/* Brand tab */}
                                            <h3 className="f1-m-2 cl14 tab01-title">Sociedad</h3>
                                            {/* Nav tabs */}
                                            <ul className="nav nav-tabs" role="tablist">
                                                <li className="nav-item">
                                                    <a
                                                        className="nav-link active"
                                                        data-toggle="tab"
                                                        href="#tab3-1"
                                                        role="tab"
                                                    >
                                                        Todo
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a
                                                        className="nav-link"
                                                        data-toggle="tab"
                                                        href="#tab3-2"
                                                        role="tab"
                                                    >
                                                        Hoteles
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a
                                                        className="nav-link"
                                                        data-toggle="tab"
                                                        href="#tab3-3"
                                                        role="tab"
                                                    >
                                                        Vuelos
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a
                                                        className="nav-link"
                                                        data-toggle="tab"
                                                        href="#tab3-4"
                                                        role="tab"
                                                    >
                                                        Restaurantes
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a
                                                        className="nav-link"
                                                        data-toggle="tab"
                                                        href="#tab3-5"
                                                        role="tab"
                                                    >
                                                        Cultura
                                                    </a>
                                                </li>
                                                <li className="nav-item-more dropdown dis-none">
                                                    <a
                                                        className="nav-link dropdown-toggle"
                                                        data-toggle="dropdown"
                                                        href="#"
                                                    >
                                                        <i className="fa fa-ellipsis-h" />
                                                    </a>
                                                    <ul className="dropdown-menu"></ul>
                                                </li>
                                            </ul>
                                            {/*  */}
                                            <a
                                                href="es/economy.html"
                                                className="tab01-link f1-s-1 cl9 hov-cl10 trans-03"
                                            >
                                                Ver Todo
                                                <i className="fs-12 m-l-5 fa fa-caret-right" />
                                            </a>
                                        </div>
                                        {/* Tab panes */}
                                        <div className="tab-content p-t-35">
                                            {/* - */}
                                            <div
                                                className="tab-pane fade show active"
                                                id="tab3-1"
                                                role="tabpanel"
                                            >
                                                <div className="row">
                                                    <div className="col-sm-6 p-r-25 p-r-15-sr991">
                                                        {/* ============= ITEM1A Sociedad ============= */}
                                                        {/* Item post */}
                                                        <div className="m-b-30">
                                                            <a
                                                                href="es/ai/20230921-moscu-camaras-ia-multan-pasajeros.html"
                                                                className="wrap-pic-w hov1 trans-03"
                                                            >
                                                                <img src="images/camaras-multas-ia.png" alt="IMG" />
                                                            </a>
                                                            <div className="p-t-20">
                                                                <h5 className="p-b-5">
                                                                    <a
                                                                        href="es/ai/20230921-moscu-camaras-ia-multan-pasajeros.html"
                                                                        className="f1-m-3 cl2 hov-cl10 trans-03"
                                                                    >
                                                                        En Moscú, las cámaras con IA multarán a los
                                                                        pasajeros sin cintúron de seguridad.
                                                                    </a>
                                                                </h5>
                                                                <span className="cl8">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-4 cl8 hov-cl10 trans-03"
                                                                    >
                                                                        Sociedad
                                                                    </a>
                                                                    <span className="f1-s-3 m-rl-3">-</span>
                                                                    <span className="f1-s-3">Sep 21</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 p-r-25 p-r-15-sr991">
                                                        {/* ============= ITEM1B Sociedad ============= */}
                                                        {/* Item post */}
                                                        <div className="flex-wr-sb-s m-b-30">
                                                            <a
                                                                href="#"
                                                                className="size-w-1 wrap-pic-w hov1 trans-03"
                                                            >
                                                                <img src="images/post-05.jpg" alt="IMG" />
                                                            </a>
                                                            <div className="size-w-2">
                                                                <h5 className="p-b-5">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                    >
                                                                        Donec metus orci, malesuada et lectus vitae
                                                                    </a>
                                                                </h5>
                                                                <span className="cl8">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                    >
                                                                        Sociedad
                                                                    </a>
                                                                    <span className="f1-s-3 m-rl-3">-</span>
                                                                    <span className="f1-s-3">Sep 17</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        {/* ============= ITEM1C Sociedad ============= */}
                                                        {/* Item post */}
                                                        <div className="flex-wr-sb-s m-b-30">
                                                            <a
                                                                href="#"
                                                                className="size-w-1 wrap-pic-w hov1 trans-03"
                                                            >
                                                                <img src="images/post-07.jpg" alt="IMG" />
                                                            </a>
                                                            <div className="size-w-2">
                                                                <h5 className="p-b-5">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                    >
                                                                        Donec metus orci, malesuada et lectus vitae
                                                                    </a>
                                                                </h5>
                                                                <span className="cl8">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                    >
                                                                        Flight
                                                                    </a>
                                                                    <span className="f1-s-3 m-rl-3">-</span>
                                                                    <span className="f1-s-3">Sep 16</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        {/* ============= ITEM1D Sociedad ============= */}
                                                        {/* Item post */}
                                                        <div className="flex-wr-sb-s m-b-30">
                                                            <a
                                                                href="#"
                                                                className="size-w-1 wrap-pic-w hov1 trans-03"
                                                            >
                                                                <img src="images/post-17.jpg" alt="IMG" />
                                                            </a>
                                                            <div className="size-w-2">
                                                                <h5 className="p-b-5">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                    >
                                                                        Donec metus orci, malesuada et lectus vitae
                                                                    </a>
                                                                </h5>
                                                                <span className="cl8">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                    >
                                                                        Culture
                                                                    </a>
                                                                    <span className="f1-s-3 m-rl-3">-</span>
                                                                    <span className="f1-s-3">Sep 12</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* - */}
                                            <div className="tab-pane fade" id="tab3-2" role="tabpanel">
                                                <div className="row">
                                                    <div className="col-sm-6 p-r-25 p-r-15-sr991">
                                                        {/* ============= ITEM2A Sociedad ============= */}
                                                        {/* Item post */}
                                                        <div className="m-b-30">
                                                            <a href="#" className="wrap-pic-w hov1 trans-03">
                                                                <img src="images/post-15.jpg" alt="IMG" />
                                                            </a>
                                                            <div className="p-t-20">
                                                                <h5 className="p-b-5">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-m-3 cl2 hov-cl10 trans-03"
                                                                    >
                                                                        You wish lorem ipsum dolor sit amet consectetur
                                                                    </a>
                                                                </h5>
                                                                <span className="cl8">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-4 cl8 hov-cl10 trans-03"
                                                                    >
                                                                        Hotels
                                                                    </a>
                                                                    <span className="f1-s-3 m-rl-3">-</span>
                                                                    <span className="f1-s-3">Sep 18</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 p-r-25 p-r-15-sr991">
                                                        {/* ============= ITEM2B Sociedad ============= */}
                                                        {/* Item post */}
                                                        <div className="flex-wr-sb-s m-b-30">
                                                            <a
                                                                href="#"
                                                                className="size-w-1 wrap-pic-w hov1 trans-03"
                                                            >
                                                                <img src="images/post-16.jpg" alt="IMG" />
                                                            </a>
                                                            <div className="size-w-2">
                                                                <h5 className="p-b-5">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                    >
                                                                        Donec metus orci, malesuada et lectus vitae
                                                                    </a>
                                                                </h5>
                                                                <span className="cl8">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                    >
                                                                        Sociedad
                                                                    </a>
                                                                    <span className="f1-s-3 m-rl-3">-</span>
                                                                    <span className="f1-s-3">Sep 17</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        {/* ============= ITEM2C Sociedad ============= */}
                                                        {/* Item post */}
                                                        <div className="flex-wr-sb-s m-b-30">
                                                            <a
                                                                href="#"
                                                                className="size-w-1 wrap-pic-w hov1 trans-03"
                                                            >
                                                                <img src="images/post-17.jpg" alt="IMG" />
                                                            </a>
                                                            <div className="size-w-2">
                                                                <h5 className="p-b-5">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                    >
                                                                        Donec metus orci, malesuada et lectus vitae
                                                                    </a>
                                                                </h5>
                                                                <span className="cl8">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                    >
                                                                        Flight
                                                                    </a>
                                                                    <span className="f1-s-3 m-rl-3">-</span>
                                                                    <span className="f1-s-3">Sep 16</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        {/* ============= ITEM2D Sociedad ============= */}
                                                        {/* Item post */}
                                                        <div className="flex-wr-sb-s m-b-30">
                                                            <a
                                                                href="#"
                                                                className="size-w-1 wrap-pic-w hov1 trans-03"
                                                            >
                                                                <img src="images/post-18.jpg" alt="IMG" />
                                                            </a>
                                                            <div className="size-w-2">
                                                                <h5 className="p-b-5">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                    >
                                                                        Donec metus orci, malesuada et lectus vitae
                                                                    </a>
                                                                </h5>
                                                                <span className="cl8">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                    >
                                                                        Culture
                                                                    </a>
                                                                    <span className="f1-s-3 m-rl-3">-</span>
                                                                    <span className="f1-s-3">Sep 12</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* - */}
                                            <div className="tab-pane fade" id="tab3-3" role="tabpanel">
                                                <div className="row">
                                                    <div className="col-sm-6 p-r-25 p-r-15-sr991">
                                                        {/* ============= ITEM3A Sociedad ============= */}
                                                        {/* Item post */}
                                                        <div className="m-b-30">
                                                            <a href="#" className="wrap-pic-w hov1 trans-03">
                                                                <img src="images/post-16.jpg" alt="IMG" />
                                                            </a>
                                                            <div className="p-t-20">
                                                                <h5 className="p-b-5">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-m-3 cl2 hov-cl10 trans-03"
                                                                    >
                                                                        You wish lorem ipsum dolor sit amet consectetur
                                                                    </a>
                                                                </h5>
                                                                <span className="cl8">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-4 cl8 hov-cl10 trans-03"
                                                                    >
                                                                        Hotels
                                                                    </a>
                                                                    <span className="f1-s-3 m-rl-3">-</span>
                                                                    <span className="f1-s-3">Sep 18</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 p-r-25 p-r-15-sr991">
                                                        {/* ============= ITEM3B Sociedad ============= */}
                                                        {/* Item post */}
                                                        <div className="flex-wr-sb-s m-b-30">
                                                            <a
                                                                href="#"
                                                                className="size-w-1 wrap-pic-w hov1 trans-03"
                                                            >
                                                                <img src="images/post-17.jpg" alt="IMG" />
                                                            </a>
                                                            <div className="size-w-2">
                                                                <h5 className="p-b-5">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                    >
                                                                        Donec metus orci, malesuada et lectus vitae
                                                                    </a>
                                                                </h5>
                                                                <span className="cl8">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                    >
                                                                        Sociedad
                                                                    </a>
                                                                    <span className="f1-s-3 m-rl-3">-</span>
                                                                    <span className="f1-s-3">Sep 17</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        {/* ============= ITEM3C Sociedad ============= */}
                                                        {/* Item post */}
                                                        <div className="flex-wr-sb-s m-b-30">
                                                            <a
                                                                href="#"
                                                                className="size-w-1 wrap-pic-w hov1 trans-03"
                                                            >
                                                                <img src="images/post-18.jpg" alt="IMG" />
                                                            </a>
                                                            <div className="size-w-2">
                                                                <h5 className="p-b-5">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                    >
                                                                        Donec metus orci, malesuada et lectus vitae
                                                                    </a>
                                                                </h5>
                                                                <span className="cl8">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                    >
                                                                        Flight
                                                                    </a>
                                                                    <span className="f1-s-3 m-rl-3">-</span>
                                                                    <span className="f1-s-3">Sep 16</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        {/* ============= ITEM3D Sociedad ============= */}
                                                        {/* Item post */}
                                                        <div className="flex-wr-sb-s m-b-30">
                                                            <a
                                                                href="#"
                                                                className="size-w-1 wrap-pic-w hov1 trans-03"
                                                            >
                                                                <img src="images/post-24.jpg" alt="IMG" />
                                                            </a>
                                                            <div className="size-w-2">
                                                                <h5 className="p-b-5">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                    >
                                                                        Donec metus orci, malesuada et lectus vitae
                                                                    </a>
                                                                </h5>
                                                                <span className="cl8">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                    >
                                                                        Culture
                                                                    </a>
                                                                    <span className="f1-s-3 m-rl-3">-</span>
                                                                    <span className="f1-s-3">Sep 12</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* - */}
                                            <div className="tab-pane fade" id="tab3-4" role="tabpanel">
                                                <div className="row">
                                                    <div className="col-sm-6 p-r-25 p-r-15-sr991">
                                                        {/* ============= ITEM4A Sociedad ============= */}
                                                        {/* Item post */}
                                                        <div className="m-b-30">
                                                            <a href="#" className="wrap-pic-w hov1 trans-03">
                                                                <img src="images/post-17.jpg" alt="IMG" />
                                                            </a>
                                                            <div className="p-t-20">
                                                                <h5 className="p-b-5">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-m-3 cl2 hov-cl10 trans-03"
                                                                    >
                                                                        You wish lorem ipsum dolor sit amet consectetur
                                                                    </a>
                                                                </h5>
                                                                <span className="cl8">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-4 cl8 hov-cl10 trans-03"
                                                                    >
                                                                        Hotels
                                                                    </a>
                                                                    <span className="f1-s-3 m-rl-3">-</span>
                                                                    <span className="f1-s-3">Sep 18</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 p-r-25 p-r-15-sr991">
                                                        {/* ============= ITEM4B Sociedad ============= */}
                                                        {/* Item post */}
                                                        <div className="flex-wr-sb-s m-b-30">
                                                            <a
                                                                href="#"
                                                                className="size-w-1 wrap-pic-w hov1 trans-03"
                                                            >
                                                                <img src="images/post-18.jpg" alt="IMG" />
                                                            </a>
                                                            <div className="size-w-2">
                                                                <h5 className="p-b-5">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                    >
                                                                        Donec metus orci, malesuada et lectus vitae
                                                                    </a>
                                                                </h5>
                                                                <span className="cl8">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                    >
                                                                        Sociedad
                                                                    </a>
                                                                    <span className="f1-s-3 m-rl-3">-</span>
                                                                    <span className="f1-s-3">Sep 17</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        {/* ============= ITEM4C Sociedad ============= */}
                                                        {/* Item post */}
                                                        <div className="flex-wr-sb-s m-b-30">
                                                            <a
                                                                href="#"
                                                                className="size-w-1 wrap-pic-w hov1 trans-03"
                                                            >
                                                                <img src="images/post-24.jpg" alt="IMG" />
                                                            </a>
                                                            <div className="size-w-2">
                                                                <h5 className="p-b-5">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                    >
                                                                        Donec metus orci, malesuada et lectus vitae
                                                                    </a>
                                                                </h5>
                                                                <span className="cl8">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                    >
                                                                        Flight
                                                                    </a>
                                                                    <span className="f1-s-3 m-rl-3">-</span>
                                                                    <span className="f1-s-3">Sep 16</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        {/* ============= ITEM4D Sociedad ============= */}
                                                        {/* Item post */}
                                                        <div className="flex-wr-sb-s m-b-30">
                                                            <a
                                                                href="#"
                                                                className="size-w-1 wrap-pic-w hov1 trans-03"
                                                            >
                                                                <img src="images/post-15.jpg" alt="IMG" />
                                                            </a>
                                                            <div className="size-w-2">
                                                                <h5 className="p-b-5">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                    >
                                                                        Donec metus orci, malesuada et lectus vitae
                                                                    </a>
                                                                </h5>
                                                                <span className="cl8">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                    >
                                                                        Culture
                                                                    </a>
                                                                    <span className="f1-s-3 m-rl-3">-</span>
                                                                    <span className="f1-s-3">Sep 12</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* - */}
                                            <div className="tab-pane fade" id="tab3-5" role="tabpanel">
                                                <div className="row">
                                                    <div className="col-sm-6 p-r-25 p-r-15-sr991">
                                                        {/* ============= ITEM5A Sociedad ============= */}
                                                        {/* Item post */}
                                                        <div className="m-b-30">
                                                            <a href="#" className="wrap-pic-w hov1 trans-03">
                                                                <img src="images/post-18.jpg" alt="IMG" />
                                                            </a>
                                                            <div className="p-t-20">
                                                                <h5 className="p-b-5">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-m-3 cl2 hov-cl10 trans-03"
                                                                    >
                                                                        You wish lorem ipsum dolor sit amet consectetur
                                                                    </a>
                                                                </h5>
                                                                <span className="cl8">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-4 cl8 hov-cl10 trans-03"
                                                                    >
                                                                        Hotels
                                                                    </a>
                                                                    <span className="f1-s-3 m-rl-3">-</span>
                                                                    <span className="f1-s-3">Sep 18</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 p-r-25 p-r-15-sr991">
                                                        {/* ============= ITEM5B Sociedad ============= */}
                                                        {/* Item post */}
                                                        <div className="flex-wr-sb-s m-b-30">
                                                            <a
                                                                href="#"
                                                                className="size-w-1 wrap-pic-w hov1 trans-03"
                                                            >
                                                                <img src="images/post-17.jpg" alt="IMG" />
                                                            </a>
                                                            <div className="size-w-2">
                                                                <h5 className="p-b-5">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                    >
                                                                        Donec metus orci, malesuada et lectus vitae
                                                                    </a>
                                                                </h5>
                                                                <span className="cl8">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                    >
                                                                        Sociedad
                                                                    </a>
                                                                    <span className="f1-s-3 m-rl-3">-</span>
                                                                    <span className="f1-s-3">Sep 17</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        {/* ============= ITEM5C Sociedad ============= */}
                                                        {/* Item post */}
                                                        <div className="flex-wr-sb-s m-b-30">
                                                            <a
                                                                href="#"
                                                                className="size-w-1 wrap-pic-w hov1 trans-03"
                                                            >
                                                                <img src="images/post-16.jpg" alt="IMG" />
                                                            </a>
                                                            <div className="size-w-2">
                                                                <h5 className="p-b-5">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                    >
                                                                        Donec metus orci, malesuada et lectus vitae
                                                                    </a>
                                                                </h5>
                                                                <span className="cl8">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                    >
                                                                        Flight
                                                                    </a>
                                                                    <span className="f1-s-3 m-rl-3">-</span>
                                                                    <span className="f1-s-3">Sep 16</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        {/* ============= ITEM5D Sociedad ============= */}
                                                        {/* Item post */}
                                                        <div className="flex-wr-sb-s m-b-30">
                                                            <a
                                                                href="#"
                                                                className="size-w-1 wrap-pic-w hov1 trans-03"
                                                            >
                                                                <img src="images/post-15.jpg" alt="IMG" />
                                                            </a>
                                                            <div className="size-w-2">
                                                                <h5 className="p-b-5">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                    >
                                                                        Donec metus orci, malesuada et lectus vitae
                                                                    </a>
                                                                </h5>
                                                                <span className="cl8">
                                                                    <a
                                                                        href="#"
                                                                        className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                    >
                                                                        Culture
                                                                    </a>
                                                                    <span className="f1-s-3 m-rl-3">-</span>
                                                                    <span className="f1-s-3">Sep 12</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/*================================== FIN DE BLOQUES ======================================*/}
                                </div>
                            </div>
                            {/*===============================================================================================*/}
                            {/*================================== PRIMERA BARRA LATERAL ======================================*/}
                            {/*===============================================================================================*/}
                            <div className="col-md-10 col-lg-4">
                                <div className="p-l-10 p-rl-0-sr991 p-b-20">
                                    {/*  */}
                                    <div>
                                        <div className="how2 how2-cl4 flex-s-c">
                                            <h3 className="f1-m-2 cl3 tab01-title">Más Popular</h3>
                                        </div>
                                        <ul className="p-t-35">
                                            <li className="flex-wr-sb-s p-b-22">
                                                <div className="size-a-8 flex-c-c borad-3 size-a-8 bg9 f1-m-4 cl0 m-b-6">
                                                    1
                                                </div>
                                                <a
                                                    href="es/economy/20230923-resistencia-de-la%20economia-rusa.html"
                                                    className="size-w-3 f1-s-7 cl3 hov-cl10 trans-03"
                                                >
                                                    "Тal cosa no se esperaba": en Occidente se sorprenden por la
                                                    resistencia
                                                </a>
                                            </li>
                                            <li className="flex-wr-sb-s p-b-22">
                                                <div className="size-a-8 flex-c-c borad-3 size-a-8 bg9 f1-m-4 cl0 m-b-6">
                                                    2
                                                </div>
                                                <a
                                                    href="es/politics/20230923-Rusia-dispuesta-a-negociar-sobre-Ucrania.html"
                                                    className="size-w-3 f1-s-7 cl3 hov-cl10 trans-03"
                                                >
                                                    Rusia está dispuesta a negociar sobre Ucrania
                                                </a>
                                            </li>
                                            <li className="flex-wr-sb-s p-b-22">
                                                <div className="size-a-8 flex-c-c borad-3 size-a-8 bg9 f1-m-4 cl0 m-b-6">
                                                    3
                                                </div>
                                                <a
                                                    href="es/politics/20230920-kremlin-informa-visita-de-Putin-a-China.html"
                                                    className="size-w-3 f1-s-7 cl3 hov-cl10 trans-03"
                                                >
                                                    Se está preparando la visita de Putin a China
                                                </a>
                                            </li>
                                            <li className="flex-wr-sb-s p-b-22">
                                                <div className="size-a-8 flex-c-c borad-3 size-a-8 bg9 f1-m-4 cl0 m-b-6">
                                                    4
                                                </div>
                                                <a
                                                    href="es/politics/20230920-kremlin-informa-visita-de-Putin-a-China.html"
                                                    className="size-w-3 f1-s-7 cl3 hov-cl10 trans-03"
                                                >
                                                    En el Kremlin afirmaron que se está preparando la visita de
                                                    Putin a China
                                                </a>
                                            </li>
                                            <li className="flex-wr-sb-s p-b-22">
                                                <div className="size-a-8 flex-c-c borad-3 size-a-8 bg9 f1-m-4 cl0">
                                                    5
                                                </div>
                                                <a
                                                    href="es/ai/20230921-moscu-camaras-ia-multan-pasajeros.html"
                                                    className="size-w-3 f1-s-7 cl3 hov-cl10 trans-03"
                                                >
                                                    Cámaras con IA comenzarán a multar a los pasajeros sin
                                                    cinturón de seguridad
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    {/*============================= BANNER LATERAL =================================*/}
                                    {/*  */}
                                    <div className="flex-c-s p-t-8">
                                        <a href="#">
                                            <img
                                                className="max-w-full"
                                                src="images/banner-02.jpg"
                                                alt="IMG"
                                            />
                                        </a>
                                    </div>
                                    {/*============================= REDES LATERAL =================================*/}
                                    {/*  */}
                                    <div className="p-t-50">
                                        <div className="how2 how2-cl4 flex-s-c">
                                            <h3 className="f1-m-2 cl3 tab01-title">Manténte Conectado</h3>
                                        </div>
                                        <ul className="p-t-35">
                                            <li className="flex-wr-sb-c p-b-20">
                                                <a
                                                    href="#"
                                                    className="size-a-8 flex-c-c borad-3 size-a-8 bg-facebook fs-16 cl0 hov-cl0"
                                                >
                                                    <span className="fab fa-facebook-f" />
                                                </a>
                                                <div className="size-w-3 flex-wr-sb-c">
                                                    <span className="f1-s-8 cl3 p-r-20">6879 Fans</span>
                                                    <a
                                                        href="#"
                                                        className="f1-s-9 text-uppercase cl3 hov-cl10 trans-03"
                                                    >
                                                        Like
                                                    </a>
                                                </div>
                                            </li>
                                            <li className="flex-wr-sb-c p-b-20">
                                                <a
                                                    href="#"
                                                    className="size-a-8 flex-c-c borad-3 size-a-8 bg-twitter fs-16 cl0 hov-cl0"
                                                >
                                                    <span className="fab fa-twitter" />
                                                </a>
                                                <div className="size-w-3 flex-wr-sb-c">
                                                    <span className="f1-s-8 cl3 p-r-20">568 Followers</span>
                                                    <a
                                                        href="#"
                                                        className="f1-s-9 text-uppercase cl3 hov-cl10 trans-03"
                                                    >
                                                        Follow
                                                    </a>
                                                </div>
                                            </li>
                                            <li className="flex-wr-sb-c p-b-20">
                                                <a
                                                    href="#"
                                                    className="size-a-8 flex-c-c borad-3 size-a-8 bg-youtube fs-16 cl0 hov-cl0"
                                                >
                                                    <span className="fab fa-youtube" />
                                                </a>
                                                <div className="size-w-3 flex-wr-sb-c">
                                                    <span className="f1-s-8 cl3 p-r-20">5039 Subscribers</span>
                                                    <a
                                                        href="#"
                                                        className="f1-s-9 text-uppercase cl3 hov-cl10 trans-03"
                                                    >
                                                        Subscribe
                                                    </a>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*=============================================================================================*/}
                {/*================================== BANNER HORIZONTAL ========================================*/}
                {/*=============================================================================================*/}
                {/* Banner */}
                <div className="container">
                    <div className="flex-c-c">
                        <a href="#">
                            <img className="max-w-full" src="images/banner-01.jpg" alt="IMG" />
                        </a>
                    </div>
                </div>
                {/*===============================================================================================*/}
                {/*================================== ÚLTIMOS ARTÍCULOS ========================================*/}
                {/*===============================================================================================*/}
                {/* Latest */}
                <section className="bg0 p-t-60 p-b-35">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-10 col-lg-8 p-b-20">
                                <div className="how2 how2-cl4 flex-s-c m-r-10 m-r-0-sr991">
                                    <h3 className="f1-m-2 cl3 tab01-title">Últimos Artículos</h3>
                                </div>
                                <div className="row p-t-35">
                                    <div className="col-sm-6 p-r-25 p-r-15-sr991">
                                        {/*============ ARTÍCULO 1 ============*/}
                                        {/* Item latest */}
                                        <div className="m-b-45">
                                            <a
                                                href="es/economy/20230927-Binance-vende-sus-activos-en-Rusia.html"
                                                className="wrap-pic-w hov1 trans-03"
                                            >
                                                <img src="images/crypto-bitcoin-binance.png" alt="IMG" />
                                            </a>
                                            <div className="p-t-16">
                                                <h5 className="p-b-5">
                                                    <a
                                                        href="es/economy/20230927-Binance-vende-sus-activos-en-Rusia.html"
                                                        className="f1-m-3 cl2 hov-cl10 trans-03"
                                                    >
                                                        La criptobolsa BINANCE vende todos sus activos en Rusia
                                                    </a>
                                                </h5>
                                                <span className="cl8">
                                                    <a href="#" className="f1-s-4 cl8 hov-cl10 trans-03">
                                                        by Belén García
                                                    </a>
                                                    <span className="f1-s-3 m-rl-3">-</span>
                                                    <span className="f1-s-3">Sep 27</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 p-r-25 p-r-15-sr991">
                                        {/*============ ARTÍCULO 2 ============*/}
                                        {/* Item latest */}
                                        <div className="m-b-45">
                                            <a
                                                href="es/economy/20230926-Se-espera-fortalecer-el-rublo-hasta-87.5-rublos-por-dolar.html"
                                                className="wrap-pic-w hov1 trans-03"
                                            >
                                                <img src="images/rublo-ruso-monedas.png" alt="IMG" />
                                            </a>
                                            <div className="p-t-16">
                                                <h5 className="p-b-5">
                                                    <a
                                                        href="es/economy/20230926-Se-espera-fortalecer-el-rublo-hasta-87.5-rublos-por-dolar.html"
                                                        className="f1-m-3 cl2 hov-cl10 trans-03"
                                                    >
                                                        El Ministerio de Economía espera fortalecer el rublo hasta
                                                        87.5 rublos por dólar{" "}
                                                    </a>
                                                </h5>
                                                <span className="cl8">
                                                    <a href="#" className="f1-s-4 cl8 hov-cl10 trans-03">
                                                        by Belén García
                                                    </a>
                                                    <span className="f1-s-3 m-rl-3">-</span>
                                                    <span className="f1-s-3">Sep 26</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 p-r-25 p-r-15-sr991">
                                        {/*============ ARTÍCULO 3 ============*/}
                                        {/* Item latest */}
                                        <div className="m-b-45">
                                            <a
                                                href="es/ai/20230926-Rusia-destina-5,2-mil-millones-de-rublos-inteligencia-artificial.html"
                                                className="wrap-pic-w hov1 trans-03"
                                            >
                                                <img src="images/ia-robot-tecno.png" alt="IMG" />
                                            </a>
                                            <div className="p-t-16">
                                                <h5 className="p-b-5">
                                                    <a
                                                        href="es/ai/20230926-Rusia-destina-5,2-mil-millones-de-rublos-inteligencia-artificial.html"
                                                        className="f1-m-3 cl2 hov-cl10 trans-03"
                                                    >
                                                        El Gobierno Ruso destinará 5,2 mil millones al desarrollo
                                                        de la inteligencia artificial
                                                    </a>
                                                </h5>
                                                <span className="cl8">
                                                    <a href="#" className="f1-s-4 cl8 hov-cl10 trans-03">
                                                        by Valeria Leon
                                                    </a>
                                                    <span className="f1-s-3 m-rl-3">-</span>
                                                    <span className="f1-s-3">Sep 26</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 p-r-25 p-r-15-sr991">
                                        {/*============ ARTÍCULO 4 ============*/}
                                        {/* Item latest */}
                                        <div className="m-b-45">
                                            <a
                                                href="es/economy/20230925-Rusia-exporta-4.9-millones-de-toneladas-de-trigo.html"
                                                className="wrap-pic-w hov1 trans-03"
                                            >
                                                <img
                                                    src="images/trigo-agro-exportacion-maquinaria.png"
                                                    alt="IMG"
                                                />
                                            </a>
                                            <div className="p-t-16">
                                                <h5 className="p-b-5">
                                                    <a
                                                        href="es/economy/20230925-Rusia-exporta-4.9-millones-de-toneladas-de-trigo.html"
                                                        className="f1-m-3 cl2 hov-cl10 trans-03"
                                                    >
                                                        Rusia ha exportado 4.9 millones de toneladas de trigo y
                                                        puede llegar a 6 millones{" "}
                                                    </a>
                                                </h5>
                                                <span className="cl8">
                                                    <a href="#" className="f1-s-4 cl8 hov-cl10 trans-03">
                                                        by Belén García
                                                    </a>
                                                    <span className="f1-s-3 m-rl-3">-</span>
                                                    <span className="f1-s-3">Sep 25</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 p-r-25 p-r-15-sr991">
                                        {/*============ ARTÍCULO 5 ============*/}
                                        {/* Item latest */}
                                        <div className="m-b-45">
                                            <a
                                                href="es/politics/20230923-Rusia-dispuesta-a-negociar-sobre-Ucrania.html"
                                                className="wrap-pic-w hov1 trans-03"
                                            >
                                                <img src="images/kremlin-dia-puente-cuadro.png" alt="IMG" />
                                            </a>
                                            <div className="p-t-16">
                                                <h5 className="p-b-5">
                                                    <a
                                                        href="es/politics/20230923-Rusia-dispuesta-a-negociar-sobre-Ucrania.html"
                                                        className="f1-m-3 cl2 hov-cl10 trans-03"
                                                    >
                                                        Rusia está dispuesta a negociar sobre Ucrania, anunció
                                                        Lavrov
                                                    </a>
                                                </h5>
                                                <span className="cl8">
                                                    <a href="#" className="f1-s-4 cl8 hov-cl10 trans-03">
                                                        by Ana Vargas
                                                    </a>
                                                    <span className="f1-s-3 m-rl-3">-</span>
                                                    <span className="f1-s-3">Sep 24</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 p-r-25 p-r-15-sr991">
                                        {/*============ ARTÍCULO 6 ============*/}
                                        {/* Item latest */}
                                        <div className="m-b-45">
                                            <a
                                                href="es/economy/20230924-gazprom-suministra-a-europa-41millones-metros-cubicos-gas.html"
                                                className="wrap-pic-w hov1 trans-03"
                                            >
                                                <img src="images/tubos-de-gas.png" alt="IMG" />
                                            </a>
                                            <div className="p-t-16">
                                                <h5 className="p-b-5">
                                                    <a
                                                        href="es/economy/20230924-gazprom-suministra-a-europa-41millones-metros-cubicos-gas.html"
                                                        className="f1-m-3 cl2 hov-cl10 trans-03"
                                                    >
                                                        Rusia suministra a Europa 41,8 millones de metros cúbicos
                                                        de gas
                                                    </a>
                                                </h5>
                                                <span className="cl8">
                                                    <a href="#" className="f1-s-4 cl8 hov-cl10 trans-03">
                                                        by John Alvarado
                                                    </a>
                                                    <span className="f1-s-3 m-rl-3">-</span>
                                                    <span className="f1-s-3">Sep 24</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*===========================================================================================*/}
                            {/*======================================= VIDEO =============================================*/}
                            {/*===========================================================================================*/}
                            <div className="col-md-10 col-lg-4">
                                <div className="p-l-10 p-rl-0-sr991 p-b-20">
                                    {/* Video */}
                                    <div className="p-b-55">
                                        <div className="how2 how2-cl4 flex-s-c m-b-35">
                                            <h3 className="f1-m-2 cl3 tab01-title">Vídeo Destacado</h3>
                                        </div>
                                        <div>
                                            <div className="wrap-pic-w pos-relative">
                                                <img src="images/video-01.png" alt="IMG" />
                                                <button
                                                    className="s-full ab-t-l flex-c-c fs-32 cl0 hov-cl10 trans-03"
                                                    data-toggle="modal"
                                                    data-target="#modal-video-01"
                                                >
                                                    <span className="fab fa-youtube" />
                                                </button>
                                            </div>
                                            <div className="p-tb-16 p-rl-25 bg3">
                                                <h5 className="p-b-5">
                                                    <a href="#" className="f1-m-3 cl0 hov-cl10 trans-03">
                                                        El canto del alma, que llegará a tu corazón
                                                    </a>
                                                </h5>
                                                <span className="cl15">
                                                    <a href="#" className="f1-s-4 cl8 hov-cl10 trans-03">
                                                        by Ana Sáenz
                                                    </a>
                                                    <span className="f1-s-3 m-rl-3">-</span>
                                                    <span className="f1-s-3">Sep 18</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Subscribe */}
                                    <div className="bg10 p-rl-35 p-t-28 p-b-35 m-b-55">
                                        <h5 className="f1-m-5 cl0 p-b-10">Subscribe</h5>
                                        <p className="f1-s-1 cl0 p-b-25">
                                            Obtén el contenido actualizado a tu correo.
                                        </p>
                                        <form className="size-a-9 pos-relative">
                                            <input
                                                className="s-full f1-m-6 cl6 plh9 p-l-20 p-r-55"
                                                type="text"
                                                name="email"
                                                placeholder="Email"
                                            />
                                            <button className="size-a-10 flex-c-c ab-t-r fs-16 cl9 hov-cl10 trans-03">
                                                <i className="fa fa-arrow-right" />
                                            </button>
                                        </form>
                                    </div>
                                    {/* Tag */}
                                    <div className="p-b-55">
                                        <div className="how2 how2-cl4 flex-s-c m-b-30">
                                            <h3 className="f1-m-2 cl3 tab01-title">Tags</h3>
                                        </div>
                                        <div className="flex-wr-s-s m-rl--5">
                                            <a
                                                href="#"
                                                className="flex-c-c size-h-2 bo-1-rad-20 bocl12 f1-s-1 cl8 hov-btn2 trans-03 p-rl-20 p-tb-5 m-all-5"
                                            >
                                                Actualidad
                                            </a>
                                            <a
                                                href="#"
                                                className="flex-c-c size-h-2 bo-1-rad-20 bocl12 f1-s-1 cl8 hov-btn2 trans-03 p-rl-20 p-tb-5 m-all-5"
                                            >
                                                Política
                                            </a>
                                            <a
                                                href="#"
                                                className="flex-c-c size-h-2 bo-1-rad-20 bocl12 f1-s-1 cl8 hov-btn2 trans-03 p-rl-20 p-tb-5 m-all-5"
                                            >
                                                Eventos
                                            </a>
                                            <a
                                                href="#"
                                                className="flex-c-c size-h-2 bo-1-rad-20 bocl12 f1-s-1 cl8 hov-btn2 trans-03 p-rl-20 p-tb-5 m-all-5"
                                            >
                                                Viral
                                            </a>
                                            <a
                                                href="#"
                                                className="flex-c-c size-h-2 bo-1-rad-20 bocl12 f1-s-1 cl8 hov-btn2 trans-03 p-rl-20 p-tb-5 m-all-5"
                                            >
                                                Cultura
                                            </a>
                                            <a
                                                href="#"
                                                className="flex-c-c size-h-2 bo-1-rad-20 bocl12 f1-s-1 cl8 hov-btn2 trans-03 p-rl-20 p-tb-5 m-all-5"
                                            >
                                                Entretenimiento
                                            </a>
                                            <a
                                                href="#"
                                                className="flex-c-c size-h-2 bo-1-rad-20 bocl12 f1-s-1 cl8 hov-btn2 trans-03 p-rl-20 p-tb-5 m-all-5"
                                            >
                                                Tendencia
                                            </a>
                                            <a
                                                href="#"
                                                className="flex-c-c size-h-2 bo-1-rad-20 bocl12 f1-s-1 cl8 hov-btn2 trans-03 p-rl-20 p-tb-5 m-all-5"
                                            >
                                                Blogs
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*===============================================================================================*/}
                {/*======================================= PIE DE PAGINA =========================================*/}
                {/*===============================================================================================*/}
                {/* Footer */}
                <footer>
                    <div className="bg2 p-t-40 p-b-25">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-4 p-b-20">
                                    <div className="size-h-3 flex-s-c">
                                        <a href="../../index.html">
                                            <img
                                                className="max-s-full"
                                                src="../../images/icons/logo-02.png"
                                                alt="LOGO"
                                            />
                                        </a>
                                    </div>
                                    <div>
                                        <p className="f1-s-1 cl11 p-b-16">
                                            Actualiza tu perspectiva global con la actualidad de Rusia y del
                                            Mundo a través de fuentes confiables. ¡Información fresca a un
                                            clic! Manténte al tanto de las últimas novedades mundiales en
                                            español. Encuentra informes de última hora sobre política,
                                            ciencia y cultura en un solo lugar.
                                        </p>
                                        <p className="f1-s-1 cl11 p-b-16">¿Alguna Pregunta?</p>
                                        <p className="f1-s-1 cl11 p-b-16">
                                            Contac us: contact@rusia-news.com
                                        </p>
                                        <div className="p-t-15">
                                            <a href="#" className="fs-18 cl11 hov-cl10 trans-03 m-r-8">
                                                <span className="fab fa-vk" />
                                            </a>
                                            <a href="#" className="fs-18 cl11 hov-cl10 trans-03 m-r-8">
                                                <span className="fab fa-telegram" />
                                            </a>
                                            <a href="#" className="fs-18 cl11 hov-cl10 trans-03 m-r-8">
                                                <span className="fab fa-twitter" />
                                            </a>
                                            <a href="#" className="fs-18 cl11 hov-cl10 trans-03 m-r-8">
                                                <span className="fab fa-facebook-f" />
                                            </a>
                                            <a href="#" className="fs-18 cl11 hov-cl10 trans-03 m-r-8">
                                                <span className="fab fa-youtube" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                {/*================================== COLUMNA POST POPULARES ========================================*/}
                                <div className="col-sm-6 col-lg-4 p-b-20">
                                    <div className="size-h-3 flex-s-c">
                                        <h5 className="f1-m-7 cl0">Posts Populares</h5>
                                    </div>
                                    <ul>
                                        {/*============= Ultimo 1 post =============*/}
                                        <li className="flex-wr-sb-s p-b-20">
                                            <a
                                                href="../../es/economy/20230927-Binance-vende-sus-activos-en-Rusia.html"
                                                className="size-w-4 wrap-pic-w hov1 trans-03"
                                            >
                                                <img
                                                    src="../../images/crypto-bitcoin-binance.png"
                                                    alt="IMG"
                                                />
                                            </a>
                                            <div className="size-w-5">
                                                <h6 className="p-b-5">
                                                    <a
                                                        href="../../es/economy/20230927-Binance-vende-sus-activos-en-Rusia.html"
                                                        className="f1-s-5 cl11 hov-cl10 trans-03"
                                                    >
                                                        La criptobolsa BINANCE vende todos sus activos en Rusia
                                                    </a>
                                                </h6>
                                                <span className="f1-s-3 cl6">Sep 27</span>
                                            </div>
                                        </li>
                                        {/*============= Ultimo 2 post =============*/}
                                        <li className="flex-wr-sb-s p-b-20">
                                            <a
                                                href="../../es/economy/20230926-Se-espera-fortalecer-el-rublo-hasta-87.5-rublos-por-dolar.html"
                                                className="size-w-4 wrap-pic-w hov1 trans-03"
                                            >
                                                <img src="../../images/rublo-ruso-monedas.png" alt="IMG" />
                                            </a>
                                            <div className="size-w-5">
                                                <h6 className="p-b-5">
                                                    <a
                                                        href="../../es/economy/20230926-Se-espera-fortalecer-el-rublo-hasta-87.5-rublos-por-dolar.html"
                                                        className="f1-s-5 cl11 hov-cl10 trans-03"
                                                    >
                                                        El Ministerio de Economía espera fortalecer el rublo hasta
                                                        87.5 rublos por dólar
                                                    </a>
                                                </h6>
                                                <span className="f1-s-3 cl6">Sep 26</span>
                                            </div>
                                        </li>
                                        {/*============= Ultimo 3 post =============*/}
                                        <li className="flex-wr-sb-s p-b-20">
                                            <a
                                                href="../../es/ai/20230926-Rusia-destina-5,2-mil-millones-de-rublos-inteligencia-artificial.html"
                                                className="size-w-4 wrap-pic-w hov1 trans-03"
                                            >
                                                <img src="../../images/ia-robot-tecno.png" alt="IMG" />
                                            </a>
                                            <div className="size-w-5">
                                                <h6 className="p-b-5">
                                                    <a
                                                        href="../../es/ai/20230926-Rusia-destina-5,2-mil-millones-de-rublos-inteligencia-artificial.html"
                                                        className="f1-s-5 cl11 hov-cl10 trans-03"
                                                    >
                                                        Putin ordenó aplicar "Sistema de Inteligencia Artificial"
                                                        en la educación superior.
                                                    </a>
                                                </h6>
                                                <span className="f1-s-3 cl6">Sep 26</span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                {/*================================== COLUMNA CATEGORÍAS ========================================*/}
                                <div className="col-sm-6 col-lg-4 p-b-20">
                                    <div className="size-h-3 flex-s-c">
                                        <h5 className="f1-m-7 cl0">Categorias</h5>
                                    </div>
                                    <ul className="m-t--12">
                                        <li className="how-bor1 p-rl-5 p-tb-10">
                                            <a href="#" className="f1-s-5 cl11 hov-cl10 trans-03 p-tb-8">
                                                Actualidad (22)
                                            </a>
                                        </li>
                                        <li className="how-bor1 p-rl-5 p-tb-10">
                                            <a href="#" className="f1-s-5 cl11 hov-cl10 trans-03 p-tb-8">
                                                Política (29)
                                            </a>
                                        </li>
                                        <li className="how-bor1 p-rl-5 p-tb-10">
                                            <a href="#" className="f1-s-5 cl11 hov-cl10 trans-03 p-tb-8">
                                                Tecnología (15)
                                            </a>
                                        </li>
                                        <li className="how-bor1 p-rl-5 p-tb-10">
                                            <a href="#" className="f1-s-5 cl11 hov-cl10 trans-03 p-tb-8">
                                                Eventos (28)
                                            </a>
                                        </li>
                                        <li className="how-bor1 p-rl-5 p-tb-10">
                                            <a href="#" className="f1-s-5 cl11 hov-cl10 trans-03 p-tb-8">
                                                Viral (16)
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*================================== LINEA DE DERECHOS ========================================*/}
                    <div className="bg11">
                        <div className="container size-h-4 flex-c-c p-tb-15">
                            <span className="f1-s-1 cl0 txt-center">
                                Copyright © 2023. All rights reserved | Made by Orbit
                                <a href="#" className="f1-s-1 cl10 hov-link1">
                                    {/* Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. */}
                                    <i className="fa fa-heart" aria-hidden="true" /> with{" "}
                                </a>
                                <a href="https://colorlib.com" target="_blank">
                                    Colorlib
                                </a>
                                {/* Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. */}
                            </span>
                        </div>
                    </div>
                </footer>
                {/*===============================================================================================*/}
                {/*===================================== FIN PIE DE PAGINA =======================================*/}
                {/*===============================================================================================*/}
                {/* Back to top */}
                <div className="btn-back-to-top" id="myBtn">
                    <span className="symbol-btn-back-to-top">
                        <span className="fas fa-angle-up" />
                    </span>
                </div>
                {/* Modal Video 01*/}
                <div
                    className="modal fade"
                    id="modal-video-01"
                    tabIndex={-1}
                    role="dialog"
                    aria-hidden="true"
                >
                    <div className="modal-dialog" role="document" data-dismiss="modal">
                        <div
                            className="close-mo-video-01 trans-0-4"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            ×
                        </div>
                        <div className="wrap-video-mo-01">
                            <div className="video-mo-01">
                                <iframe
                                    src="https://www.youtube.com/embed/ZP_FkF4Ha7I?rel=0"
                                    title="Madre Rusia"
                                    allowFullScreen=""
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {/*===============================================================================================*/}
                {/*===============================================================================================*/}
                {/*===============================================================================================*/}
                {/*===============================================================================================*/}


            </div>

        </React.Fragment>

    );

}
