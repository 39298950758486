


import Menu from "../Menu";
import Footer from "../Footer";
import Sidebar2 from "../Sidebar2";

import { Link } from "react-router-dom";


function Entertainment() {



    return (

        <main>


            <Menu />



            <section >
                <div >

                    Hola 

                </div >
            </section >



            <Footer />


        </main >



    );

}

export default Entertainment;

