
import React from "react";
import { Link } from "react-router-dom";


function Footer() {



    return (


        <main>


            {/*===============================================================================================*/}
            {/*======================================= PIE DE PAGINA =========================================*/}
            {/*===============================================================================================*/}
            {/* Footer */}
            <footer>
                <div className="bg2 p-t-40 p-b-25">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 p-b-20">
                                <div className="size-h-3 flex-s-c">

                                    <Link to="/">

                                        <img
                                            className="max-s-full"
                                            src="images/icons/logo-02.png"
                                            alt="LOGO"
                                        />
                                    </Link>
                                </div>
                                <div>
                                    <p className="f1-s-1 cl11 p-b-16">
                                        Actualiza tu perspectiva global con la actualidad de Rusia y del
                                        Mundo a través de fuentes confiables. ¡Información fresca a un
                                        clic! Manténte al tanto de las últimas novedades mundiales en
                                        español. Encuentra informes de última hora sobre política,
                                        ciencia y cultura en un solo lugar.
                                    </p>
                                    <p className="f1-s-1 cl11 p-b-16">¿Alguna Pregunta?</p>
                                    <p className="f1-s-1 cl11 p-b-16">
                                        Contac us: contact@rusia-news.com
                                    </p>
                                    <div className="p-t-15">
                                        <Link to="/" className="fs-18 cl11 hov-cl10 trans-03 m-r-8">
                                            <span className="fab fa-vk" />   </Link>

                                        <Link to="/" className="fs-18 cl11 hov-cl10 trans-03 m-r-8">
                                            <span className="fab fa-telegram" />   </Link>

                                        <Link to="/" className="fs-18 cl11 hov-cl10 trans-03 m-r-8">
                                            <span className="fab fa-twitter" />   </Link>

                                        <Link to="/" className="fs-18 cl11 hov-cl10 trans-03 m-r-8">
                                            <span className="fab fa-facebook-f" />   </Link>

                                        <Link to="/" className="fs-18 cl11 hov-cl10 trans-03 m-r-8">
                                            <span className="fab fa-youtube" />   </Link>



                                    </div>
                                </div>
                            </div>



                            {/*================================== COLUMNA POST POPULARES ========================================*/}
                            <div className="col-sm-6 col-lg-4 p-b-20">
                                <div className="size-h-3 flex-s-c">
                                    <h5 className="f1-m-7 cl0">Posts Populares</h5>
                                </div>
                                <ul>
                                    {/*============= Ultimo 1 post =============*/}
                                    <li className="flex-wr-sb-s p-b-20">
                                        <a
                                            href="es/politics/20230923-Rusia-dispuesta-a-negociar-sobre-Ucrania.html"
                                            className="size-w-4 wrap-pic-w hov1 trans-03"
                                        >
                                            <img src="images/kremlin-dia-puente-cuadro.png" alt="IMG" />
                                        </a>
                                        <div className="size-w-5">
                                            <h6 className="p-b-5">
                                                <a
                                                    href="es/politics/20230923-Rusia-dispuesta-a-negociar-sobre-Ucrania.html"
                                                    className="f1-s-5 cl11 hov-cl10 trans-03"
                                                >
                                                    Rusia está dispuesta a negociar sobre Ucrania, anunció
                                                    Lavrov
                                                </a>
                                            </h6>
                                            <span className="f1-s-3 cl6">Sep 23</span>
                                        </div>
                                    </li>


                                    {/*============= Ultimo 2 post =============*/}
                                    <li className="flex-wr-sb-s p-b-20">
                                        <a
                                            href="es/politics/20230924-desdolarizacion-mundial-debido-a-los-BRICS.html"
                                            className="size-w-4 wrap-pic-w hov1 trans-03"
                                        >
                                            <img src="images/dolar-roto.png" alt="IMG" />
                                        </a>
                                        <div className="size-w-5">
                                            <h6 className="p-b-5">
                                                <a
                                                    href="es/politics/20230924-desdolarizacion-mundial-debido-a-los-BRICS.html"
                                                    className="f1-s-5 cl11 hov-cl10 trans-03"
                                                >
                                                    La desdolarización de la economía mundial debido a los
                                                    BRICS
                                                </a>
                                            </h6>
                                            <span className="f1-s-3 cl6">Sep 24</span>
                                        </div>
                                    </li>


                                    {/*============= Ultimo 3 post =============*/}
                                    <li className="flex-wr-sb-s p-b-20">
                                        <a
                                            href="es/ai/20230908-putin-inteligencia-artificial.html"
                                            className="size-w-4 wrap-pic-w hov1 trans-03"
                                        >
                                            <img src="images/ia-mujer-pantalla.png" alt="IMG" />
                                        </a>
                                        <div className="size-w-5">
                                            <h6 className="p-b-5">
                                                <a
                                                    href="es/ai/20230908-putin-inteligencia-artificial.html"
                                                    className="f1-s-5 cl11 hov-cl10 trans-03"
                                                >
                                                    Putin ordenó aplicar "Sistema de Inteligencia Artificial"
                                                    en la educación superior.
                                                </a>
                                            </h6>
                                            <span className="f1-s-3 cl6">Sep 18</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>



                            {/*================================== COLUMNA CATEGORÍAS ========================================*/}
                            <div className="col-sm-6 col-lg-4 p-b-20">
                                <div className="size-h-3 flex-s-c">
                                    <h5 className="f1-m-7 cl0">Categorias</h5>
                                </div>
                                <ul className="m-t--12">
                                    <li className="how-bor1 p-rl-5 p-tb-10">

                                        <Link to="/actual" className="f1-s-5 cl11 hov-cl10 trans-03 p-tb-8">
                                            Actualidad (2582) </Link>

                                    </li>
                                    <li className="how-bor1 p-rl-5 p-tb-10">

                                        <Link to="/politics" className="f1-s-5 cl11 hov-cl10 trans-03 p-tb-8">
                                            Política (2829) </Link>

                                    </li>
                                    <li className="how-bor1 p-rl-5 p-tb-10">

                                        <Link to="/economy" className="f1-s-5 cl11 hov-cl10 trans-03 p-tb-8">
                                            Economía (2472) </Link>

                                    </li>
                                    <li className="how-bor1 p-rl-5 p-tb-10">

                                        <Link to="/technology" className="f1-s-5 cl11 hov-cl10 trans-03 p-tb-8">
                                            Tecnología (1865) </Link>


                                    </li>
                                    <li className="how-bor1 p-rl-5 p-tb-10">

                                        <Link to="/society" className="f1-s-5 cl11 hov-cl10 trans-03 p-tb-8">
                                            Sociedad (3282) </Link>

                                    </li>
                                    <li className="how-bor1 p-rl-5 p-tb-10">

                                        <Link to="/viral" className="f1-s-5 cl11 hov-cl10 trans-03 p-tb-8">
                                            Viral (4387) </Link>

                                    </li>



                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {/*================================== LINEA DE DERECHOS ========================================*/}
                <div className="bg11">
                    <div className="container size-h-4 flex-c-c p-tb-15">
                        <span className="f1-s-1 cl0 txt-center">
                            Copyright © 2023. All rights reserved | Made by Orbit
                            <a href="#" className="f1-s-1 cl10 hov-link1">
                                {/* Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. */}
                                <i className="fa fa-heart" aria-hidden="true" /> with{" "}
                            </a>
                            <a href="https://colorlib.com" target="_blank">
                                Colorlib
                            </a>
                            {/* Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. */}
                        </span>
                    </div>
                </div>
            </footer>
            {/*===============================================================================================*/}
            {/*===================================== FIN PIE DE PAGINA =======================================*/}
            {/*===============================================================================================*/}

            
                {/* Back to top */}
                <div className="btn-back-to-top" id="myBtn">
                    <span className="symbol-btn-back-to-top">
                        <span className="fas fa-angle-up" />
                    </span>
                </div>
            


        </main>



    );

}

export default Footer;