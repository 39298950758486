

import Menu from "../../Menu";
import Sidebar from "../../Sidebar";
import Footer from "../../Footer";

import { Link } from "react-router-dom";



function A230929() {



    return (

        <main>



            <Menu />



            {/* Breadcrumb */}
            <div className="container">
                <div className="headline bg0 flex-wr-sb-c p-rl-20 p-tb-8">
                    <div className="f2-s-1 p-r-30 m-tb-6">
                        <Link to="/" className="breadcrumb-item f1-s-3 cl9"> Inicio </Link>

                        <Link to="/trending" className="breadcrumb-item f1-s-3 cl9"> Blog </Link>

                        <span className="breadcrumb-item f1-s-3 cl9">
                            La Federación Rusa se prepara para un gran cambio
                        </span>
                    </div>
                    <div className="pos-relative size-a-2 bo-1-rad-22 of-hidden bocl11 m-tb-6">
                        <input
                            className="f1-s-1 cl6 plh9 s-full p-l-25 p-r-45"
                            type="text"
                            name="search"
                            placeholder="Buscar"
                        />
                        <button className="flex-c-c size-a-1 ab-t-r fs-20 cl2 hov-cl10 trans-03">
                            <i className="zmdi zmdi-search" />
                        </button>
                    </div>
                </div>
            </div>


            {/* Content */}
            <section className="bg0 p-b-140 p-t-10">
                <div className="container">

                    <div className="row justify-content-center">

                        <div className="col-md-10 col-lg-8 p-b-30">
                            <div className="p-r-10 p-r-0-sr991">
                                {/*===============================================================================================*/}
                                {/*================================ DETALLES DEL CONTENIDO  ======================================*/}
                                {/*===============================================================================================*/}
                                {/* Blog Detail */}
                                <div className="p-b-70">

                                    <Link to="/society" className="f1-s-10 cl2 hov-cl10 trans-03 text-uppercase"> Sociedad </Link>


                                    <h3 className="f1-l-3 cl2 p-b-16 p-t-33 respon2">
                                        La primera conferencia parlamentaria "Rusia-América Latina" se
                                        celebra en Moscú
                                    </h3>
                                    <div className="flex-wr-s-s p-b-40">
                                        <span className="f1-s-3 cl8 m-r-15">
                                            <a href="#" className="f1-s-4 cl8 hov-cl10 trans-03">
                                                by Luis Palermo
                                            </a>
                                            <span className="m-rl-3">-</span>
                                            <span>Sep 29</span>
                                        </span>
                                        <span className="f1-s-3 cl8 m-r-15">2351 Views</span>
                                        <a href="#" className="f1-s-3 cl8 hov-cl10 trans-03 m-r-15">
                                            0 Comment
                                        </a>
                                    </div>
                                    <div className="wrap-pic-max-w p-b-30">
                                        <img src="../../images/Putin-Latam.png" alt="IMG" />
                                    </div>
                                    <p className="f1-s-11 cl6 p-b-25">
                                        Del 29 de septiembre al 2 de octubre en Moscú, por iniciativa de
                                        la Duma Estatal, se lleva a cabo la Primera Conferencia
                                        Parlamentaria Internacional "Rusia - América Latina".
                                    </p>
                                    <p className="f1-s-11 cl6 p-b-25">
                                        Más de 200 participantes asistirán, incluyendo parlamentarios de
                                        países de América Latina y el Caribe, representantes de la
                                        comunidad de expertos, líderes sociales y diplomáticos.
                                        Discutirán cuestiones relevantes en la agenda parlamentaria
                                        internacional.
                                    </p>
                                    <p className="f1-s-15 cl1 p-b-25">
                                        En el marco de la conferencia se realizan mesas redondas sobre:
                                    </p>
                                    <p className="f1-s-11 cl6 p-b-25">
                                        • Cooperación económica equitativa y mutuamente beneficiosa: el
                                        papel de los parlamentos.
                                        <br /> <br />
                                        • Desarrollo de relaciones humanitarias entre Rusia y América
                                        Latina: la contribución de los parlamentos.
                                        <br />
                                        • Un mundo multipolar justo: el papel de la diplomacia
                                        parlamentaria.
                                        <br /> <br />
                                        • Seguridad para todos: la posición de los parlamentos.
                                        <br />
                                    </p>
                                    <p className="f1-s-11 cl6 p-b-25">
                                        El 2 de octubre, se llevará a cabo una sesión plenaria de la
                                        Conferencia Parlamentaria Internacional "Rusia - América Latina"
                                        sobre el tema "Cooperación en beneficio de un mundo justo para
                                        todos".
                                    </p>
                                    <p className="f1-s-11 cl6 p-b-25">
                                        La activación del diálogo directo entre los parlamentos de Rusia
                                        y los países de América Latina abre grandes oportunidades para
                                        expandir la cooperación a través de nuevas áreas de colaboración
                                        conjunta, señaló el presidente de Rusia, Vladimir Putin.
                                    </p>
                                    <p className="f1-s-11 cl6 p-b-25">
                                        "La visita de un grupo representativo de legisladores de América
                                        Latina, que expresan la voluntad de sus electores y están
                                        llamados a trabajar en su interés, es una evidencia adicional
                                        del deseo de nuestros países de desarrollar una asociación
                                        mutuamente beneficiosa y completa con Rusia", dijo el jefe de
                                        estado durante su discurso en la conferencia parlamentaria
                                        "Rusia - América Latina".
                                    </p>
                                    <p className="f1-s-11 cl6 p-b-25">
                                        "Estamos convencidos de que la activación del diálogo directo
                                        entre los parlamentos abre oportunidades muy buenas para
                                        profundizar nuestra cooperación y expandirla a través de nuevas
                                        áreas de actividad conjunta", añadió.
                                    </p>
                                    <p className="f1-s-11 cl6 p-b-25">
                                        Putin destacó que la conferencia actual tiene un programa muy
                                        completo y amplio. Según sus palabras, los participantes tendrán
                                        la oportunidad de discutir el papel de la diplomacia
                                        parlamentaria en el fortalecimiento de la cooperación entre
                                        Rusia y los países de América Latina en una variedad de campos,
                                        incluyendo política, seguridad, economía y humanidades.
                                    </p>
                                    <p className="f1-s-11 cl6 p-b-25">
                                        La Primera Conferencia Parlamentaria Internacional "Rusia -
                                        América Latina" se lleva a cabo en Moscú del 29 de septiembre al
                                        2 de octubre. En el marco de la conferencia se realizan mesas
                                        redondas sobre la cooperación económica equitativa y mutuamente
                                        beneficiosa, así como sobre el desarrollo de las relaciones
                                        humanitarias entre Rusia y América Latina.
                                    </p>


                                    {/* =========== FUENTE ============ */}
                                    <div className="flex-s-s p-t-12 p-b-15">
                                        <span className="f1-s-12 cl5 m-r-8">Fuente:</span>
                                        <div className="flex-wr-s-s size-w-0">
                                            <a
                                                href="http://duma.gov.ru/news/57845/"
                                                className="f1-s-12 cl8 hov-link1 m-r-15"
                                                target="_blank"
                                            >
                                                [ DUMA RUSA ]
                                            </a>
                                            <a
                                                href="https://www.interfax.ru/russia/923268"
                                                className="f1-s-12 cl8 hov-link1 m-r-15"
                                                target="_blank"
                                            >
                                                [ INTERFAX ]
                                            </a>
                                        </div>
                                    </div>


                                    {/* ====================== Tag ====================== */}
                                    <div className="flex-s-s p-t-12 p-b-15">
                                        <span className="f1-s-12 cl5 m-r-8">Tags:</span>
                                        <div className="flex-wr-s-s size-w-0">

                                            <Link to="/actual" className="f1-s-12 cl8 hov-link1 m-r-15">
                                                Actualidad </Link>

                                            <Link to="/society" className="f1-s-12 cl8 hov-link1 m-r-15">
                                                Sociedad </Link>

                                            <Link to="/" className="f1-s-12 cl8 hov-link1 m-r-15">
                                                América Latina </Link>

                                            <Link to="/" className="f1-s-12 cl8 hov-link1 m-r-15">
                                                Duma Rusa </Link>


                                        </div>
                                    </div>
                                    {/*===========================================================================================*/}
                                    {/*================================ FIN DEL CONTENIDO  ======================================*/}
                                    {/*===========================================================================================*/}



                                    {/* Share */}
                                    <div className="flex-s-s">
                                        <span className="f1-s-12 cl5 p-t-1 m-r-15">Comparte:</span>
                                        <div className="flex-wr-s-s size-w-0">
                                            {/* <a href="#" class="dis-block f1-s-13 cl0 bg-google borad-3 p-tb-4 p-rl-18 hov-btn1 m-r-3 m-b-3 trans-03"> */}

                                            <Link to="/"
                                                className="dis-block f1-s-13 cl0 bg-facebook borad-3 p-tb-4 p-rl-18 hov-btn1 m-r-3 m-b-3 trans-03">
                                                <i className="fab fa-vk m-r-7" /> Vkontakte </Link>

                                            <Link to="/"
                                                className="dis-block f1-s-13 cl0 bg-google borad-3 p-tb-4 p-rl-18 hov-btn1 m-r-3 m-b-3 trans-03">

                                                <i className="fab fa-telegram m-r-7" /> Telegram </Link>

                                            <Link to="/"
                                                className="dis-block f1-s-13 cl0 bg-twitter borad-3 p-tb-4 p-rl-18 hov-btn1 m-r-3 m-b-3 trans-03" >

                                                <i className="fab fa-twitter m-r-7" /> Twitter </Link>


                                            <Link to="/"
                                                className="dis-block f1-s-13 cl0 bg-facebook borad-3 p-tb-4 p-rl-18 hov-btn1 m-r-3 m-b-3 trans-03">

                                                <i className="fab fa-facebook-f m-r-7" /> Facebook </Link>




                                        </div>
                                    </div>
                                </div>



                                {/* Leave a comment */}
                                <div>
                                    <h4 className="f1-l-4 cl3 p-b-12">Deja un comentario</h4>
                                    <p className="f1-s-13 cl8 p-b-40">
                                        Su email no será publicado. Los campos obligatorios están marcados *
                                        
                                    </p>
                                    <form>
                                        <textarea
                                            className="bo-1-rad-3 bocl13 size-a-15 f1-s-13 cl5 plh6 p-rl-18 p-tb-14 m-b-20"
                                            name="msg"
                                            placeholder="Comentario..."
                                            defaultValue={""}
                                        />
                                        <input
                                            className="bo-1-rad-3 bocl13 size-a-16 f1-s-13 cl5 plh6 p-rl-18 m-b-20"
                                            type="text"
                                            name="name"
                                            placeholder="Nombre*"
                                        />
                                        <input
                                            className="bo-1-rad-3 bocl13 size-a-16 f1-s-13 cl5 plh6 p-rl-18 m-b-20"
                                            type="text"
                                            name="email"
                                            placeholder="Email*"
                                        />
                                        <input
                                            className="bo-1-rad-3 bocl13 size-a-16 f1-s-13 cl5 plh6 p-rl-18 m-b-20"
                                            type="text"
                                            name="website"
                                            placeholder="Sitio web"
                                        />
                                        <button className="size-a-17 bg2 borad-3 f1-s-12 cl0 hov-btn1 trans-03 p-rl-15 m-t-10">
                                            Publicar comentario
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>


                        {/*===============================================================================================*/}
                        {/*================================ BARRA LATERAL ======================================*/}
                        {/*===============================================================================================*/}
                        {/* Sidebar */}



                        <Sidebar />


                    </div>
                </div>
            </section>




            <Footer />


        </main>



    );

}

export default A230929;
