



import Footer from './Footer';
import Menu from './Menu';
import { Link } from "react-router-dom";


function Contact() {



  return (

    <main>



      <Menu />


         {/* <div className="animsition">  */}

        {/* Breadcrumb */}
        <div className="container">
          <div className="headline bg0 flex-wr-sb-c p-rl-20 p-tb-8">
            <div className="f2-s-1 p-r-30 m-tb-6">
              
            <Link to="/" className="breadcrumb-item f1-s-3 cl9"> Inicio </Link>

              <span className="breadcrumb-item f1-s-3 cl9">Contáctanos</span>
            </div>
            <div className="pos-relative size-a-2 bo-1-rad-22 of-hidden bocl11 m-tb-6">
              <input
                className="f1-s-1 cl6 plh9 s-full p-l-25 p-r-45"
                type="text"
                name="search"
                placeholder="Buscar"
              />
              <button className="flex-c-c size-a-1 ab-t-r fs-20 cl2 hov-cl10 trans-03">
                <i className="zmdi zmdi-search" />
              </button>
            </div>
          </div>
        </div>


        {/* Page heading */}
        <div className="container p-t-4 p-b-40">
          <h2 className="f1-l-1 cl2">Contáctanos</h2>
        </div>




        {/* Content */}
        <section className="bg0 p-b-60">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-7 col-lg-8 p-b-80">
                <div className="p-r-10 p-r-0-sr991">
                  <form>
                    <input
                      className="bo-1-rad-3 bocl13 size-a-19 f1-s-13 cl5 plh6 p-rl-18 m-b-20"
                      type="text"
                      name="name"
                      placeholder="Nombre*"
                    />
                    <input
                      className="bo-1-rad-3 bocl13 size-a-19 f1-s-13 cl5 plh6 p-rl-18 m-b-20"
                      type="text"
                      name="email"
                      placeholder="Email*"
                    />
                    <input
                      className="bo-1-rad-3 bocl13 size-a-19 f1-s-13 cl5 plh6 p-rl-18 m-b-20"
                      type="text"
                      name="website"
                      placeholder="Sitio Web"
                    />
                    <textarea
                      className="bo-1-rad-3 bocl13 size-a-15 f1-s-13 cl5 plh6 p-rl-18 p-tb-14 m-b-20"
                      name="msg"
                      placeholder="Tu mensaje"
                      defaultValue={""}
                    />
                    <button className="size-a-20 bg2 borad-3 f1-s-12 cl0 hov-btn1 trans-03 p-rl-15 m-t-20">
                      Enviar
                    </button>
                  </form>
                </div>
              </div>
              {/* Sidebar */}
              <div className="col-md-5 col-lg-4 p-b-80">
                <div className="p-l-10 p-rl-0-sr991">
                  {/* Popular Posts */}
                  <div>
                    <div className="how2 how2-cl4 flex-s-c">
                      <h3 className="f1-m-2 cl3 tab01-title">Lo Más Leído</h3>
                    </div>
                    <ul className="p-t-35">
                      <li className="flex-wr-sb-s p-b-30">
                        <a
                          href="../../es/economy/20230927-Binance-vende-sus-activos-en-Rusia.html"
                          className="size-w-10 wrap-pic-w hov1 trans-03"
                        >
                          <img
                            src="../../images/crypto-bitcoin-binance.png"
                            alt="IMG"
                          />
                        </a>
                        <div className="size-w-11">
                          <h6 className="p-b-4">
                            <a
                              href="../../es/economy/20230927-Binance-vende-sus-activos-en-Rusia.html"
                              className="f1-s-5 cl3 hov-cl10 trans-03"
                            >
                              La criptobolsa BINANCE vende todos sus activos en Rusia
                            </a>
                          </h6>
                          <span className="cl8 txt-center p-b-24">
                            <a href="#" className="f1-s-6 cl8 hov-cl10 trans-03">
                              Economía
                            </a>
                            <span className="f1-s-3 m-rl-3">-</span>
                            <span className="f1-s-3">Sep 27</span>
                          </span>
                        </div>
                      </li>
                      <li className="flex-wr-sb-s p-b-30">
                        <a
                          href="../../es/economy/20230926-Se-espera-fortalecer-el-rublo-hasta-87.5-rublos-por-dolar.html"
                          className="size-w-10 wrap-pic-w hov1 trans-03"
                        >
                          <img src="../../images/rublo-ruso-monedas.png" alt="IMG" />
                        </a>
                        <div className="size-w-11">
                          <h6 className="p-b-4">
                            <a
                              href="../../es/economy/20230926-Se-espera-fortalecer-el-rublo-hasta-87.5-rublos-por-dolar.html"
                              className="f1-s-5 cl3 hov-cl10 trans-03"
                            >
                              El Ministerio de Economía espera fortalecer el rublo
                              hasta 87.5 rublos por dólar
                            </a>
                          </h6>
                          <span className="cl8 txt-center p-b-24">
                            <a href="#" className="f1-s-6 cl8 hov-cl10 trans-03">
                              Economía
                            </a>
                            <span className="f1-s-3 m-rl-3">-</span>
                            <span className="f1-s-3">Sep 26</span>
                          </span>
                        </div>
                      </li>
                      <li className="flex-wr-sb-s p-b-30">
                        <a
                          href="../../es/ai/20230926-Rusia-destina-5,2-mil-millones-de-rublos-inteligencia-artificial.html"
                          className="size-w-10 wrap-pic-w hov1 trans-03"
                        >
                          <img src="../../images/ia-robot-tecno.png" alt="IMG" />
                        </a>
                        <div className="size-w-11">
                          <h6 className="p-b-4">
                            <a
                              href="../../es/ai/20230926-Rusia-destina-5,2-mil-millones-de-rublos-inteligencia-artificial.html"
                              className="f1-s-5 cl3 hov-cl10 trans-03"
                            >
                              El Gobierno Ruso destinará 5,2 mil millones al
                              desarrollo de la inteligencia artificial{" "}
                            </a>
                          </h6>
                          <span className="cl8 txt-center p-b-24">
                            <a href="#" className="f1-s-6 cl8 hov-cl10 trans-03">
                              Tecnología
                            </a>
                            <span className="f1-s-3 m-rl-3">-</span>
                            <span className="f1-s-3">Sep 26</span>
                          </span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>


       {/* </div>  */}

      <Footer />


    </main>



  );

}

export default Contact;

