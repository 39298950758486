


import Menu from "../../Menu";
import Sidebar from "../../Sidebar";
import Footer from "../../Footer";

import { Link } from "react-router-dom";



function A231012() {



    return (

        <main>



            <Menu />



            {/* Breadcrumb */}
            <div className="container">
                <div className="headline bg0 flex-wr-sb-c p-rl-20 p-tb-8">
                    <div className="f2-s-1 p-r-30 m-tb-6">
                        <Link to="/" className="breadcrumb-item f1-s-3 cl9"> Inicio </Link>

                        <Link to="/trending" className="breadcrumb-item f1-s-3 cl9"> Blog </Link>

                        <span className="breadcrumb-item f1-s-3 cl9">
                            La Federación Rusa se prepara para un gran cambio
                        </span>
                    </div>
                    <div className="pos-relative size-a-2 bo-1-rad-22 of-hidden bocl11 m-tb-6">
                        <input
                            className="f1-s-1 cl6 plh9 s-full p-l-25 p-r-45"
                            type="text"
                            name="search"
                            placeholder="Buscar"
                        />
                        <button className="flex-c-c size-a-1 ab-t-r fs-20 cl2 hov-cl10 trans-03">
                            <i className="zmdi zmdi-search" />
                        </button>
                    </div>
                </div>
            </div>


            {/* Content */}
            <section className="bg0 p-b-140 p-t-10">
                <div className="container">

                    <div className="row justify-content-center">

                        <div className="col-md-10 col-lg-8 p-b-30">
                            <div className="p-r-10 p-r-0-sr991">
                                {/*===============================================================================================*/}
                                {/*================================ DETALLES DEL CONTENIDO  ======================================*/}
                                {/*===============================================================================================*/}
                                {/* Blog Detail */}
                                <div className="p-b-70">

                                    <Link to="/economy" className="f1-s-10 cl2 hov-cl10 trans-03 text-uppercase"> Economía </Link>


                                    <h3 className="f1-l-3 cl2 p-b-16 p-t-33 respon2">
                                        AEROFLOT volará a 86 destinos internacionales en el horario de invierno.
                                    </h3>
                                    <div className="flex-wr-s-s p-b-40">
                                        <span className="f1-s-3 cl8 m-r-15">
                                            <a href="#" className="f1-s-4 cl8 hov-cl10 trans-03">
                                                by Jorge Miller
                                            </a>
                                            <span className="m-rl-3">-</span>
                                            <span>Oct 12</span>
                                        </span>
                                        <span className="f1-s-3 cl8 m-r-15">2351 Views</span>
                                        <a href="#" className="f1-s-3 cl8 hov-cl10 trans-03 m-r-15">
                                            0 Comment
                                        </a>
                                    </div>
                                    <div className="wrap-pic-max-w p-b-30">
                                        <img src="../../images/avion-aeroflot-aero.png" alt="IMG" />
                                    </div>






                                    <p className="f1-s-11 cl6 p-b-25">
                                        En el horario de otoño-invierno, que entra en vigencia el 29 de octubre, "AEROFLOT" volará a 190 destinos, incluyendo 86 internacionales, según informó la aerolínea.
                                    </p>
                                    <p className="f1-s-11 cl6 p-b-25">
                                        El número total de rutas de vuelo de la aerolínea para la próxima temporada de otoño-invierno será un 10% mayor que el año anterior. La capacidad de transporte aumentará un 15%.
                                    </p>
                                    <p className="f1-s-11 cl6 p-b-25">
                                        El grupo "AEROFLOT" aumentó los volúmenes de pasajeros en rutas internacionales en septiembre alcanzando un total de 920,000 pasajeros. Al mismo tiempo, el volumen total de pasajeros transportados también creció un 4,7% en comparación con el mismo período del año pasado, llegando a 4,6 millones de personas.
                                    </p>
                                    <p className="f1-s-11 cl6 p-b-25">
                                        "El volumen total de pasajeros transportados alcanzó los 4,6 millones, lo que representa un aumento del 4,7% en comparación con el mismo período de 2022, gracias al continuo desarrollo de la red de rutas del grupo", según el comunicado de la aerolínea.
                                    </p>
                                    <p className="f1-s-11 cl6 p-b-25">
                                        El volumen de pasajeros transportados por el grupo en vuelos nacionales en septiembre fue de 3,7 millones, lo que representa un descenso del 5,7% en comparación con el año anterior.
                                    </p>
                                    <p className="f1-s-11 cl6 p-b-25">
                                        Durante el período de enero a septiembre de 2023, el grupo "Аэрофлот" aumentó los volúmenes de pasajeros en un 14,6% en comparación con el año anterior, alcanzando un total de 35,8 millones de pasajeros. La aerolínea principal del grupo, "Аэрофлот", transportó casi 19 millones de pasajeros (+19%) durante este período. En septiembre, la aerolínea "Аэрофлот" transportó 2,4 millones de pasajeros (+2,1%).
                                    </p>





                                    {/* =========== FUENTE ============ */}
                                    <div className="flex-s-s p-t-12 p-b-15">
                                        <span className="f1-s-12 cl5 m-r-8">Fuente:</span>
                                        <div className="flex-wr-s-s size-w-0">
                                            <a
                                                href="https://www.aeroflot.ru/ru-ru/news/62886"
                                                className="f1-s-12 cl8 hov-link1 m-r-15"
                                                target="_blank"
                                            >
                                                [ AEROFLOT ]
                                            </a>
                                             
                                        </div>
                                    </div>


                                    {/* ====================== Tag ====================== */}
                                    <div className="flex-s-s p-t-12 p-b-15">
                                        <span className="f1-s-12 cl5 m-r-8">Tags:</span>
                                        <div className="flex-wr-s-s size-w-0">

                                            <Link to="/actual" className="f1-s-12 cl8 hov-link1 m-r-15">
                                                Actualidad </Link>

                                            <Link to="/economy" className="f1-s-12 cl8 hov-link1 m-r-15">
                                                Economía </Link>

                                            <Link to="/" className="f1-s-12 cl8 hov-link1 m-r-15">
                                                Aeroflot </Link>

                                            

                                        </div>
                                    </div>
                                    {/*===========================================================================================*/}
                                    {/*================================ FIN DEL CONTENIDO  ======================================*/}
                                    {/*===========================================================================================*/}



                                    {/* Share */}
                                    <div className="flex-s-s">
                                        <span className="f1-s-12 cl5 p-t-1 m-r-15">Comparte:</span>
                                        <div className="flex-wr-s-s size-w-0">
                                            {/* <a href="#" class="dis-block f1-s-13 cl0 bg-google borad-3 p-tb-4 p-rl-18 hov-btn1 m-r-3 m-b-3 trans-03"> */}

                                            <Link to="/"
                                                className="dis-block f1-s-13 cl0 bg-facebook borad-3 p-tb-4 p-rl-18 hov-btn1 m-r-3 m-b-3 trans-03">
                                                <i className="fab fa-vk m-r-7" /> Vkontakte </Link>

                                            <Link to="/"
                                                className="dis-block f1-s-13 cl0 bg-google borad-3 p-tb-4 p-rl-18 hov-btn1 m-r-3 m-b-3 trans-03">

                                                <i className="fab fa-telegram m-r-7" /> Telegram </Link>

                                            <Link to="/"
                                                className="dis-block f1-s-13 cl0 bg-twitter borad-3 p-tb-4 p-rl-18 hov-btn1 m-r-3 m-b-3 trans-03" >

                                                <i className="fab fa-twitter m-r-7" /> Twitter </Link>


                                            <Link to="/"
                                                className="dis-block f1-s-13 cl0 bg-facebook borad-3 p-tb-4 p-rl-18 hov-btn1 m-r-3 m-b-3 trans-03">

                                                <i className="fab fa-facebook-f m-r-7" /> Facebook </Link>




                                        </div>
                                    </div>
                                </div>



                                {/* Leave a comment */}
                                <div>
                                    <h4 className="f1-l-4 cl3 p-b-12">Deja un comentario</h4>
                                    <p className="f1-s-13 cl8 p-b-40">
                                        Su email no será publicado. Los campos obligatorios están marcados *

                                    </p>
                                    <form>
                                        <textarea
                                            className="bo-1-rad-3 bocl13 size-a-15 f1-s-13 cl5 plh6 p-rl-18 p-tb-14 m-b-20"
                                            name="msg"
                                            placeholder="Comentario..."
                                            defaultValue={""}
                                        />
                                        <input
                                            className="bo-1-rad-3 bocl13 size-a-16 f1-s-13 cl5 plh6 p-rl-18 m-b-20"
                                            type="text"
                                            name="name"
                                            placeholder="Nombre*"
                                        />
                                        <input
                                            className="bo-1-rad-3 bocl13 size-a-16 f1-s-13 cl5 plh6 p-rl-18 m-b-20"
                                            type="text"
                                            name="email"
                                            placeholder="Email*"
                                        />
                                        <input
                                            className="bo-1-rad-3 bocl13 size-a-16 f1-s-13 cl5 plh6 p-rl-18 m-b-20"
                                            type="text"
                                            name="website"
                                            placeholder="Sitio web"
                                        />
                                        <button className="size-a-17 bg2 borad-3 f1-s-12 cl0 hov-btn1 trans-03 p-rl-15 m-t-10">
                                            Publicar comentario
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>


                        {/*===============================================================================================*/}
                        {/*================================ BARRA LATERAL ======================================*/}
                        {/*===============================================================================================*/}
                        {/* Sidebar */}



                        <Sidebar />


                    </div>
                </div>
            </section>




            <Footer />


        </main>



    );

}

export default A231012;
