

import Menu from "../../Menu";
import Sidebar from "../../Sidebar";
import Footer from "../../Footer";

import { Link } from "react-router-dom";



function A231015() {



    return (

        <main>



            <Menu />



            {/* Breadcrumb */}
            <div className="container">
                <div className="headline bg0 flex-wr-sb-c p-rl-20 p-tb-8">
                    <div className="f2-s-1 p-r-30 m-tb-6">
                        <Link to="/" className="breadcrumb-item f1-s-3 cl9"> Inicio </Link>

                        <Link to="/trending" className="breadcrumb-item f1-s-3 cl9"> Blog </Link>

                        <span className="breadcrumb-item f1-s-3 cl9">
                            La Federación Rusa se prepara para un gran cambio
                        </span>
                    </div>
                    <div className="pos-relative size-a-2 bo-1-rad-22 of-hidden bocl11 m-tb-6">
                        <input
                            className="f1-s-1 cl6 plh9 s-full p-l-25 p-r-45"
                            type="text"
                            name="search"
                            placeholder="Buscar"
                        />
                        <button className="flex-c-c size-a-1 ab-t-r fs-20 cl2 hov-cl10 trans-03">
                            <i className="zmdi zmdi-search" />
                        </button>
                    </div>
                </div>
            </div>


            {/* Content */}
            <section className="bg0 p-b-140 p-t-10">
                <div className="container">

                    <div className="row justify-content-center">

                        <div className="col-md-10 col-lg-8 p-b-30">
                            <div className="p-r-10 p-r-0-sr991">
                                {/*===============================================================================================*/}
                                {/*================================ DETALLES DEL CONTENIDO  ======================================*/}
                                {/*===============================================================================================*/}
                                {/* Blog Detail */}
                                <div className="p-b-70">

                                    <Link to="/society" className="f1-s-10 cl2 hov-cl10 trans-03 text-uppercase"> Sociedad </Link>


                                    <h3 className="f1-l-3 cl2 p-b-16 p-t-33 respon2">
                                        El puente de Crimea fue completamente restaurado antes de lo previsto
                                    </h3>
                                    <div className="flex-wr-s-s p-b-40">
                                        <span className="f1-s-3 cl8 m-r-15">
                                            <a href="#" className="f1-s-4 cl8 hov-cl10 trans-03">
                                                by Belén García
                                            </a>
                                            <span className="m-rl-3">-</span>
                                            <span>Oct 15</span>
                                        </span>
                                        <span className="f1-s-3 cl8 m-r-15">2351 Views</span>
                                        <a href="#" className="f1-s-3 cl8 hov-cl10 trans-03 m-r-15">
                                            0 Comment
                                        </a>
                                    </div>
                                    <div className="wrap-pic-max-w p-b-30">
                                        <img src="../../images/puente-crimea-rusia.png" alt="IMG" />
                                    </div>







                                    <p className="f1-s-11 cl6 p-b-25">
                                        El viceprimer ministro de Rusia, Marat Khusnullin, anunció que el tráfico de vehículos se reabrió en el Puente de Crimea el sábado, en todas las cuatro vías, adelantándose a la fecha prevista.
                                    </p>
                                    <p className="f1-s-11 cl6 p-b-25">
                                        "Dieciocho días antes de la fecha programada, hemos completado la restauración total del Puente de Crimea y hemos reabierto el tráfico de vehículos en las cuatro vías", escribió en su canal de Telegram.
                                    </p>
                                    <p className="f1-s-11 cl6 p-b-25">
                                        Khuznullin destacó que todas las obras de reparación se completaron en menos de tres meses.
                                    </p>
                                    <p className="f1-s-11 cl6 p-b-25">
                                        "La parte derecha, como se informó al presidente, se planeaba restaurar el 1 de noviembre. Pero gracias al trabajo heroico de todos los involucrados en este proyecto único, hemos logrado terminar antes de lo previsto", escribió el viceprimer ministro de Rusia.
                                    </p>
                                    <p className="f1-s-11 cl6 p-b-25">
                                        El servicio de prensa de Rosavtodor informó que en 88 días, los especialistas construyeron la infraestructura temporal necesaria para las obras, desmontaron las secciones dañadas.
                                    </p>
                                    <p className="f1-s-11 cl6 p-b-25">
                                        "Durante las obras de restauración se utilizaron las mismas tecnologías que se utilizaron en la construcción del Puente de Crimea, por lo que estábamos bien preparados para cualquier eventualidad. Esto nos permitió completar todos los trabajos mucho antes de las fechas previstas", dijo el jefe de Rosavtodor, Roman Novikov.
                                    </p>
                                    <p className="f1-s-11 cl6 p-b-25">
                                        En la primera mitad de octubre, los constructores del puente transportaron e instalaron una nueva sección en su posición de proyecto, colocaron dos capas de asfalto, instalaron barreras de seguridad y postes de iluminación, renovaron los sistemas de drenaje y seguridad del transporte. El 13 de octubre, una comisión inspeccionó la calidad de los trabajos realizados.
                                    </p>
                                    <p className="f1-s-11 cl6 p-b-25">
                                        La parte izquierda del puente se reabrió al tráfico después de las obras de reparación y restauración el 14 de septiembre, un día antes de la fecha programada.
                                    </p>
                                    <p className="f1-s-11 cl6 p-b-25">
                                        En la noche del 17 de julio de 2023, el Puente de Crimea fue atacado por dos vehículos aéreos no tripulados ucranianos. Dos residentes de la región de Belgorod murieron y su hija menor resultó herida. Se abrió un caso penal por actos de terrorismo.
                                    </p>
                                    <p className="f1-s-11 cl6 p-b-25">
                                        Las inspecciones mostraron que las pilas del puente no resultaron dañadas. El servicio ferroviario se reanudó la mañana del 17 de julio, y el 18 de julio se restableció el tráfico de vehículos en modo reversible por un carril.
                                    </p>
                                    <p className="f1-s-11 cl6 p-b-25">
                                        El costo de las reparaciones del Puente de Crimea se estimó preliminarmente en 1-1.3 mil millones de rublos.
                                    </p>






                                    {/* =========== FUENTE ============ */}
                                    <div className="flex-s-s p-t-12 p-b-15">
                                        <span className="f1-s-12 cl5 m-r-8">Fuente:</span>
                                        <div className="flex-wr-s-s size-w-0">

                                            <Link to="https://t.me/mkhusnullin/1939" className="f1-s-12 cl8 hov-link1 m-r-15"
                                                target="_blank">
                                                [ Telegram - Marat Khusnullin ] </Link>

                                            <Link to="https://ria.ru/20231014/most-1902743965.html" className="f1-s-12 cl8 hov-link1 m-r-15"
                                                target="_blank">
                                                [ RIA NOVOSTI ] </Link>

                                        </div>
                                    </div>


                                    {/* ====================== Tag ====================== */}
                                    <div className="flex-s-s p-t-12 p-b-15">
                                        <span className="f1-s-12 cl5 m-r-8">Tags:</span>
                                        <div className="flex-wr-s-s size-w-0">

                                            <Link to="/actual" className="f1-s-12 cl8 hov-link1 m-r-15">
                                                Actualidad </Link>

                                            <Link to="/economy" className="f1-s-12 cl8 hov-link1 m-r-15">
                                                Economía </Link>

                                            <Link to="/" className="f1-s-12 cl8 hov-link1 m-r-15">
                                                Marat Khusnullin </Link>



                                        </div>
                                    </div>
                                    {/*===========================================================================================*/}
                                    {/*================================ FIN DEL CONTENIDO  ======================================*/}
                                    {/*===========================================================================================*/}



                                    {/* Share */}
                                    <div className="flex-s-s">
                                        <span className="f1-s-12 cl5 p-t-1 m-r-15">Comparte:</span>
                                        <div className="flex-wr-s-s size-w-0">
                                            {/* <a href="#" class="dis-block f1-s-13 cl0 bg-google borad-3 p-tb-4 p-rl-18 hov-btn1 m-r-3 m-b-3 trans-03"> */}

                                            <Link to="/"
                                                className="dis-block f1-s-13 cl0 bg-facebook borad-3 p-tb-4 p-rl-18 hov-btn1 m-r-3 m-b-3 trans-03">
                                                <i className="fab fa-vk m-r-7" /> Vkontakte </Link>

                                            <Link to="/"
                                                className="dis-block f1-s-13 cl0 bg-google borad-3 p-tb-4 p-rl-18 hov-btn1 m-r-3 m-b-3 trans-03">

                                                <i className="fab fa-telegram m-r-7" /> Telegram </Link>

                                            <Link to="/"
                                                className="dis-block f1-s-13 cl0 bg-twitter borad-3 p-tb-4 p-rl-18 hov-btn1 m-r-3 m-b-3 trans-03" >

                                                <i className="fab fa-twitter m-r-7" /> Twitter </Link>


                                            <Link to="/"
                                                className="dis-block f1-s-13 cl0 bg-facebook borad-3 p-tb-4 p-rl-18 hov-btn1 m-r-3 m-b-3 trans-03">

                                                <i className="fab fa-facebook-f m-r-7" /> Facebook </Link>




                                        </div>
                                    </div>
                                </div>



                                {/* Leave a comment */}
                                <div>
                                    <h4 className="f1-l-4 cl3 p-b-12">Deja un comentario</h4>
                                    <p className="f1-s-13 cl8 p-b-40">
                                        Su email no será publicado. Los campos obligatorios están marcados *

                                    </p>
                                    <form>
                                        <textarea
                                            className="bo-1-rad-3 bocl13 size-a-15 f1-s-13 cl5 plh6 p-rl-18 p-tb-14 m-b-20"
                                            name="msg"
                                            placeholder="Comentario..."
                                            defaultValue={""}
                                        />
                                        <input
                                            className="bo-1-rad-3 bocl13 size-a-16 f1-s-13 cl5 plh6 p-rl-18 m-b-20"
                                            type="text"
                                            name="name"
                                            placeholder="Nombre*"
                                        />
                                        <input
                                            className="bo-1-rad-3 bocl13 size-a-16 f1-s-13 cl5 plh6 p-rl-18 m-b-20"
                                            type="text"
                                            name="email"
                                            placeholder="Email*"
                                        />
                                        <input
                                            className="bo-1-rad-3 bocl13 size-a-16 f1-s-13 cl5 plh6 p-rl-18 m-b-20"
                                            type="text"
                                            name="website"
                                            placeholder="Sitio web"
                                        />
                                        <button className="size-a-17 bg2 borad-3 f1-s-12 cl0 hov-btn1 trans-03 p-rl-15 m-t-10">
                                            Publicar comentario
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>


                        {/*===============================================================================================*/}
                        {/*================================ BARRA LATERAL ======================================*/}
                        {/*===============================================================================================*/}
                        {/* Sidebar */}



                        <Sidebar />


                    </div>
                </div>
            </section>




            <Footer />


        </main>



    );

}

export default A231015;
