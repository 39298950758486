

// import React from "react";
import React, { useState, useEffect } from 'react';
import * as ReactDOM from 'react-dom';
import { Link } from "react-router-dom";


function Menu() {

    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

    const toggleMobileMenu = () => {
        setMobileMenuOpen(!isMobileMenuOpen);
    };

    const toggleSubMenu = (e) => {
        e.target.classList.toggle('turn-arrow-main-menu-m');
        e.target.nextElementSibling.style.display = e.target.classList.contains('turn-arrow-main-menu-m') ? 'block' : 'none';
    };

    const closeMobileMenu = () => {
        if (isMobileMenuOpen) {
            setMobileMenuOpen(false);
        }
    };




    return (


        <main>

            <header>
                {/* ENCABEZADO ESCRITORIO */}
                {/* Header desktop */}
                <div className="container-menu-desktop">



                    <div className="topbar">
                        <div className="content-topbar container h-100">
                            <div className="left-topbar">
                                <span className="left-topbar-item flex-wr-s-c">
                                    <span>MOSCÚ, MOS</span>
                                    <img
                                        className="m-b-1 m-rl-8"
                                        src="images/icons/icon-night.png"
                                        alt="IMG"
                                    />
                                    <span>HI 55° LO 37°</span>
                                </span>
                                <Link to="/about" className="left-topbar-item"> Acerca de </Link>

                                {/* <a href="#" className="left-topbar-item"> */}
                                <Link to="/contact" className="left-topbar-item"> Contacto </Link>

                                <a href="#" className="left-topbar-item">
                                    Sing up
                                </a>
                                <a href="#" className="left-topbar-item">
                                    Log in
                                </a>
                            </div>
                            <div className="right-topbar">

                                <Link to="/"> <span className="fab fa-vk" />   </Link>
                                <Link to="/"> <span className="fab fa-telegram" />   </Link>
                                <Link to="/"> <span className="fab fa-twitter" />   </Link>
                                <Link to="/"> <span className="fab fa-facebook-f" />   </Link>
                                <Link to="/"> <span className="fab fa-youtube" />   </Link>


                            </div>
                        </div>
                    </div>




                    {/* ENCABEZADO MOVIL */}
                    {/* Header Mobile */}
                    <div className="wrap-header-mobile">

                        {/* Logo mobiLe */}
                        <div className="logo-mobile">
                            <Link to="/">
                                <img src="images/icons/logo-01.png" alt="IMG-LOGO" />
                            </Link>
                        </div>


                        {/* Button show menu */}
                        <div
                            className={`btn-show-menu-mobile hamburger hamburger--squeeze m-r--8 ${isMobileMenuOpen ? 'is-active' : ''}`}
                            onClick={toggleMobileMenu}
                        >

                            <span className="hamburger-box">
                                <span className="hamburger-inner" />
                            </span>
                        </div>
                    </div>






                    {/* Menu Mobile */}
                    <div className={`menu-mobile ${isMobileMenuOpen ? 'show-menu' : ''}`}>

                        <ul className="topbar-mobile">
                            <li className="left-topbar">
                                <span className="left-topbar-item flex-wr-s-c">
                                    <span>MOSCÚ, MOS</span>
                                    <img
                                        className="m-b-1 m-rl-8"
                                        src="images/icons/icon-night.png"
                                        alt="IMG"
                                    />
                                    <span>HI 55° LO 37°</span>
                                </span>
                            </li>


                            <li className="left-topbar">

                                <Link to="/about" className="left-topbar-item"> Acerca de </Link>
                                <Link to="/contact" className="left-topbar-item"> Contacto </Link>


                                <a href="#" className="left-topbar-item">
                                    Sing up
                                </a>
                                <a href="#" className="left-topbar-item">
                                    Log in
                                </a>
                            </li>
                            <li className="right-topbar">

                                <Link to="/"> <span className="fab fa-vk" />   </Link>
                                <Link to="/"> <span className="fab fa-telegram" />   </Link>
                                <Link to="/"> <span className="fab fa-twitter" />   </Link>
                                <Link to="/"> <span className="fab fa-facebook-f" />   </Link>
                                <Link to="/"> <span className="fab fa-youtube" />   </Link>

                            </li>
                        </ul>


                        {/* MENU MOVIL */}
                        <ul className="main-menu-m">
                            <li>
                                <Link to="/"> Inicio </Link>
                                <span className="arrow-main-menu-m">
                                    <i className="fa fa-angle-right" aria-hidden="true" />
                                </span>
                            </li>
                            <li> <Link to="/actual"> Actualidad </Link> </li>
                            <li> <Link to="/politics"> Política </Link>  </li>
                            <li> <Link to="/economy"> Economía </Link>  </li>
                            <li> <Link to="/technology"> Tecnología </Link> </li>
                            <li> <Link to="/society"> Sociedad </Link> </li>
                            <li> <Link to="/video"> Vídeo </Link> </li>

                            <li>
                                <a href="#">Destacado</a>
                                <ul className="sub-menu-m">

                                    <li><Link to="/about"> Acerca de </Link> </li>
                                    <li><Link to="/contact"> Contáctanos </Link> </li>

                                </ul>
                                <span className="arrow-main-menu-m">
                                    <i className="fa fa-angle-right" aria-hidden="true" />
                                </span>
                            </li>
                        </ul>
                    </div>



                    {/*===============================================================================================*/}
                    {/*===============================================================================================*/}
                    {/* ESCRITORIO */}
                    {/*  */}
                    <div className="wrap-logo container">
                        {/* Logo desktop */}
                        <div className="logo">
                            <Link to="/">
                                <img src="images/icons/logo-01.png" alt="LOGO" />
                            </Link>
                        </div>
                        {/* Banner */}
                        <div className="banner-header">

                            <Link to="/">  <img src="images/banner-01.jpg" alt="IMG" /> </Link>

                        </div>
                    </div>






                    <div className="wrap-main-nav">
                        <div className="main-nav">

                            {/* =================== MENU ESCRITORIO =================== */}
                            {/* Menu desktop */}
                            <nav className="menu-desktop">
                                <a className="logo-stick" href="index.html">
                                    <img src="images/icons/logo-01.png" alt="LOGO" />
                                </a>
                                <ul className="main-menu">
                                    <li className="main-menu-active">
                                        <Link to="/"> Inicio </Link>
                                    </li>



                                    {/*===============================================================================================*/}
                                    {/* ======= BLOQUE ACTUALIDAD (news) ======= */}
                                    <li className="mega-menu-item">
                                        <Link to="/actual"> Actualidad </Link>

                                        <div className="sub-mega-menu">
                                            <div className="nav flex-column nav-pills" role="tablist">
                                                <a
                                                    className="nav-link active"
                                                    data-toggle="pill"
                                                    href="#news-0"
                                                    role="tab"
                                                >
                                                    Todo
                                                </a>
                                                <a
                                                    className="nav-link"
                                                    data-toggle="pill"
                                                    href="#news-1"
                                                    role="tab"
                                                >
                                                    Tendencia
                                                </a>
                                                <a
                                                    className="nav-link"
                                                    data-toggle="pill"
                                                    href="#news-2"
                                                    role="tab"
                                                >
                                                    Tecnología
                                                </a>
                                                <a
                                                    className="nav-link"
                                                    data-toggle="pill"
                                                    href="#news-3"
                                                    role="tab"
                                                >
                                                    Sociedad
                                                </a>
                                                {/*
                                                   <a class="nav-link" data-toggle="pill" href="#news-4" role="tab">Eventos</a>
                                                   <a class="nav-link" data-toggle="pill" href="#news-5" role="tab">Viral</a> */}


                                            </div>
                                            <div className="tab-content">
                                                <div
                                                    className="tab-pane show active"
                                                    id="news-0"
                                                    role="tabpanel"
                                                >
                                                    <div className="row">



                                                        {/* ======= ItemA Actualidad ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>

                                                                <Link to="/231015-El-puente-de-Crimea-fue-completamente-restaurado" className="wrap-pic-w hov1 trans-03">
                                                                    <img
                                                                        src="images/puente-crimea-rusia.png"
                                                                        alt="IMG"
                                                                    /> </Link>



                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <Link to="/231015-El-puente-de-Crimea-fue-completamente-restaurado"
                                                                            className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                        >
                                                                            El puente de Crimea fue completamente restaurado antes de lo previsto
                                                                        </Link>
                                                                    </h5>
                                                                    <span className="cl8">


                                                                        <Link to="/society" className="f1-s-6 cl8 hov-cl10 trans-03"> Sociedad </Link>

                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Oct 15</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>





                                                        {/* ======= ItemA Actualidad ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>

                                                                <Link to="/231012-Aeroflot-aumenta-vuelos-internacionales" className="wrap-pic-w hov1 trans-03">
                                                                    <img
                                                                        src="images/avion-aeroflot-aero.png"
                                                                        alt="IMG"
                                                                    /> </Link>



                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <Link to="/231012-Aeroflot-aumenta-vuelos-internacionales"
                                                                            className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                        >
                                                                            AEROFLOT volará a otros 86 destinos internacionales.
                                                                        </Link>
                                                                    </h5>
                                                                    <span className="cl8">


                                                                        <Link to="/economy" className="f1-s-6 cl8 hov-cl10 trans-03"> Economía </Link>

                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Oct 12</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>






                                                        {/* ======= ItemA Actualidad ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a
                                                                    href="es/economy/20230927-Binance-vende-sus-activos-en-Rusia.html"
                                                                    className="wrap-pic-w hov1 trans-03"
                                                                >
                                                                    <img
                                                                        src="images/crypto-bitcoin-binance.png"
                                                                        alt="IMG"
                                                                    />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href="es/economy/20230927-Binance-vende-sus-activos-en-Rusia.html"
                                                                            className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                        >
                                                                            La criptobolsa BINANCE vende todos sus activos
                                                                            en Rusia
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">


                                                                        <Link to="/economy" className="f1-s-6 cl8 hov-cl10 trans-03"> Economía </Link>

                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Sep 27</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>



                                                        {/* ======= ItemA1 Actualidad ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a
                                                                    href="es/economy/20230926-Se-espera-fortalecer-el-rublo-hasta-87.5-rublos-por-dolar.html"
                                                                    className="wrap-pic-w hov1 trans-03"
                                                                >
                                                                    <img
                                                                        src="images/rublo-ruso-monedas.png"
                                                                        alt="IMG"
                                                                    />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href="es/economy/20230926-Se-espera-fortalecer-el-rublo-hasta-87.5-rublos-por-dolar.html"
                                                                            className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                        >
                                                                            El Ministerio de Economía espera fortalecer el
                                                                            rublo hasta 87.5 rublos por dólar
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">
                                                                        <Link to="/economy" className="f1-s-6 cl8 hov-cl10 trans-03"> Economía </Link>

                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Sep 26</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>










                                                    </div>
                                                </div>



                                                <div className="tab-pane" id="news-1" role="tabpanel">
                                                    <div className="row">


                                                        {/* ======= ItemA  Actualidad ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a
                                                                    href="es/ai/20230926-Rusia-destina-5,2-mil-millones-de-rublos-inteligencia-artificial.html"
                                                                    className="wrap-pic-w hov1 trans-03"
                                                                >
                                                                    <img src="images/ia-robot-tecno.png" alt="IMG" />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href="es/ai/20230926-Rusia-destina-5,2-mil-millones-de-rublos-inteligencia-artificial.html"
                                                                            className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                        >
                                                                            El Gobierno Ruso destinará 5,2 mil millones al
                                                                            desarrollo de la inteligencia artificial
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">
                                                                        <a
                                                                            href="#"
                                                                            className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                        >
                                                                            IA
                                                                        </a>
                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Sep 26</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>



                                                        {/* ======= ItemA  Actualidad ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a
                                                                    href="es/economy/20230925-Rusia-exporta-4.9-millones-de-toneladas-de-trigo.html"
                                                                    className="wrap-pic-w hov1 trans-03"
                                                                >
                                                                    <img
                                                                        src="images/trigo-agro-exportacion-maquinaria.png"
                                                                        alt="IMG"
                                                                    />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href="es/economy/20230925-Rusia-exporta-4.9-millones-de-toneladas-de-trigo.html"
                                                                            className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                        >
                                                                            Rusia ha exportado 4.9 millones de toneladas de
                                                                            trigo y puede llegar a 6 millones
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">
                                                                        <Link to="/economy" className="f1-s-6 cl8 hov-cl10 trans-03"> Economía </Link>

                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Sep 25</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>





                                                        {/* ======= ItemA2 Actualidad ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a
                                                                    href="es/politics/20230923-Rusia-dispuesta-a-negociar-sobre-Ucrania.html"
                                                                    className="wrap-pic-w hov1 trans-03"
                                                                >
                                                                    <img
                                                                        src="images/kremlin-dia-puente-cuadro.png"
                                                                        alt="IMG"
                                                                    />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href="es/politics/20230923-Rusia-dispuesta-a-negociar-sobre-Ucrania.html"
                                                                            className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                        >
                                                                            Rusia está dispuesta a negociar sobre Ucrania,
                                                                            anunció Lavrov
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">

                                                                        <Link to="/politics" className="f1-s-6 cl8 hov-cl10 trans-03"> Política </Link>


                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Sep 23</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* ======= ItemA3 Actualidad ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a
                                                                    href="es/economy/20230924-gazprom-suministra-a-europa-41millones-metros-cubicos-gas.html"
                                                                    className="wrap-pic-w hov1 trans-03"
                                                                >
                                                                    <img src="images/tubos-de-gas.png" alt="IMG" />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href="es/economy/20230924-gazprom-suministra-a-europa-41millones-metros-cubicos-gas.html"
                                                                            className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                        >
                                                                            "Gazprom" suministra a Europa 41,8 millones de
                                                                            metros cúbicos de gas
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">


                                                                        <Link to="/economy" className="f1-s-6 cl8 hov-cl10 trans-03"> Economía </Link>

                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Sep 24</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>










                                                    </div>
                                                </div>
                                                <div className="tab-pane" id="news-2" role="tabpanel">
                                                    <div className="row">





                                                        {/* ======= ItemA4 Actualidad ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a
                                                                    href="es/politics/20230920-kremlin-informa-visita-de-Putin-a-China.html"
                                                                    className="wrap-pic-w hov1 trans-03"
                                                                >
                                                                    <img src="images/kremlin.png" alt="IMG" />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href="es/politics/20230920-kremlin-informa-visita-de-Putin-a-China.html"
                                                                            className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                        >
                                                                            En el Kremlin afirmaron que se está preparando
                                                                            la visita de Putin a China.
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">
                                                                        <Link to="/economy" className="f1-s-6 cl8 hov-cl10 trans-03"> Economía </Link>

                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Sep 20</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>



                                                        {/* ======= ItemB1 Actualidad ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a
                                                                    href="es/society/20230925-profesiones-prometedoras-desarrollo-en-Moscu.html"
                                                                    className="wrap-pic-w hov1 trans-03"
                                                                >
                                                                    <img
                                                                        src="images/chicas-computadora-tecnologia.png"
                                                                        alt="IMG"
                                                                    />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href="es/society/20230925-profesiones-prometedoras-desarrollo-en-Moscu.html"
                                                                            className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                        >
                                                                            Las profesiones prometedoras en el desarrollo se
                                                                            discutirán en Moscú
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">

                                                                        <Link to="/actual" className="f1-s-6 cl8 hov-cl10 trans-03"> Actualidad </Link>

                                                                        {/*   <a
                                                                            href="#"
                                                                            className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                        >
                                                                            Actualidad
                                                                        </a> */}
                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Sep 25</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>



                                                        {/* ======= ItemB2 Actualidad ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a
                                                                    href="es/technology/20230920-Rusia-no-bloquea-WhatsApp-ni-YouTube.html"
                                                                    className="wrap-pic-w hov1 trans-03"
                                                                >
                                                                    <img src="images/youtube.png" alt="IMG" />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href="es/technology/20230920-Rusia-no-bloquea-WhatsApp-ni-YouTube.html"
                                                                            className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                        >
                                                                            Rusia no tiene planes de bloquear WhatsApp ni
                                                                            YouTube.
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">
                                                                        <Link to="/technology" className="f1-s-6 cl8 hov-cl10 trans-03"> Tecnología </Link>

                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Sep 20</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        {/* ======= ItemB3 Actualidad ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a
                                                                    href="es/politics/20230924-desdolarizacion-mundial-debido-a-los-BRICS.html"
                                                                    className="wrap-pic-w hov1 trans-03"
                                                                >
                                                                    <img src="images/dolar-roto.png" alt="IMG" />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href="es/politics/20230924-desdolarizacion-mundial-debido-a-los-BRICS.html"
                                                                            className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                        >
                                                                            La desdolarización debido a los BRICS pondrá fin
                                                                            al dominio de los Estados Unidos
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">
                                                                        <Link to="/politics" className="f1-s-6 cl8 hov-cl10 trans-03"> Política </Link>

                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Sep 24</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>




                                                    </div>
                                                </div>
                                                <div className="tab-pane" id="news-3" role="tabpanel">
                                                    <div className="row">
                                                        {/* ======= ItemD1 Actualidad ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a href="#" className="wrap-pic-w hov1 trans-03">
                                                                    <img src="images/post-25.jpg" alt="IMG" />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href="#"
                                                                            className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                        >
                                                                            23 Donec metus orci, malesuada et lectus vitae
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">
                                                                        <Link to="/trending" className="f1-s-6 cl8 hov-cl10 trans-03"> Viral </Link>

                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Sep 18</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* ======= ItemD2 Actualidad ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a href="#" className="wrap-pic-w hov1 trans-03">
                                                                    <img src="images/post-27.jpg" alt="IMG" />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href="#"
                                                                            className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                        >
                                                                            24 Donec metus orci, malesuada et lectus vitae
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">
                                                                        <Link to="/trending" className="f1-s-6 cl8 hov-cl10 trans-03"> Viral </Link>

                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Sep 20</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* ======= ItemD3 Actualidad ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a href="#" className="wrap-pic-w hov1 trans-03">
                                                                    <img src="images/post-26.jpg" alt="IMG" />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href="#"
                                                                            className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                        >
                                                                            25 Donec metus orci, malesuada et lectus vitae
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">
                                                                        <Link to="/trending" className="f1-s-6 cl8 hov-cl10 trans-03"> Viral </Link>

                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Sep 12</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* ======= ItemD4 Actualidad ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a href="#" className="wrap-pic-w hov1 trans-03">
                                                                    <img src="images/post-34.jpg" alt="IMG" />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href="#"
                                                                            className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                        >
                                                                            26 Donec metus orci, malesuada et lectus vitae
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">
                                                                        <Link to="/trending" className="f1-s-6 cl8 hov-cl10 trans-03"> Viral </Link>

                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Sep 15</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </li>

                                    {/* ======= BLOQUE POLÍTICA (enter) ======= */}
                                    <li className="mega-menu-item">

                                        <Link to="/politics" > Política </Link>

                                        <div className="sub-mega-menu">
                                            <div className="nav flex-column nav-pills" role="tablist">
                                                <a
                                                    className="nav-link active"
                                                    data-toggle="pill"
                                                    href="#enter-1"
                                                    role="tab"
                                                >
                                                    Todo
                                                </a>
                                                <a
                                                    className="nav-link"
                                                    data-toggle="pill"
                                                    href="#enter-2"
                                                    role="tab"
                                                >
                                                    Eventos
                                                </a>
                                                <a
                                                    className="nav-link"
                                                    data-toggle="pill"
                                                    href="#enter-3"
                                                    role="tab"
                                                >
                                                    Defensa
                                                </a>
                                            </div>
                                            <div className="tab-content">
                                                <div
                                                    className="tab-pane show active"
                                                    id="enter-1"
                                                    role="tabpanel"
                                                >
                                                    <div className="row">
                                                        {/* ======= ItemA1 Política ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a
                                                                    href="es/politics/20230923-Rusia-dispuesta-a-negociar-sobre-Ucrania.html"
                                                                    className="wrap-pic-w hov1 trans-03"
                                                                >
                                                                    <img
                                                                        src="images/kremlin-dia-puente-cuadro.png"
                                                                        alt="IMG"
                                                                    />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href="es/politics/20230923-Rusia-dispuesta-a-negociar-sobre-Ucrania.html"
                                                                            className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                        >
                                                                            Rusia está dispuesta a negociar sobre Ucrania,
                                                                            anunció Lavrov
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">

                                                                        <Link to="/politics" className="f1-s-6 cl8 hov-cl10 trans-03"> Política </Link>

                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Sep 23</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* ======= ItemA2 - Política ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a
                                                                    href="es/politics/20230924-desdolarizacion-mundial-debido-a-los-BRICS.html"
                                                                    className="wrap-pic-w hov1 trans-03"
                                                                >
                                                                    <img src="images/dolar-roto.png" alt="IMG" />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href="es/politics/20230924-desdolarizacion-mundial-debido-a-los-BRICS.html"
                                                                            className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                        >
                                                                            La desdolarización debido a los BRICS pondrá fin
                                                                            al dominio de los Estados Unidos
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">

                                                                        <Link to="/politics" className="f1-s-6 cl8 hov-cl10 trans-03"> Política </Link>

                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Sep 24</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* ======= ItemA3 - Política ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a
                                                                    href="es/politics/20230921-rusia-presidencia-BRICS.html"
                                                                    className="wrap-pic-w hov1 trans-03"
                                                                >
                                                                    <img src="images/brics.png" alt="IMG" />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href="es/politics/20230921-rusia-presidencia-BRICS.html"
                                                                            className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                        >
                                                                            Los países BRICS respaldaron la presidencia de
                                                                            Rusia.
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">
                                                                        <Link to="/politics" className="f1-s-6 cl8 hov-cl10 trans-03"> Política </Link>
                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Sep 21</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* ======= ItemA4 - Política ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a
                                                                    href="es/politics/20230920-kremlin-informa-visita-de-Putin-a-China.html"
                                                                    className="wrap-pic-w hov1 trans-03"
                                                                >
                                                                    <img src="images/kremlin.png" alt="IMG" />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href="es/politics/20230920-kremlin-informa-visita-de-Putin-a-China.html"
                                                                            className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                        >
                                                                            En el Kremlin afirmaron que se está preparando
                                                                            la visita de Putin a China.
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">
                                                                        <Link to="/politics" className="f1-s-6 cl8 hov-cl10 trans-03"> Política </Link>
                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Sep 20</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="tab-pane" id="enter-2" role="tabpanel">
                                                    <div className="row">
                                                        {/* ======= ItemB1 - Política ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a
                                                                    href="es/politics/20230920-Putin-Pashinyan-discuten-sobre-Karabaj.html"
                                                                    className="wrap-pic-w hov1 trans-03"
                                                                >
                                                                    <img src="images/erevan.png" alt="IMG" />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href="es/politics/20230920-Putin-Pashinyan-discuten-sobre-Karabaj.html"
                                                                            className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                        >
                                                                            Putin y Pashinyan discutieron la situación en
                                                                            Karabaj
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">
                                                                        <Link to="/politics" className="f1-s-6 cl8 hov-cl10 trans-03"> Política </Link>
                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Sep 20</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* ======= ItemB2 - Política ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a
                                                                    href="es/defense/20230919-putin-vehiculos-blindados.html"
                                                                    className="wrap-pic-w hov1 trans-03"
                                                                >
                                                                    <img
                                                                        src="images/tanque-blindado-ia.jpg"
                                                                        alt="IMG"
                                                                    />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href="es/defense/20230919-putin-vehiculos-blindados.html"
                                                                            className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                        >
                                                                            Putin declaró que la producción de vehículos
                                                                            blindados en Rusia se duplicó.
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">
                                                                        <a
                                                                            href="#"
                                                                            className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                        >
                                                                            Defensa
                                                                        </a>
                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Sep 19</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* ======= ItemB3 - Política ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a href="#" className="wrap-pic-w hov1 trans-03">
                                                                    <img src="images/post-37.jpg" alt="IMG" />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href="#"
                                                                            className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                        >
                                                                            115 Donec metus orci, malesuada et lectus vitae
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">
                                                                        <Link to="/trending" className="f1-s-6 cl8 hov-cl10 trans-03"> Viral </Link>

                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Sep 13</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* ======= ItemB4 - Política ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a href="#" className="wrap-pic-w hov1 trans-03">
                                                                    <img src="images/post-38.jpg" alt="IMG" />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href="#"
                                                                            className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                        >
                                                                            116 Donec metus orci, malesuada et lectus vitae
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">
                                                                        <Link to="/trending" className="f1-s-6 cl8 hov-cl10 trans-03"> Viral </Link>

                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Sep 15</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="tab-pane" id="enter-3" role="tabpanel">
                                                    <div className="row">
                                                        {/* ======= ItemC1 Política ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a href="#" className="wrap-pic-w hov1 trans-03">
                                                                    <img src="images/post-39.jpg" alt="IMG" />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href="#"
                                                                            className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                        >
                                                                            117 Donec metus orci, malesuada et lectus vitae
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">
                                                                        <Link to="/trending" className="f1-s-6 cl8 hov-cl10 trans-03"> Viral </Link>

                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Sep 12</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* ======= ItemC2 Política ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a href="#" className="wrap-pic-w hov1 trans-03">
                                                                    <img src="images/post-41.jpg" alt="IMG" />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href="#"
                                                                            className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                        >
                                                                            118 Donec metus orci, malesuada et lectus vitae
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">
                                                                        <Link to="/trending" className="f1-s-6 cl8 hov-cl10 trans-03"> Viral </Link>

                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Sep 15</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* ======= ItemC3 Política ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a href="#" className="wrap-pic-w hov1 trans-03">
                                                                    <img src="images/post-42.jpg" alt="IMG" />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href="#"
                                                                            className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                        >
                                                                            119 Donec metus orci, malesuada et lectus vitae
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">
                                                                        <Link to="/trending" className="f1-s-6 cl8 hov-cl10 trans-03"> Viral </Link>

                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Sep 15</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* ======= ItemC4 Política ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a href="#" className="wrap-pic-w hov1 trans-03">
                                                                    <img src="images/post-40.jpg" alt="IMG" />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href="#"
                                                                            className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                        >
                                                                            120 Donec metus orci, malesuada et lectus vitae
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">
                                                                        <Link to="/trending" className="f1-s-6 cl8 hov-cl10 trans-03"> Viral </Link>

                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Sep 12</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>


                                    {/* ======= BLOQUE ECONOMÍA (business) ======= */}
                                    <li className="mega-menu-item">

                                        <Link to="/economy"> Economía </Link>
                                        <div className="sub-mega-menu">
                                            <div className="nav flex-column nav-pills" role="tablist">
                                                <a
                                                    className="nav-link active"
                                                    data-toggle="pill"
                                                    href="#business-1"
                                                    role="tab"
                                                >
                                                    Todo
                                                </a>
                                                <a
                                                    className="nav-link"
                                                    data-toggle="pill"
                                                    href="#business-2"
                                                    role="tab"
                                                >
                                                    Finanzas
                                                </a>
                                            </div>
                                            <div className="tab-content">
                                                <div
                                                    className="tab-pane show active"
                                                    id="business-1"
                                                    role="tabpanel"
                                                >
                                                    <div className="row">


                                                        {/* ======= ItemA Econ ======= */}

                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>

                                                                <Link to="/231012-Aeroflot-aumenta-vuelos-internacionales" className="wrap-pic-w hov1 trans-03">
                                                                    <img
                                                                        src="images/avion-aeroflot-aero.png"
                                                                        alt="IMG"
                                                                    /> </Link>



                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <Link to="/231012-Aeroflot-aumenta-vuelos-internacionales"
                                                                            className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                        >
                                                                            AEROFLOT volará a otros 86 destinos internacionales.
                                                                        </Link>
                                                                    </h5>
                                                                    <span className="cl8">


                                                                        <Link to="/economy" className="f1-s-6 cl8 hov-cl10 trans-03"> Economía </Link>

                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Oct 12</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>



                                                        {/* ======= Item Econ ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a
                                                                    href="es/economy/20230927-Binance-vende-sus-activos-en-Rusia.html"
                                                                    className="wrap-pic-w hov1 trans-03"
                                                                >
                                                                    <img
                                                                        src="images/crypto-bitcoin-binance.png"
                                                                        alt="IMG"
                                                                    />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href="es/economy/20230927-Binance-vende-sus-activos-en-Rusia.html"
                                                                            className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                        >
                                                                            La criptobolsa BINANCE vende todos sus activos
                                                                            en Rusia
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">

                                                                        <Link to="/economy" className="f1-s-6 cl8 hov-cl10 trans-03"> Economía </Link>


                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Sep 27</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        {/* ======= ItemA1 Econ ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a
                                                                    href="es/economy/20230926-Se-espera-fortalecer-el-rublo-hasta-87.5-rublos-por-dolar.html"
                                                                    className="wrap-pic-w hov1 trans-03"
                                                                >
                                                                    <img
                                                                        src="images/rublo-ruso-monedas.png"
                                                                        alt="IMG"
                                                                    />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href="es/economy/20230926-Se-espera-fortalecer-el-rublo-hasta-87.5-rublos-por-dolar.html"
                                                                            className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                        >
                                                                            El Ministerio de Economía espera fortalecer el
                                                                            rublo hasta 87.5 rublos por dólar
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">
                                                                        <Link to="/economy" className="f1-s-6 cl8 hov-cl10 trans-03"> Economía </Link>

                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Sep 26</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        {/* ======= ItemA1 Economía ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a
                                                                    href="es/economy/20230925-Rusia-exporta-4.9-millones-de-toneladas-de-trigo.html"
                                                                    className="wrap-pic-w hov1 trans-03"
                                                                >
                                                                    <img
                                                                        src="images/trigo-agro-exportacion-maquinaria.png"
                                                                        alt="IMG"
                                                                    />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href="es/economy/20230925-Rusia-exporta-4.9-millones-de-toneladas-de-trigo.html"
                                                                            className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                        >
                                                                            Rusia ha exportado 4.9 millones de toneladas de
                                                                            trigo y puede llegar a 6 millones
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">
                                                                        <Link to="/economy" className="f1-s-6 cl8 hov-cl10 trans-03"> Economía </Link>

                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Sep 25</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>




                                                    </div>
                                                </div>




                                                <div className="tab-pane" id="business-2" role="tabpanel">
                                                    <div className="row">


                                                        {/* ======= ItemA2 Economía ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a
                                                                    href="es/economy/20230924-gazprom-suministra-a-europa-41millones-metros-cubicos-gas.html"
                                                                    className="wrap-pic-w hov1 trans-03"
                                                                >
                                                                    <img src="images/tubos-de-gas.png" alt="IMG" />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href="es/economy/20230924-gazprom-suministra-a-europa-41millones-metros-cubicos-gas.html"
                                                                            className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                        >
                                                                            "Gazprom" suministra a Europa 41,8 millones de
                                                                            metros cúbicos de gas
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">
                                                                        <Link to="/economy" className="f1-s-6 cl8 hov-cl10 trans-03"> Economía </Link>

                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Sep 24</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        {/* ======= ItemA3 Economía ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a
                                                                    href="es/economy/20230923-resistencia-de-la economia-rusa.html"
                                                                    className="wrap-pic-w hov1 trans-03"
                                                                >
                                                                    <img
                                                                        src="images/juego-economia-rusa.png"
                                                                        alt="IMG"
                                                                    />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href="es/economy/20230923-resistencia-de-la economia-rusa.html"
                                                                            className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                        >
                                                                            "Тal cosa no se esperaba": La resistencia de la
                                                                            economía rusa.
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">
                                                                        <Link to="/economy" className="f1-s-6 cl8 hov-cl10 trans-03"> Economía </Link>

                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Sep 23</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        {/* ======= ItemA4 Economía ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a
                                                                    href="es/economy/20230920-rusia-mayor-proveedor-oro-emiratos-arabes.html"
                                                                    className="wrap-pic-w hov1 trans-03"
                                                                >
                                                                    <img src="images/barras-de-oro.png" alt="IMG" />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href="es/economy/20230920-rusia-mayor-proveedor-oro-emiratos-arabes.html"
                                                                            className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                        >
                                                                            Rusia se ha convertido en el mayor proveedor de
                                                                            oro en los Emiratos Árabes Unidos (EAU)
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">
                                                                        <Link to="/economy" className="f1-s-6 cl8 hov-cl10 trans-03"> Economía </Link>

                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Sep 20</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        {/* ======= ItemB1 Economía ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a
                                                                    href="es/economy/20230920-rusia-exportacion-gasolina.html"
                                                                    className="wrap-pic-w hov1 trans-03"
                                                                >
                                                                    <img src="images/gasolina.png" alt="IMG" />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href="es/economy/20230920-rusia-exportacion-gasolina.html"
                                                                            className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                        >
                                                                            En Rusia se han anunciado medidas radicales para
                                                                            detener la exportación de gasolina del país.
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">
                                                                        <Link to="/economy" className="f1-s-6 cl8 hov-cl10 trans-03"> Economía </Link>

                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Sep 20</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>




                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>



                                    {/* ======= BLOQUE TECNOLOGÍA (travel) ======= */}
                                    <li className="mega-menu-item">

                                        <Link to="/technology"> Tecnología </Link>


                                        <div className="sub-mega-menu">
                                            <div className="nav flex-column nav-pills" role="tablist">
                                                <a
                                                    className="nav-link active"
                                                    data-toggle="pill"
                                                    href="#travel-1"
                                                    role="tab"
                                                >
                                                    Todo
                                                </a>
                                                <a
                                                    className="nav-link"
                                                    data-toggle="pill"
                                                    href="#travel-2"
                                                    role="tab"
                                                >
                                                    Ciencia
                                                </a>
                                            </div>



                                            <div className="tab-content">
                                                <div
                                                    className="tab-pane show active"
                                                    id="travel-1"
                                                    role="tabpanel"
                                                >
                                                    <div className="row">
                                                        {/* ======= ItemA  TECNO ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a
                                                                    href="es/ai/20230926-Rusia-destina-5,2-mil-millones-de-rublos-inteligencia-artificial.html"
                                                                    className="wrap-pic-w hov1 trans-03"
                                                                >
                                                                    <img src="images/ia-robot-tecno.png" alt="IMG" />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href="es/ai/20230926-Rusia-destina-5,2-mil-millones-de-rublos-inteligencia-artificial.html"
                                                                            className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                        >
                                                                            El Gobierno Ruso destinará 5,2 mil millones al
                                                                            desarrollo de la inteligencia artificial
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">
                                                                        <a
                                                                            href="#"
                                                                            className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                        >
                                                                            IA
                                                                        </a>
                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Sep 26</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        {/* ======= ItemA1 Tecnología ======= */}
                                                        <div className="col-3">
                                                            {/* Item 1 - Tecnología */}
                                                            <div>
                                                                <a
                                                                    href="es/technology/20230920-Rusia-no-bloquea-WhatsApp-ni-YouTube.html"
                                                                    className="wrap-pic-w hov1 trans-03"
                                                                >
                                                                    <img src="images/youtube.png" alt="IMG" />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href="es/technology/20230920-Rusia-no-bloquea-WhatsApp-ni-YouTube.html"
                                                                            className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                        >
                                                                            Rusia no tiene planes de bloquear WhatsApp ni
                                                                            YouTube.
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">

                                                                        <Link to="/technology" className="f1-s-6 cl8 hov-cl10 trans-03"> Tecnología </Link>

                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Sep 20</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* ======= ItemA2 Tecnología ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a
                                                                    href="es/technology/20230920-MISIS-generadores-cuanticos.html"
                                                                    className="wrap-pic-w hov1 trans-03"
                                                                >
                                                                    <img src="images/MISIS.jpg" alt="IMG" />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href="es/technology/20230920-MISIS-generadores-cuanticos.html"
                                                                            className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                        >
                                                                            En MISIS, se ha mejorado la fiabilidad de los
                                                                            generadores cuánticos.
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">
                                                                        <a
                                                                            href="#"
                                                                            className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                        >
                                                                            Ciencia
                                                                        </a>
                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Sep 20</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* ======= ItemA3 Tecnología ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a
                                                                    href="es/ai/20230908-putin-inteligencia-artificial.html"
                                                                    className="wrap-pic-w hov1 trans-03"
                                                                >
                                                                    <img src="images/ia-mujer-pantalla.png" alt="IMG" />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href="es/ai/20230908-putin-inteligencia-artificial.html"
                                                                            className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                        >
                                                                            Putin instruyó la aplicación "Inteligencia
                                                                            Artificial" en la educación superior.
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">
                                                                        <a
                                                                            href="#"
                                                                            className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                        >
                                                                            IA
                                                                        </a>
                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Sep 18</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                {/* ======= Fila 2- Tecnología ======= */}
                                                <div className="tab-pane" id="travel-2" role="tabpanel">
                                                    <div className="row">
                                                        {/* ======= ItemA4 Tecnología ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a
                                                                    href="es/technology/20230918-identificacion-digital-rusia.html"
                                                                    className="wrap-pic-w hov1 trans-03"
                                                                >
                                                                    <img
                                                                        src="images/rusia-identi-digital.jpg"
                                                                        alt="IMG"
                                                                    />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href="es/technology/20230918-identificacion-digital-rusia.html"
                                                                            className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                        >
                                                                            Putin ha firmado un decreto sobre la
                                                                            identificación digital
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">
                                                                        <a
                                                                            href="#"
                                                                            className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                        >
                                                                            Digital
                                                                        </a>
                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Sep 18</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* ======= ItemB1 Tecnología ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a
                                                                    href="es/technology/20230917-premio-ciencia01.html"
                                                                    className="wrap-pic-w hov1 trans-03"
                                                                >
                                                                    <img
                                                                        src="images/ciencia-laboratorio.png"
                                                                        alt="IMG"
                                                                    />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href="es/technology/20230917-premio-ciencia01.html"
                                                                            className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                        >
                                                                            Los ganadores del premio "Por la lealtad a la
                                                                            ciencia" serán anunciados en octubre.
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">
                                                                        <a
                                                                            href="#"
                                                                            className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                        >
                                                                            Ciencia
                                                                        </a>
                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Sep 18</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* ======= ItemB2 Tecnología ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a href="#" className="wrap-pic-w hov1 trans-03">
                                                                    <img src="images/post-36.jpg" alt="IMG" />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href="#"
                                                                            className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                        >
                                                                            Donec metus orci, malesuada et lectus vitae
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">
                                                                        <a
                                                                            href="#"
                                                                            className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                        >
                                                                            Eventos
                                                                        </a>
                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Sep 13</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* ======= ItemB3 Tecnología ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a href="#" className="wrap-pic-w hov1 trans-03">
                                                                    <img src="images/post-37.jpg" alt="IMG" />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href="#"
                                                                            className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                        >
                                                                            Donec metus orci, malesuada et lectus vitae
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">
                                                                        <a
                                                                            href="#"
                                                                            className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                        >
                                                                            Eventos
                                                                        </a>
                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Sep 13</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>


                                    {/* ======= BLOQUE SOCIEDAD (life) ======= */}
                                    <li className="mega-menu-item">

                                        <Link to="/society"> Sociedad </Link>

                                        <div className="sub-mega-menu">
                                            <div className="nav flex-column nav-pills" role="tablist">
                                                <a
                                                    className="nav-link active"
                                                    data-toggle="pill"
                                                    href="#life-1"
                                                    role="tab"
                                                >
                                                    Todo
                                                </a>


                                                <a
                                                    className="nav-link"
                                                    data-toggle="pill"
                                                    href="#life-2"
                                                    role="tab"
                                                >
                                                    Cultura
                                                </a>



                                            </div>





                                            <div className="tab-content">
                                                <div
                                                    className="tab-pane show active"
                                                    id="life-1"
                                                    role="tabpanel"
                                                >
                                                    <div className="row">



                                                        {/* ======= ItemA Actualidad ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>

                                                                <Link to="/231015-El-puente-de-Crimea-fue-completamente-restaurado" className="wrap-pic-w hov1 trans-03">
                                                                    <img
                                                                        src="images/puente-crimea-rusia.png"
                                                                        alt="IMG"
                                                                    /> </Link>



                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <Link to="/231015-El-puente-de-Crimea-fue-completamente-restaurado"
                                                                            className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                        >
                                                                            El puente de Crimea fue completamente restaurado antes de lo previsto
                                                                        </Link>
                                                                    </h5>
                                                                    <span className="cl8">


                                                                        <Link to="/society" className="f1-s-6 cl8 hov-cl10 trans-03"> Sociedad </Link>

                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Oct 15</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>





                                                        {/* ======= ItemA2 Sociedad ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a
                                                                    href="es/ai/20230921-moscu-camaras-ia-multan-pasajeros.html"
                                                                    className="wrap-pic-w hov1 trans-03"
                                                                >
                                                                    <img src="images/camaras-multas-ia.png" alt="IMG" />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href="es/ai/20230921-moscu-camaras-ia-multan-pasajeros.html"
                                                                            className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                        >
                                                                            En Moscú, las cámaras comenzarán a multar a los
                                                                            pasajeros sin cintúron de seguridad.
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">

                                                                        <Link to="/society" className="f1-s-6 cl8 hov-cl10 trans-03"> Sociedad </Link>

                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Sep 21</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>



                                                        {/* ======= ItemA3 Sociedad ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a
                                                                    href="es/education/20230921-colaboracion-entre-universidades-de-Rusia-Cuba.html"
                                                                    className="wrap-pic-w hov1 trans-03"
                                                                >
                                                                    <img
                                                                        src="images/rusas-universidad-estudios.png"
                                                                        alt="IMG"
                                                                    />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href="es/education/20230921-colaboracion-entre-universidades-de-Rusia-Cuba.html"
                                                                            className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                        >
                                                                            Chernyshenko habló sobre la colaboración entre
                                                                            las universidades de Rusia y Cuba.
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">

                                                                        <Link to="/education" className="f1-s-6 cl8 hov-cl10 trans-03"> Educación </Link>

                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Sep 21</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>




                                                        {/* ======= ItemA4 Sociedad ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a
                                                                    href="es/society/20230921-exitoso-inicio-de-carrera-de-jovenes.html"
                                                                    className="wrap-pic-w hov1 trans-03"
                                                                >
                                                                    <img
                                                                        src="images/chicas-rusas-patio.png"
                                                                        alt="IMG"
                                                                    />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href="es/society/20230921-exitoso-inicio-de-carrera-de-jovenes.html"
                                                                            className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                        >
                                                                            Putin insta a crear condiciones para un exitoso
                                                                            inicio de carrera de la juventud.
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">
                                                                        <Link to="/society" className="f1-s-6 cl8 hov-cl10 trans-03"> Sociedad </Link>

                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Sep 21</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>









                                                    </div>
                                                </div>




                                                {/* ======= Cultura (life) ======= */}

                                                <div
                                                    className="tab-pane"
                                                    id="life-2"
                                                    role="tabpanel"
                                                >
                                                    <div className="row">



                                                        {/* ======= ItemA4 Sociedad ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a
                                                                    href="es/society/20230921-exitoso-inicio-de-carrera-de-jovenes.html"
                                                                    className="wrap-pic-w hov1 trans-03"
                                                                >
                                                                    <img
                                                                        src="images/chicas-rusas-patio.png"
                                                                        alt="IMG"
                                                                    />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href="es/society/20230921-exitoso-inicio-de-carrera-de-jovenes.html"
                                                                            className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                        >
                                                                            Putin insta a crear condiciones para un exitoso
                                                                            inicio de carrera de la juventud.
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">
                                                                        <Link to="/society" className="f1-s-6 cl8 hov-cl10 trans-03"> Sociedad </Link>

                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Sep 21</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>







                                                        {/* ======= ItemA1 Sociedad ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a
                                                                    href="es/culture/20230919-rusas-hermosas-miss-universe.html"
                                                                    className="wrap-pic-w hov1 trans-03"
                                                                >
                                                                    <img
                                                                        src="images/miss-universe-russia.png"
                                                                        alt="IMG"
                                                                    />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href="es/culture/20230919-rusas-hermosas-miss-universe.html"
                                                                            className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                        >
                                                                            Las mujeres más hermosas de Rusia: La vida de
                                                                            las Miss Universo
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">
                                                                        <a
                                                                            href="#"
                                                                            className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                        >
                                                                            Cultura
                                                                        </a>
                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Sep 19</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* ======= ItemA2 Sociedad ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a
                                                                    href="es/ai/20230921-moscu-camaras-ia-multan-pasajeros.html"
                                                                    className="wrap-pic-w hov1 trans-03"
                                                                >
                                                                    <img src="images/camaras-multas-ia.png" alt="IMG" />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href="es/ai/20230921-moscu-camaras-ia-multan-pasajeros.html"
                                                                            className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                        >
                                                                            En Moscú, las cámaras comenzarán a multar a los
                                                                            pasajeros sin cintúron de seguridad.
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">

                                                                        <Link to="/society" className="f1-s-6 cl8 hov-cl10 trans-03"> Sociedad </Link>

                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Sep 21</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>




                                                        {/* ======= ItemA3 Sociedad ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a
                                                                    href="es/education/20230921-colaboracion-entre-universidades-de-Rusia-Cuba.html"
                                                                    className="wrap-pic-w hov1 trans-03"
                                                                >
                                                                    <img
                                                                        src="images/rusas-universidad-estudios.png"
                                                                        alt="IMG"
                                                                    />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href="es/education/20230921-colaboracion-entre-universidades-de-Rusia-Cuba.html"
                                                                            className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                        >
                                                                            Chernyshenko habló sobre la colaboración entre
                                                                            las universidades de Rusia y Cuba.
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">

                                                                        <Link to="/education" className="f1-s-6 cl8 hov-cl10 trans-03"> Educación </Link>

                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Sep 21</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>







                                                    </div>
                                                </div>






                                            </div>



                                        </div>
                                    </li>
                                    {/* ======= BLOQUE VIDEO (video) ======= */}
                                    <li className="mega-menu-item">
                                        <Link to="/video"> Vídeo </Link>

                                        <div className="sub-mega-menu">
                                            <div className="nav flex-column nav-pills" role="tablist">
                                                <a
                                                    className="nav-link active"
                                                    data-toggle="pill"
                                                    href="#video-1"
                                                    role="tab"
                                                >
                                                    Todo
                                                </a>
                                            </div>
                                            <div className="tab-content">
                                                <div
                                                    className="tab-pane show active"
                                                    id="video-1"
                                                    role="tabpanel"
                                                >
                                                    <div className="row">
                                                        {/* ======= ItemA1 Video ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a
                                                                    href="es/video/20230929-conferencia-Rusia-America-Latina.html"
                                                                    className="wrap-pic-w hov1 trans-03"
                                                                >
                                                                    <img src="images/Putin-Latam.png" alt="IMG" />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href="es/video/20230929-conferencia-Rusia-America-Latina.html"
                                                                            className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                        >
                                                                            La primera conferencia parlamentaria
                                                                            "Rusia-América Latina" se celebra en Moscú
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">
                                                                        <Link to="/society" className="f1-s-6 cl8 hov-cl10 trans-03"> Sociedad </Link>

                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Sep 29</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* ======= ItemA2 Video ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a href="#" className="wrap-pic-w hov1 trans-03">
                                                                    <img src="images/post-10.jpg" alt="IMG" />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href="#"
                                                                            className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                        >
                                                                            Donec metus orci, malesuada et lectus vitae
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">

                                                                        <Link to="/trending" className="f1-s-6 cl8 hov-cl10 trans-03"> Viral </Link>

                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Sep 12</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* ======= ItemA3 Video ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a href="#" className="wrap-pic-w hov1 trans-03">
                                                                    <img src="images/post-07.jpg" alt="IMG" />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href="#"
                                                                            className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                        >
                                                                            Donec metus orci, malesuada et lectus vitae
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">
                                                                        <Link to="/trending" className="f1-s-6 cl8 hov-cl10 trans-03"> Viral </Link>

                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Sep 20</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* ======= ItemA4 Video ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a href="#" className="wrap-pic-w hov1 trans-03">
                                                                    <img src="images/post-06.jpg" alt="IMG" />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href="#"
                                                                            className="f1-s-5 cl3 hov-cl10 trans-03"
                                                                        >
                                                                            Donec metus orci, malesuada et lectus vitae
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">
                                                                        <Link to="/trending" className="f1-s-6 cl8 hov-cl10 trans-03"> Viral </Link>

                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Sep 15</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    {/* ======= BLOQUE DESTACADO ======= */}
                                    <li>
                                        <a href="#">Destacado</a>
                                        <ul className="sub-menu">

                                            <li>
                                                <Link to="/about"> Acerca de </Link>
                                            </li>
                                            <li>
                                                <Link to="/contact"> Contáctanos </Link>

                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>




                </div>
            </header >



        </main >



    );

}

export default Menu;