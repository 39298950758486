

import React from "react";
import { Link } from "react-router-dom";


function Sidebar() {



    return (


        <div className="col-md-10 col-lg-4 p-b-30">
            <div className="p-l-10 p-rl-0-sr991 p-t-70">
                {/* Category */}
                <div className="p-b-60">
                    <div className="how2 how2-cl4 flex-s-c">
                        <h3 className="f1-m-2 cl3 tab01-title">Categoría</h3>
                    </div>
                    <ul className="p-t-35">
                        <li className="how-bor3 p-rl-4">

                            <Link to="/actual" className="dis-block f1-s-10 text-uppercase cl2 hov-cl10 trans-03 p-tb-13"> Actualidad </Link>


                        </li>
                        <li className="how-bor3 p-rl-4">

                            <Link to="/politics" className="dis-block f1-s-10 text-uppercase cl2 hov-cl10 trans-03 p-tb-13"> Política </Link>
                        </li>
                        <li className="how-bor3 p-rl-4">

                            <Link to="/technology" className="dis-block f1-s-10 text-uppercase cl2 hov-cl10 trans-03 p-tb-13"> Tecnología </Link>
                        </li>
                        <li className="how-bor3 p-rl-4">

                            <Link to="/economy" className="dis-block f1-s-10 text-uppercase cl2 hov-cl10 trans-03 p-tb-13"> Economía </Link>
                        </li>
                        <li className="how-bor3 p-rl-4">

                            <Link to="/society" className="dis-block f1-s-10 text-uppercase cl2 hov-cl10 trans-03 p-tb-13"> Sociedad </Link>
                        </li>
                        <li className="how-bor3 p-rl-4">

                            <Link to="/viral" className="dis-block f1-s-10 text-uppercase cl2 hov-cl10 trans-03 p-tb-13"> Viral </Link>
                        </li>
                    </ul>
                </div>


                {/*================================ ARCHIVO ======================================*/}
                {/* Archive */}
                <div className="p-b-37">
                    <div className="how2 how2-cl4 flex-s-c">
                        <h3 className="f1-m-2 cl3 tab01-title">Archivo</h3>
                    </div>
                    <ul className="p-t-32">
                        <li className="p-rl-4 p-b-19">

                            <Link to="/trending" className="flex-wr-sb-c f1-s-10 text-uppercase cl2 hov-cl10 trans-03">  <span>Agosto 2023</span>
                                <span>(6438)</span> </Link>

                        </li>
                        <li className="p-rl-4 p-b-19">

                            <Link to="/trending" className="flex-wr-sb-c f1-s-10 text-uppercase cl2 hov-cl10 trans-03">  <span>Julio 2023</span>
                                <span>(3784)</span> </Link>

                        </li>
                        <li className="p-rl-4 p-b-19">

                            <Link to="/trending" className="flex-wr-sb-c f1-s-10 text-uppercase cl2 hov-cl10 trans-03">  <span>Junio 2023</span>
                                <span>(2341)</span> </Link>

                        </li>
                        <li className="p-rl-4 p-b-19">

                            <Link to="/trending" className="flex-wr-sb-c f1-s-10 text-uppercase cl2 hov-cl10 trans-03">  <span>Mayo 2023</span>
                                <span>(1075)</span> </Link>

                        </li>
                        <li className="p-rl-4 p-b-19">

                            <Link to="/trending" className="flex-wr-sb-c f1-s-10 text-uppercase cl2 hov-cl10 trans-03">  <span>Abril 2023</span>
                                <span>(834)</span> </Link>

                        </li>
                        <li className="p-rl-4 p-b-19">

                            <Link to="/trending" className="flex-wr-sb-c f1-s-10 text-uppercase cl2 hov-cl10 trans-03">  <span>Marzo 2023</span>
                                <span>(741)</span> </Link>

                        </li>
                        <li className="p-rl-4 p-b-19">

                            <Link to="/trending" className="flex-wr-sb-c f1-s-10 text-uppercase cl2 hov-cl10 trans-03">  <span>Febrero 2023</span>
                                <span>(523)</span> </Link>

                        </li>
                        <li className="p-rl-4 p-b-19">

                            <Link to="/trending" className="flex-wr-sb-c f1-s-10 text-uppercase cl2 hov-cl10 trans-03">  <span>Enero 2023</span>
                                <span>(358)</span> </Link>

                        </li>

 

                    </ul>
                </div>
                {/*================================ LO MÁS LEÍDO =====================================*/}
                {/* Popular Posts */}
                <div className="p-b-30">
                    <div className="how2 how2-cl4 flex-s-c">
                        <h3 className="f1-m-2 cl3 tab01-title">Lo Más Leído</h3>
                    </div>
                    <ul className="p-t-35">
                        <li className="flex-wr-sb-s p-b-30">
                            <a
                                href="../../es/economy/20230927-Binance-vende-sus-activos-en-Rusia.html"
                                className="size-w-10 wrap-pic-w hov1 trans-03"
                            >
                                <img
                                    src="../../images/crypto-bitcoin-binance.png"
                                    alt="IMG"
                                />
                            </a>
                            <div className="size-w-11">
                                <h6 className="p-b-4">
                                    <a
                                        href="../../es/economy/20230927-Binance-vende-sus-activos-en-Rusia.html"
                                        className="f1-s-5 cl3 hov-cl10 trans-03"
                                    >
                                        La criptobolsa BINANCE vende todos sus activos en Rusia
                                    </a>
                                </h6>
                                <span className="cl8 txt-center p-b-24">
                                      
                                    <Link to="/economy" className="f1-s-6 cl8 hov-cl10 trans-03"> Economía </Link>  
                                    
                                    <span className="f1-s-3 m-rl-3">-</span>
                                    <span className="f1-s-3">Sep 27</span>
                                </span>
                            </div>
                        </li>




                        <li className="flex-wr-sb-s p-b-30">
                            <a
                                href="../../es/economy/20230926-Se-espera-fortalecer-el-rublo-hasta-87.5-rublos-por-dolar.html"
                                className="size-w-10 wrap-pic-w hov1 trans-03"
                            >
                                <img src="../../images/rublo-ruso-monedas.png" alt="IMG" />
                            </a>
                            <div className="size-w-11">
                                <h6 className="p-b-4">
                                    <a
                                        href="../../es/economy/20230926-Se-espera-fortalecer-el-rublo-hasta-87.5-rublos-por-dolar.html"
                                        className="f1-s-5 cl3 hov-cl10 trans-03"
                                    >
                                        El Ministerio de Economía espera fortalecer el rublo
                                        hasta 87.5 rublos por dólar
                                    </a>
                                </h6>
                                <span className="cl8 txt-center p-b-24">
                                    <Link to="/economy" className="f1-s-6 cl8 hov-cl10 trans-03"> Economía </Link> 
                                    <span className="f1-s-3 m-rl-3">-</span>
                                    <span className="f1-s-3">Sep 26</span>
                                </span>
                            </div>
                        </li>
                        <li className="flex-wr-sb-s p-b-30">
                            <a
                                href="../../es/ai/20230926-Rusia-destina-5,2-mil-millones-de-rublos-inteligencia-artificial.html"
                                className="size-w-10 wrap-pic-w hov1 trans-03"
                            >
                                <img src="../../images/ia-robot-tecno.png" alt="IMG" />
                            </a>
                            <div className="size-w-11">
                                <h6 className="p-b-4">
                                    <a
                                        href="../../es/ai/20230926-Rusia-destina-5,2-mil-millones-de-rublos-inteligencia-artificial.html"
                                        className="f1-s-5 cl3 hov-cl10 trans-03"
                                    >
                                        El Gobierno Ruso destinará 5,2 mil millones al
                                        desarrollo de la inteligencia artificial{" "}
                                    </a>
                                </h6>
                                <span className="cl8 txt-center p-b-24">
                                    <Link to="/ai" className="f1-s-6 cl8 hov-cl10 trans-03"> Inteligencia Artificial </Link> 
                                    <span className="f1-s-3 m-rl-3">-</span>
                                    <span className="f1-s-3">Sep 26</span>
                                </span>
                            </div>
                        </li>
                    </ul>
                </div>



                {/* ================= Tag ================= */}
                <div>
                    <div className="how2 how2-cl4 flex-s-c m-b-30">
                        <h3 className="f1-m-2 cl3 tab01-title">Etiquetas</h3>
                    </div>
                    <div className="flex-wr-s-s m-rl--5">
                        <Link to="/actual"
                              className="flex-c-c size-h-2 bo-1-rad-20 bocl12 f1-s-1 cl8 hov-btn2 trans-03 p-rl-20 p-tb-5 m-all-5"
                              > Actualidad </Link>

                        <Link to="/politics"
                              className="flex-c-c size-h-2 bo-1-rad-20 bocl12 f1-s-1 cl8 hov-btn2 trans-03 p-rl-20 p-tb-5 m-all-5"
                              > Política </Link>

                        <Link to="/events"
                              className="flex-c-c size-h-2 bo-1-rad-20 bocl12 f1-s-1 cl8 hov-btn2 trans-03 p-rl-20 p-tb-5 m-all-5"
                              > Eventos </Link>
                         

                        <Link to="/viral"
                              className="flex-c-c size-h-2 bo-1-rad-20 bocl12 f1-s-1 cl8 hov-btn2 trans-03 p-rl-20 p-tb-5 m-all-5"
                              > Viral </Link>
                       
                       <Link to="/society"
                              className="flex-c-c size-h-2 bo-1-rad-20 bocl12 f1-s-1 cl8 hov-btn2 trans-03 p-rl-20 p-tb-5 m-all-5"
                              > Sociedad </Link>

                       <Link to="/trending"
                              className="flex-c-c size-h-2 bo-1-rad-20 bocl12 f1-s-1 cl8 hov-btn2 trans-03 p-rl-20 p-tb-5 m-all-5"
                              > Tendencia </Link>
                         
                         <Link to="/entertainment"
                              className="flex-c-c size-h-2 bo-1-rad-20 bocl12 f1-s-1 cl8 hov-btn2 trans-03 p-rl-20 p-tb-5 m-all-5"
                              > Entretenimiento </Link>
                         
                         <Link to="/business"
                              className="flex-c-c size-h-2 bo-1-rad-20 bocl12 f1-s-1 cl8 hov-btn2 trans-03 p-rl-20 p-tb-5 m-all-5"
                              > Negocios </Link>
                         
                         
                    </div>
                </div>
            </div>
        </div>








    );

}

export default Sidebar;
