


import Menu from "../Menu";
import Footer from "../Footer";
import Sidebar2 from "../Sidebar2";

import { Link } from "react-router-dom";


function Society() {



    return (

        <main>


            <Menu />



              {/* Breadcrumb */}
              <div className="container">
                <div className="bg0 flex-wr-sb-c p-rl-20 p-tb-8">
                    <div className="f2-s-1 p-r-30 m-tb-6">
                        <a href="../index.html" className="breadcrumb-item f1-s-3 cl9">
                            Home
                        </a>
                        <span className="breadcrumb-item f1-s-3 cl9">Sección</span>
                    </div>
                    <div className="pos-relative size-a-2 bo-1-rad-22 of-hidden bocl11 m-tb-6">
                        <input
                            className="f1-s-1 cl6 plh9 s-full p-l-25 p-r-45"
                            type="text"
                            name="search"
                            placeholder="Search"
                        />
                        <button className="flex-c-c size-a-1 ab-t-r fs-20 cl2 hov-cl10 trans-03">
                            <i className="zmdi zmdi-search" />
                        </button>
                    </div>
                </div>
            </div>
            {/* ============================================== */}
            {/* ============ TÍTULO DE LA SECCIÓN ============ */}
            {/* ============================================== */}
            {/* Page heading */}
            <div className="container p-t-4 p-b-40">
                <h2 className="f1-l-1 cl2">SOCIEDAD</h2>
            </div>
            {/* Post */}
            <section className="bg0 p-b-55">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-10 col-lg-8 p-b-80">

                            <div className="row">



                                 {/* ============ CUADRO DE LA SECCIÓN ============ */}
                                 <div className="col-sm-6 p-r-25 p-r-15-sr991">
                                    {/* Item latest */}
                                    <div className="m-b-45">
                                        <Link to="/231015-El-puente-de-Crimea-fue-completamente-restaurado"
                                            className="wrap-pic-w hov1 trans-03"
                                        >
                                            <img
                                                src="images/puente-crimea-rusia.png"
                                                alt="IMG"
                                            /> </Link>

                                        <div className="p-t-16">
                                            <h5 className="p-b-5">
                                                <Link to="/231015-El-puente-de-Crimea-fue-completamente-restaurado"
                                                    className="f1-m-3 cl2 hov-cl10 trans-03"
                                                >
                                                    El puente de Crimea fue completamente restaurado antes de lo previsto
                                                </Link>
                                            </h5>

                                            <span className="cl8">
                                                <a href="../#" className="f1-s-4 cl8 hov-cl10 trans-03">
                                                    by Belén García
                                                </a>
                                                <span className="f1-s-3 m-rl-3">-</span>
                                                <span className="f1-s-3">Oct 15</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>


                                {/* ============ CUADRO DE LA SECCIÓN ============ */}
                                <div className="col-sm-6 p-r-25 p-r-15-sr991">
                                    {/* Item latest */}
                                    <div className="m-b-45">
                                        <Link to="/231012-Aeroflot-aumenta-vuelos-internacionales"
                                            className="wrap-pic-w hov1 trans-03"
                                        >
                                            <img
                                                src="images/avion-aeroflot-aero.png"
                                                alt="IMG"
                                            /> </Link>

                                        <div className="p-t-16">
                                            <h5 className="p-b-5">
                                                <Link to="/231012-Aeroflot-aumenta-vuelos-internacionales"
                                                    className="f1-m-3 cl2 hov-cl10 trans-03"
                                                >

                                                    AEROFLOT volará a otros 86 destinos internacionales.
                                                </Link>
                                            </h5>

                                            <span className="cl8">
                                                <a href="../#" className="f1-s-4 cl8 hov-cl10 trans-03">
                                                    by Jorge Miller
                                                </a>
                                                <span className="f1-s-3 m-rl-3">-</span>
                                                <span className="f1-s-3">Oct 12</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                {/* ============ CUADRO DE LA SECCIÓN ============ */}
                                <div className="col-sm-6 p-r-25 p-r-15-sr991">
                                    {/* Item latest */}
                                    <div className="m-b-45">
                                        <a
                                            href="../es/society/20230929-primera-conferencia-Rusia-America-Latina.html"
                                            className="wrap-pic-w hov1 trans-03"
                                        >
                                            <img src="../images/Putin-Latam.png" alt="IMG" />
                                        </a>
                                        <div className="p-t-16">
                                            <h5 className="p-b-5">
                                                <a
                                                    href="../es/society/20230929-primera-conferencia-Rusia-America-Latina.html"
                                                    className="f1-m-3 cl2 hov-cl10 trans-03"
                                                >
                                                    La primera conferencia parlamentaria "Rusia-América
                                                    Latina" se celebra en Moscú
                                                </a>
                                            </h5>
                                            <span className="cl8">
                                                <a href="../#" className="f1-s-4 cl8 hov-cl10 trans-03">
                                                    by Luis Palermo
                                                </a>
                                                <span className="f1-s-3 m-rl-3">-</span>
                                                <span className="f1-s-3">Sep 29</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>


                                {/* ============ CUADRO DE LA SECCIÓN ============ */}
                                <div className="col-sm-6 p-r-25 p-r-15-sr991">
                                    {/* Item latest */}
                                    <div className="m-b-45">
                                        <a
                                            href="../es/economy/20230927-Binance-vende-sus-activos-en-Rusia.html"
                                            className="wrap-pic-w hov1 trans-03"
                                        >
                                            <img src="../images/crypto-bitcoin-binance.png" alt="IMG" />
                                        </a>
                                        <div className="p-t-16">
                                            <h5 className="p-b-5">
                                                <a
                                                    href="../es/economy/20230927-Binance-vende-sus-activos-en-Rusia.html"
                                                    className="f1-m-3 cl2 hov-cl10 trans-03"
                                                >
                                                    La criptobolsa BINANCE vende todos sus activos en Rusia{" "}
                                                </a>
                                            </h5>
                                            <span className="cl8">
                                                <a href="../#" className="f1-s-4 cl8 hov-cl10 trans-03">
                                                    by Belén García
                                                </a>
                                                <span className="f1-s-3 m-rl-3">-</span>
                                                <span className="f1-s-3">Sep 27</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>


                                {/* ============ CUADRO DE LA SECCIÓN ============ */}
                                <div className="col-sm-6 p-r-25 p-r-15-sr991">
                                    {/* Item latest */}
                                    <div className="m-b-45">
                                        <a
                                            href="../es/economy/20230926-Se-espera-fortalecer-el-rublo-hasta-87.5-rublos-por-dolar.html"
                                            className="wrap-pic-w hov1 trans-03"
                                        >
                                            <img src="../images/rublo-ruso-monedas.png" alt="IMG" />
                                        </a>
                                        <div className="p-t-16">
                                            <h5 className="p-b-5">
                                                <a
                                                    href="../es/economy/20230926-Se-espera-fortalecer-el-rublo-hasta-87.5-rublos-por-dolar.html"
                                                    className="f1-m-3 cl2 hov-cl10 trans-03"
                                                >
                                                    El Ministerio de Economía espera fortalecer el rublo hasta
                                                    87.5 rublos por dólar
                                                </a>
                                            </h5>
                                            <span className="cl8">
                                                <a href="../#" className="f1-s-4 cl8 hov-cl10 trans-03">
                                                    by Belén García
                                                </a>
                                                <span className="f1-s-3 m-rl-3">-</span>
                                                <span className="f1-s-3">Sep 26</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>


                                {/* ============ CUADRO DE LA SECCIÓN ============ */}
                                <div className="col-sm-6 p-r-25 p-r-15-sr991">
                                    {/* Item latest */}
                                    <div className="m-b-45">
                                        <a
                                            href="../es/ai/20230926-Rusia-destina-5,2-mil-millones-de-rublos-inteligencia-artificial.html"
                                            className="wrap-pic-w hov1 trans-03"
                                        >
                                            <img src="../images/ia-robot-tecno.png" alt="IMG" />
                                        </a>
                                        <div className="p-t-16">
                                            <h5 className="p-b-5">
                                                <a
                                                    href="../es/ai/20230926-Rusia-destina-5,2-mil-millones-de-rublos-inteligencia-artificial.html"
                                                    className="f1-m-3 cl2 hov-cl10 trans-03"
                                                >
                                                    El Gobierno Ruso destinará 5,2 mil millones al desarrollo
                                                    de la inteligencia artificial
                                                </a>
                                            </h5>
                                            <span className="cl8">
                                                <a href="../#" className="f1-s-4 cl8 hov-cl10 trans-03">
                                                    by Valeria Leon
                                                </a>
                                                <span className="f1-s-3 m-rl-3">-</span>
                                                <span className="f1-s-3">Sep 26</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>


                                {/* ============ CUADRO DE LA SECCIÓN ============ */}
                                <div className="col-sm-6 p-r-25 p-r-15-sr991">
                                    {/* Item latest */}
                                    <div className="m-b-45">
                                        <a
                                            href="../es/economy/20230925-Rusia-exporta-4.9-millones-de-toneladas-de-trigo.html"
                                            className="wrap-pic-w hov1 trans-03"
                                        >
                                            <img
                                                src="../images/trigo-agro-exportacion-maquinaria.png"
                                                alt="IMG"
                                            />
                                        </a>
                                        <div className="p-t-16">
                                            <h5 className="p-b-5">
                                                <a
                                                    href="../es/economy/20230925-Rusia-exporta-4.9-millones-de-toneladas-de-trigo.html"
                                                    className="f1-m-3 cl2 hov-cl10 trans-03"
                                                >
                                                    Rusia ha exportado 4.9 millones de toneladas de trigo y
                                                    puede llegar a 6 millones
                                                </a>
                                            </h5>
                                            <span className="cl8">
                                                <a href="../#" className="f1-s-4 cl8 hov-cl10 trans-03">
                                                    by Belén García
                                                </a>
                                                <span className="f1-s-3 m-rl-3">-</span>
                                                <span className="f1-s-3">Sep 25</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>


                                {/* ============ CUADRO DE LA SECCIÓN ============ */}
                                <div className="col-sm-6 p-r-25 p-r-15-sr991">
                                    {/* Item latest */}
                                    <div className="m-b-45">
                                        <a
                                            href="../es/economy/20230924-gazprom-suministra-a-europa-41millones-metros-cubicos-gas.html"
                                            className="wrap-pic-w hov1 trans-03"
                                        >
                                            <img src="../images/tubos-de-gas.png" alt="IMG" />
                                        </a>
                                        <div className="p-t-16">
                                            <h5 className="p-b-5">
                                                <a
                                                    href="../es/economy/20230924-gazprom-suministra-a-europa-41millones-metros-cubicos-gas.html"
                                                    className="f1-m-3 cl2 hov-cl10 trans-03"
                                                >
                                                    Rusia suministra a Europa 41,8 millones de metros cúbicos
                                                    de gas
                                                </a>
                                            </h5>
                                            <span className="cl8">
                                                <a href="../#" className="f1-s-4 cl8 hov-cl10 trans-03">
                                                    by Belén García
                                                </a>
                                                <span className="f1-s-3 m-rl-3">-</span>
                                                <span className="f1-s-3">Sep 24</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>


                                {/* ============ CUADRO DE LA SECCIÓN ============ */}
                                <div className="col-sm-6 p-r-25 p-r-15-sr991">
                                    {/* Item latest */}
                                    <div className="m-b-45">
                                        <a
                                            href="../es/society/20230925-profesiones-prometedoras-desarrollo-en-Moscu.html"
                                            className="wrap-pic-w hov1 trans-03"
                                        >
                                            <img
                                                src="../images/chicas-computadora-tecnologia.png"
                                                alt="IMG"
                                            />
                                        </a>
                                        <div className="p-t-16">
                                            <h5 className="p-b-5">
                                                <a
                                                    href="../es/society/20230925-profesiones-prometedoras-desarrollo-en-Moscu.html"
                                                    className="f1-m-3 cl2 hov-cl10 trans-03"
                                                >
                                                    Las profesiones prometedoras en el desarrollo se
                                                    discutirán en Moscú
                                                </a>
                                            </h5>
                                            <span className="cl8">
                                                <a href="../#" className="f1-s-4 cl8 hov-cl10 trans-03">
                                                    by John Alvarado
                                                </a>
                                                <span className="f1-s-3 m-rl-3">-</span>
                                                <span className="f1-s-3">Sep 25</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>



                                {/* ============ CUADRO DE LA SECCIÓN ============ */}

                                <div className="col-sm-6 p-r-25 p-r-15-sr991">
                                    {/* Item latest */}
                                    <div className="m-b-45">
                                        <a
                                            href="../blog-detail-01.html"
                                            className="wrap-pic-w hov1 trans-03"
                                        >
                                            <img src="../images/entertaiment-11.jpg" alt="IMG" />
                                        </a>
                                        <div className="p-t-16">
                                            <h5 className="p-b-5">
                                                <a
                                                    href="../blog-detail-01.html"
                                                    className="f1-m-3 cl2 hov-cl10 trans-03"
                                                >
                                                    You wish lorem ipsum dolor sit amet consectetur
                                                </a>
                                            </h5>
                                            <span className="cl8">
                                                <a href="../#" className="f1-s-4 cl8 hov-cl10 trans-03">
                                                    by John Alvarado
                                                </a>
                                                <span className="f1-s-3 m-rl-3">-</span>
                                                <span className="f1-s-3">Feb 16</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                {/* ============ CUADRO DE LA SECCIÓN ============ */}

                                <div className="col-sm-6 p-r-25 p-r-15-sr991">
                                    {/* Item latest */}
                                    <div className="m-b-45">
                                        <a
                                            href="../blog-detail-01.html"
                                            className="wrap-pic-w hov1 trans-03"
                                        >
                                            <img src="../images/entertaiment-12.jpg" alt="IMG" />
                                        </a>
                                        <div className="p-t-16">
                                            <h5 className="p-b-5">
                                                <a
                                                    href="../blog-detail-01.html"
                                                    className="f1-m-3 cl2 hov-cl10 trans-03"
                                                >
                                                    You wish lorem ipsum dolor sit amet consectetur
                                                </a>
                                            </h5>
                                            <span className="cl8">
                                                <a href="../#" className="f1-s-4 cl8 hov-cl10 trans-03">
                                                    by John Alvarado
                                                </a>
                                                <span className="f1-s-3 m-rl-3">-</span>
                                                <span className="f1-s-3">Feb 15</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>


                                {/* ============ CUADRO DE LA SECCIÓN ============ */}

                                <div className="col-sm-6 p-r-25 p-r-15-sr991">
                                    {/* Item latest */}
                                    <div className="m-b-45">
                                        <a
                                            href="../blog-detail-01.html"
                                            className="wrap-pic-w hov1 trans-03"
                                        >
                                            <img src="../images/entertaiment-13.jpg" alt="IMG" />
                                        </a>
                                        <div className="p-t-16">
                                            <h5 className="p-b-5">
                                                <a
                                                    href="../blog-detail-01.html"
                                                    className="f1-m-3 cl2 hov-cl10 trans-03"
                                                >
                                                    You wish lorem ipsum dolor sit amet consectetur
                                                </a>
                                            </h5>
                                            <span className="cl8">
                                                <a href="../#" className="f1-s-4 cl8 hov-cl10 trans-03">
                                                    by John Alvarado
                                                </a>
                                                <span className="f1-s-3 m-rl-3">-</span>
                                                <span className="f1-s-3">Feb 13</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>





                            </div>



                            {/* Pagination */}
                            <div className="flex-wr-s-c m-rl--7 p-t-15">
                                <a
                                    href="../#"
                                    className="flex-c-c pagi-item hov-btn1 trans-03 m-all-7 pagi-active"
                                >
                                    1
                                </a>
                                <a
                                    href="../#"
                                    className="flex-c-c pagi-item hov-btn1 trans-03 m-all-7"
                                >
                                    2
                                </a>
                            </div>
                        </div>


                        {/*===============================================================================================*/}
                        {/*================================ BARRA LATERAL ======================================*/}
                        {/*===============================================================================================*/}
                        {/* Sidebar2 */}



                        <Sidebar2 />







                    </div>
                </div>
            </section>




            <Footer />


        </main >



    );

}

export default Society;

