

import React from 'react';
import Footer from './Footer';
import Menu from './Menu';
import { Link } from "react-router-dom";


export default function about() {



    return (

        <React.Fragment>


            <Menu />

            <div className="animsition">

                {/* Breadcrumb */}
                <div className="container">
                    <div className="headline bg0 flex-wr-sb-c p-rl-20 p-tb-8">
                        <div className="f2-s-1 p-r-30 m-tb-6">


                            <Link to="/" className="breadcrumb-item f1-s-3 cl9"> Inicio </Link>

                            <span className="breadcrumb-item f1-s-3 cl9">Acerca de Nosotros</span>


                        </div>
                        <div className="pos-relative size-a-2 bo-1-rad-22 of-hidden bocl11 m-tb-6">
                            <input
                                className="f1-s-1 cl6 plh9 s-full p-l-25 p-r-45"
                                type="text"
                                name="search"
                                placeholder="Buscar"
                            />
                            <button className="flex-c-c size-a-1 ab-t-r fs-20 cl2 hov-cl10 trans-03">
                                <i className="zmdi zmdi-search" />
                            </button>
                        </div>
                    </div>
                </div>
                {/* Page heading */}
                <div className="container p-t-4 p-b-35">
                    <h2 className="f1-l-1 cl2">Acerca de Nosotros</h2>
                </div>
                {/* Content */}
                <section className="bg0 p-b-110">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-7 col-lg-8 p-b-30">
                                <div className="p-r-10 p-r-0-sr991">
                                    <p className="f1-s-11 cl6 p-b-25">
                                        Somos una plataforma comprometida en brindarte la información más
                                        relevante y precisa de Rusia y del mundo. En Rusia News, creemos
                                        que el conocimiento es poder, y nuestro objetivo es empoderarte
                                        con noticias frescas y análisis profundos sobre la actualidad, la
                                        política y los desarrollos más recientes en distintos campos de
                                        ciencia y tecnología.
                                    </p>
                                    <p className="f1-s-11 cl6 p-b-25">
                                        Nuestro equipo de redacción te ofrece noticias y análisis
                                        precisos, lo que nos permite mostrarte una perspectiva integral de
                                        los acontecimientos que impactan en nuestro mundo. Nos esforzamos
                                        por mantenerte informado sobre la actualidad y los avances
                                        tecnológicos que están transformando nuestra sociedad, así como
                                        por proporcionarte análisis en profundidad que te ayuden a
                                        comprender su relevancia.
                                    </p>
                                    <p className="f1-s-11 cl6 p-b-25">
                                        Creemos en la transparencia y la honestidad; valoramos la
                                        diversidad de opiniones y fomentamos el diálogo constructivo. Nos
                                        comprometemos a entregar noticias imparciales y objetivas,
                                        manteniendo siempre los más altos estándares éticos al entregar la
                                        información precisa y verificada. Queremos ser tu fuente confiable
                                        de información, ayudándote a navegar por el mundo de la tecnología
                                        y la actualidad de manera informada y segura.
                                    </p>
                                    <p className="f1-s-11 cl6 p-b-25">
                                        Nuestra misión es ser una fuente confiable de información
                                        imparcial, donde puedas explorar diferentes perspectivas. Únete a
                                        nosotros en esta travesía de conocimiento y de análisis. Explora
                                        nuestras secciones de noticias, artículos y eventos, y déjanos ser
                                        tu guía en la búsqueda de información de Rusia y del mundo.
                                    </p>
                                </div>
                            </div>
                            {/*================================== BARRA LATERAL =====================================*/}
                            {/* Sidebar */}
                            <div className="col-md-5 col-lg-4 p-b-30">
                                <div className="p-l-10 p-rl-0-sr991 p-t-5">
                                    {/* Popular Posts */}
                                    <div>
                                        <div className="how2 how2-cl4 flex-s-c">
                                            <h3 className="f1-m-2 cl3 tab01-title">Lo más leído</h3>
                                        </div>
                                        <ul className="p-t-35">
                                            <li className="flex-wr-sb-s p-b-30">
                                                <a
                                                    href="../../es/economy/20230927-Binance-vende-sus-activos-en-Rusia.html"
                                                    className="size-w-10 wrap-pic-w hov1 trans-03"
                                                >
                                                    <img
                                                        src="../../images/crypto-bitcoin-binance.png"
                                                        alt="IMG"
                                                    />
                                                </a>
                                                <div className="size-w-11">
                                                    <h6 className="p-b-4">
                                                        <a
                                                            href="../../es/economy/20230927-Binance-vende-sus-activos-en-Rusia.html"
                                                            className="f1-s-5 cl3 hov-cl10 trans-03"
                                                        >
                                                            La criptobolsa BINANCE vende todos sus activos en Rusia
                                                        </a>
                                                    </h6>
                                                    <span className="cl8 txt-center p-b-24">
                                                        <a href="#" className="f1-s-6 cl8 hov-cl10 trans-03">
                                                            Economía
                                                        </a>
                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                        <span className="f1-s-3">Sep 27</span>
                                                    </span>
                                                </div>
                                            </li>
                                            <li className="flex-wr-sb-s p-b-30">
                                                <a
                                                    href="../../es/economy/20230926-Se-espera-fortalecer-el-rublo-hasta-87.5-rublos-por-dolar.html"
                                                    className="size-w-10 wrap-pic-w hov1 trans-03"
                                                >
                                                    <img src="../../images/rublo-ruso-monedas.png" alt="IMG" />
                                                </a>
                                                <div className="size-w-11">
                                                    <h6 className="p-b-4">
                                                        <a
                                                            href="../../es/economy/20230926-Se-espera-fortalecer-el-rublo-hasta-87.5-rublos-por-dolar.html"
                                                            className="f1-s-5 cl3 hov-cl10 trans-03"
                                                        >
                                                            El Ministerio de Economía espera fortalecer el rublo
                                                            hasta 87.5 rublos por dólar
                                                        </a>
                                                    </h6>
                                                    <span className="cl8 txt-center p-b-24">
                                                        <a href="#" className="f1-s-6 cl8 hov-cl10 trans-03">
                                                            Economía
                                                        </a>
                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                        <span className="f1-s-3">Sep 26</span>
                                                    </span>
                                                </div>
                                            </li>
                                            <li className="flex-wr-sb-s p-b-30">
                                                <a
                                                    href="../../es/ai/20230926-Rusia-destina-5,2-mil-millones-de-rublos-inteligencia-artificial.html"
                                                    className="size-w-10 wrap-pic-w hov1 trans-03"
                                                >
                                                    <img src="../../images/ia-robot-tecno.png" alt="IMG" />
                                                </a>
                                                <div className="size-w-11">
                                                    <h6 className="p-b-4">
                                                        <a
                                                            href="../../es/ai/20230926-Rusia-destina-5,2-mil-millones-de-rublos-inteligencia-artificial.html"
                                                            className="f1-s-5 cl3 hov-cl10 trans-03"
                                                        >
                                                            El Gobierno Ruso destinará 5,2 mil millones al
                                                            desarrollo de la inteligencia artificial{" "}
                                                        </a>
                                                    </h6>
                                                    <span className="cl8 txt-center p-b-24">
                                                        <a href="#" className="f1-s-6 cl8 hov-cl10 trans-03">
                                                            Tecnología
                                                        </a>
                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                        <span className="f1-s-3">Sep 26</span>
                                                    </span>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>

            <Footer />


        </React.Fragment>




    );

}


