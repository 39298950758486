



import React, { useState, useEffect } from 'react';
import * as ReactDOM from 'react-dom';
// import { useEffect } from 'react';

import logo from './logo.png';
// import './App.css';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Academics from './pages/es/Academics';
import Actual from './pages/es/Actual';
import Ai from './pages/es/Ai';
import Business from './pages/es/Business';
import Culture from './pages/es/Culture';
import Defense from './pages/es/Defense';
import Economy from './pages/es/Economy';
import Education from './pages/es/Education';
import Entertainment from './pages/es/Entertainment';
import Events from './pages/es/Events';
import Lifestyle from './pages/es/Lifestyle';
import Politics from './pages/es/Politics';
import Society from './pages/es/Society';
import Technology from './pages/es/Technology';
import Trending from './pages/es/Trending';
import Video from './pages/es/Video';
import Viral from './pages/es/Viral';
import Prueba from './pages/Prueba';
 

import A231012 from './pages/es/economy/231012-Aeroflot-aumenta-vuelos';
import A230929 from './pages/es/economy/230929-Conf-Rusia-Latam';
import A231015 from './pages/es/society/231015-Crimea';

import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'; 


 
function App() {


    

  return (


    <div className="App" >
      

             <Router>
                 <Routes>
                    <Route path='/' element={<Home/>} />
                    <Route path='/about' element={<About />} />
                    <Route path='/contact' element={<Contact />} />
                    <Route path='/prueba' element={<Prueba />} />

                    <Route path='/academics' element={<Academics />} />
                    <Route path='/actual' element={<Actual />} />
                    <Route path='/ai' element={<Ai />} />
                    <Route path='/business' element={<Business />} />
                    <Route path='/culture' element={<Culture />} />
                    <Route path='/defense' element={<Defense />} />
                    <Route path='/economy' element={<Economy />} />
                    <Route path='/education' element={<Education />} />
                    <Route path='/entertainment' element={<Entertainment />} />
                    <Route path='/events' element={<Events />} />
                    <Route path='/lifestyle' element={<Lifestyle />} />
                    <Route path='/politics' element={<Politics />} />
                    <Route path='/society' element={<Society />} />
                    <Route path='/technology' element={<Technology />} />
                    <Route path='/trending' element={<Trending/>} />
                    <Route path='/video' element={<Video />} />
                    <Route path='/viral' element={<Viral />} />

                    
                    <Route path='/231015-El-puente-de-Crimea-fue-completamente-restaurado' element={<A231015 />} />
                    <Route path='/231012-Aeroflot-aumenta-vuelos-internacionales' element={<A231012 />} />
                    <Route path='/230929-Conf-Rusia-Latam' element={<A230929 />} />

                 </Routes>


             </Router>
             
             

    </div>



  );

}

export default App;
